import axios from "axios";

const instance = axios.create({
    baseURL: "https://viacep.com.br/ws/"
})

export const cepServices = {
    getAdress: (cep) => {
        return instance.get(cep + "/json/")
    }
}