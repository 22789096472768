import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { UserContext } from "../Context/UserContext";
import Header from "./Header";

export default function Main({ isAutenticated }) {
  const { GetPermission } = useContext(UserContext);
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add("sidebar-offset")
    : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);
  useEffect(() => {
    if (isAutenticated) {
      GetPermission();
    } else {
    }
  }, []);

  return !isAutenticated ? (
    <Navigate to="/signin" />
  ) : (
    <React.Fragment>
      <Sidebar />

      <Header />
      <Outlet />

    </React.Fragment>
  );
}
