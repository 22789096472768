import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Nav, ProgressBar, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { apiServices } from "../apiServices/ApiServices";
import { DashboardServices } from "../apiServices/dashBoardServices";
import "./styles/styles.css";
import DateRangeFilter from "../components/DateRangerFilter";
import { productServices } from "../apiServices/productServices";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { userServices } from "../apiServices/userServices";
import { ToastContainer, toast } from "react-toastify";

const firstSecondName = (name) => {
  const splitName = name.split(" ");
  return splitName[0] + " " + splitName[1]
}



export default function FinanceMonitoring() {

  const [dataToChart, setDataToChart] = useState([]);
  const [searchToData, setSearchToData] = useState([]);
  const [materialState, setMaterialState] = useState([]);
  const [materialAmountClient, setMaterialAmountClient] = useState([]);

  const [materialStateMetal, setMaterialStateMetal] = useState([]);
  const [clientState, setClientState] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);

  const [selectedMetal, setSelectedMetal] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const formatDateUsa = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };


  const handleSearchSubmit = (e) => {
    e.preventDefault()
    let dataInicio = "";
    let dataFim = "";
    if (e.target.length === 4) {
      dataInicio = (e.target[1].value)
      dataFim = (e.target[2].value)
    } else {
      dataInicio = (e.target[2].value)
      dataFim = (e.target[3].value)
    }



    const dataToSearch = {
      tipoMetal: selectedMetal,
      client: selectedClient,
      initialDate: formatDateUsa(dataInicio),
      endDate: formatDateUsa(dataFim)
    }

    setSearchToData(dataToSearch)
  }

  const handleSelectedMetal = (selectedOption) => {
    setSelectedMetal(selectedOption.value)
  }

  const handleSelectedClient = (selectedOption) => {
    setSelectedClient(selectedOption.value)
  }

  // Função para mostrar o DatePicker com um efeito de fade in.
  const showDatePickerWithFadeIn = () => {
    setShowDatePicker(true);
  };

  // Função para esconder o DatePicker com um efeito de fade out.
  const hideDatePickerWithFadeOut = () => {
    setShowDatePicker(false);
  };


  const handleMaterialType = async (param, token) => {

    const data = [{ value: '', label: 'Todos' }];
    const response = await productServices.selectListMaterial("", token);
    const responseData = (response.data)
    responseData.map((dados) => {
      data.push({ value: dados.id, label: dados.name })
    })
    setMaterialStateMetal(data)

  }

  const handleClient = async (token) => {
    const request = {
      token: token,
    }
    const response = await userServices.getUsers(request)
    const data = [{ value: '', label: 'Todos' }];
    const responseData = (response.data.users);
    responseData.map((dados) => {
      data.push({ value: dados.ID, label: dados.NAME })
    })
    setClientState(data)
  }

  const handleSearch = (e) => {
    // Copie o estado atual de searchToData e adicione o array recebido no evento
    const updatedSearchToData = [...searchToData, e];

    // Defina o estado de searchToData com os novos dados
    setSearchToData(updatedSearchToData);

    // Você pode fazer o mesmo para dataToChart e materialState se necessário
    // const updatedDataToChart = [...dataToChart, e];
    // setDataToChart(updatedDataToChart);

    // const updatedMaterialState = [...materialState, e];
    // setMaterialState(updatedMaterialState);


  }

  const formatDate = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}`;
  };

  const formatHour = (data) => {
    const date = new Date(data);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  const formatDateHour = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleGetData = async () => {
    const data = {
      "token": token,
      "action": "dashboard_data",
      "search": searchToData
    }

    try {
      const response = await DashboardServices.getDataToBalanceController(data);
      const totalData = response.data;

      let saldoAtual = 0;

      // Ordenar os dados por data e hora
      totalData.sort((a, b) => new Date(a.date_time) - new Date(b.date_time));

      // Calcular o saldo acumulado
      const saldoAcumulado = totalData.map(item => {
        if (item.type_name === "Entrada") {
          saldoAtual += item.amount;
        } else if (item.type_name === "Pedido") {
          saldoAtual -= Math.abs(item.amount); // Subtrair o valor absoluto do item.amount
        }

        return {
          ...item,
          saldo_atual: saldoAtual.toFixed(2) // Arredondar o saldo para 2 casas decimais
        };
      });
      toast.dismiss();
      setDataToChart(saldoAcumulado);

      const materialArray = [];

      saldoAcumulado.forEach(item => {
        const metalName = item.material_name;
        const amount = item.amount;

        let arrayAmount = item.amount.toString();
        if (arrayAmount[0] === "-") {
          arrayAmount = arrayAmount.substring(1).replace(/,/g, "");
        } else {
          arrayAmount = arrayAmount.replace(/,/g, "");
        }

        const parsedAmount = parseFloat(arrayAmount);
        const tipoEntrada = item.type_name;

        if (metalName) {
          const materialEntry = materialArray.find(entry => entry.metalName === metalName);
          if (materialEntry) {
            if (tipoEntrada === "Entrada") {
              materialEntry.metalAmount += parsedAmount;
            } else if (tipoEntrada === "Pedido") {
              materialEntry.metalAmount -= parsedAmount;
            }
          } else {
            const newMaterialEntry = {
              metalName,
              metalAmount: tipoEntrada === "Entrada" ? parsedAmount : -parsedAmount
            };
            materialArray.push(newMaterialEntry);
          }
        }
      });

      const materialClient = [];

      saldoAcumulado.forEach(item => {
        const clientName = item.client_name;
        const amount = item.amount;

        let arrayAmount = item.amount.toString();
        if (arrayAmount[0] === "-") {
          arrayAmount = arrayAmount.substring(1).replace(/,/g, "");
        } else {
          arrayAmount = arrayAmount.replace(/,/g, "");
        }
        const parsedAmount = parseFloat(arrayAmount);
        const tipoEntrada = item.type_name;

        if (clientName) {
          const materialClientEntry = materialClient.find(entry => entry.clientName === clientName);
          if (materialClientEntry) {
            if (tipoEntrada === "Entrada") {
              materialClientEntry.metalAmount += parsedAmount;
            } else if (tipoEntrada === "Pedido") {
              materialClientEntry.metalAmount -= parsedAmount;
            }
          } else {
            const newMaterialClientEntry = {
              clientName,
              metalAmount: tipoEntrada === "Entrada" ? parsedAmount : -parsedAmount
            };
            materialClient.push(newMaterialClientEntry);
          }
        }
      });

      setMaterialAmountClient(materialClient)
      setMaterialState(materialArray);

    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }

  const dataForChart = materialAmountClient.map((entry, index) => ({
    x: entry.clientName,
    y: entry.metalAmount.toFixed(2),
    name: entry.clientName
  }));

  const seriesFive = [{
    type: 'column',
    data: dataForChart // Use o array de dados diretamente aqui
  }];


  var optionFive = {
    chart: {
      type: 'bar',
      toolbar: { show: true },
      stacked: true,
      events: {
        click: function (event, chartContext, config) {

        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '60%'
      }
    },
    colors: ['#3a59c4', '#e5e9f2'],
    dataLabels: {
      enabled: true
    },
    grid: {
      borderColor: 'rgba(33,43,48,0.07)',
      padding: {
        top: -20,
        bottom: -5
      },
      yaxis: {
        lines: { show: true }
      }
    },
    fill: {
      type: ['solid', 'gradient'],
      gradient: {
        type: 'vertical',
        opacityFrom: 0.35,
        opacityTo: 0.2,
        gradientToColors: ['#f3f5fc']
      }
    },
    legend: {
      show: true,
      markers: {
        fillColors: ['#00E396', '#775DD0']
      }
    }
  };



  useEffect(() => {
    handleMaterialType("", token);
    handleGetData()
    handleClient(token);

  }, [searchToData])

  const seriesBalance = [{
    name: 'Saldo Atual',
    data: dataToChart.map(item => ({
      x: formatDateHour(item.date_time), // Use a data como o índice
      y: item.saldo_atual // Use o saldo_atual como o valor
    }))
  }];

  const optionBalance = {
    chart: {
      type: 'area',
      toolbar: { show: true },
      stacked: true
    },
    colors: ['#506fd9', '#e5e9f2'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.3)',
      padding: { top: 70, left: 50, right: 50 },
      yaxis: {
        lines: { show: true }
      }
    },

    stroke: {
      curve: 'smooth',
      width: [3, 0]
    },
    xaxis: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM \'yy',
      },
      axisBorder: { show: true },
      labels: { enabled: false }
    },
    yaxis: {
      min: 0,
      show: true
    },
    fill: {
      type: 'gradient'
    },
    menubar: { show: true },
    dataLabels: { enabled: true },
    legend: { show: true },
    tooltip: { enabled: true }
  };

  const apexChartData = Object.values(materialState).map(item => item.metalAmount);
  const labels = materialState.map(item => item.metalName);

  const optionDonut = {
    labels: labels,
    chart: {
      parentHeightOffset: 0,
      width: 'auto',
      height: 6,
      strokeWidth: 2
    },
    colors: ['#506fd9', '#85b6ff', '#51596d', '#eff1f5'],
    dataLabels: { enabled: true },
    grid: {
      padding: {
        top: 0,
        bottom: 0
      }
    },
    legend: { show: true }
  };

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const token = localStorage.getItem("token");

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3 d-flex flex-wrap">
          <Col xl={12}>
            <Card className="card-one d-flex flex-wrap">
              <Card.Body className="p-xl-4">
                {/* <h1 className="finance-value">
                  <span>Material</span>
                </h1> */}
                <Row className="d-flex flex-wrap justify-content-between mb-2 mb-xl-4">
                  <Col xs="3" md="12" className="d-flex justify-content-between flex-wrap w-100">
                    <Card className="card-one w-100" >
                      <Card.Body className="w-100 justify-content-between p-3 p-xl-4">
                        <Form className="d-flex justify-content-between w-100 g-3" onSubmit={handleSearchSubmit}>
                          {/* <h3 className="">
                            <span>Filtro</span>
                          </h3> */}
                          <div className="w-20 text-center mx-1" style={{ zIndex: 999 }}>
                            <label>Tipo de Metal</label>
                            <Select options={materialStateMetal} onChange={handleSelectedMetal} isSearchable={true} />
                          </div>

                          {clientState.length === 2 ? (
                            <>
                            </>
                          ) : (
                            <div className="w-20 text-center mx-1" style={{ zIndex: 999 }}>
                              <label>Cliente</label>
                              <Select options={clientState} onChange={handleSelectedClient} isSearchable={true} />
                            </div>
                          )
                          }

                          <div className="w-20 text-center mx-1" style={{ zIndex: 999 }}>
                            <label>Data de Início</label>
                            <ReactDatePicker dateFormat="yyyy/MM/dd" selected={startDate} defaulValue={null} onChange={(date) => setStartDate(date)} className="form-control" />
                          </div>
                          <div className="w-20 text-center" style={{ zIndex: 999 }}>
                            <label>Data de Fim</label>
                            <ReactDatePicker dateFormat="yyyy/MM/dd" selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" />
                          </div>
                          <div className="w-20 mt-3 d-flex align-items-end justify-content-center text-center">
                            <Button type="submit" variant="primary">Filtrar</Button>
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between g-3 mb-2 mb-xl-4">
                  <Col md="12" lg="3" className="d-flex flex-column p-0 justify-content-center">
                    <Card className="card-one  p-0 d-flex align-items-center justify-content-center w-100 h-100">
                      <div className="finance-item p-0 h-100 flex-column d-flex justify-content-center">
                        <div className="mb-5">
                          <ReactApexChart series={apexChartData} options={optionDonut} height="250px" type="donut" className="apex-donut-two" />
                        </div>
                      </div>
                    </Card>
                    {/* </Col>
                  <Col xs="6" sm="4" md="6" className="d-flex justify-content-center"> */}
                    <Card className="card-one w-100">
                      <Card.Body className="pt-0">
                        <ProgressBar className="progress-finance mb-4">
                          {materialState.map((item, index) => (
                            <ProgressBar key={index} now={item.metalAmount} label={item.metalAmount.toFixed(2)}></ProgressBar>
                          ))}
                        </ProgressBar>

                        <Row className="g-3 text-center">
                          {materialState.map((item, index) => (
                            <Col key={index} className="text-center">
                              <label className="card-label text-center fs-sm fw-medium mb-1">{item.metalName}</label>
                              <h2 className="text-center mb-0">{item.metalAmount.toFixed(2)}(g)</h2>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="12" lg="9">
                    <Card className="card-one">
                      <Card.Body className="overflow-hidden p-3">
                        <div className="finance-info p-3 p-xl-4">
                          <h1 className="finance-value">
                            <span>Total: </span>{dataToChart.length > 0 ? dataToChart[dataToChart.length - 1].saldo_atual : 0}<span>(g)</span>
                          </h1>
                          {/* <h4 className="finance-subvalue mb-3 mb-md-2">
                    <i className="ri-arrow-up-line text-primary"></i>
                    <span className="text-primary">38.63%</span>
                    <small>Comparado ao mês anterior</small>
                  </h4> */}
                        </div>
                        <div className="mt-5">
                          <ReactApexChart series={seriesBalance} options={optionBalance} type="area" height={430} className="apex-chart-two" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md={12}>
            <Row>
              <Col className="xs-4">
                <Card className="card-one">
                  <Card.Body className="d-flexp-3 p-xl-4">
                    <h1 className="finance-value">
                      <span>Cliente</span>
                    </h1> */}
                    {/* <h4 className="finance-subvalue mb-3 mb-md-2">
                    <i className="ri-arrow-up-line text-primary"></i>
                    <span className="text-primary">38.63%</span>
                    <small>Comparado ao mês anterior</small>
                  </h4> */}
                    {/* <ReactApexChart series={seriesFive} options={optionFive} type="area" height={200} className="apex-chart-three" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col> */}

          <Col xl="12" className="">
            <Row className="d-flex">
              <Col xl="12" className="">
                <Card className="card-one w-100 overflow-x-auto">
                  <Card.Header className="text-center d-flex justify-content-center">
                    <h1 className="mb-1 text-center">Histório de Saldo</h1>
                  </Card.Header>
                  <Card.Body>

                    <Table className="d-flex flex-column justify-content-between" responsive>
                      <thead>
                        <tr className="d-flex justify-content-between">
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Saldo</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Total</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Número do Pedido</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Metal</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Cliente</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Data</label>
                          </th>
                          <th className="text-left border w-custom p-1">
                            <label className="d-block text-secondary mb-1">Hora</label>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="custom-overflow">
                        {dataToChart.map((item, index) => (
                          <tr key={index} className="d-flex justify-content-between">
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="amount" name="amount" variant="outline" onClick={() => handleSearch({ "amount": item.amount })} value={item.amount}>
                                <span className="fw-medium text-dark ff-numerals">{item.amount}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="saldo_atual" name="saldo_atual" variant="outline" onClick={() => handleSearch({ "saldo_atual": item.saldo_atual })} value={item.saldo_atual}>
                                <span className="fw-medium text-dark ff-numerals">{item.saldo_atual}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="code_order" name="code_order" variant="outline" onClick={() => handleSearch({ "code_order": item.code_order !== "00000000" ? item.code_order : "" })} value={item.code_order !== "00000000" ? item.code_order : ""}>
                                <span className="fw-medium text-dark ff-numerals">{item.code_order !== "00000000" ? item.code_order : ""}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="material_name" name="material_name" variant="outline" onClick={() => handleSearch({ "material_name": item.material_name })} value={item.material_name}>
                                <span className="fw-medium text-dark ff-numerals">{item.material_name}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="client_name" name="client_name" variant="outline" onClick={() => handleSearch({ "client_name": item.client_name })} value={item.client_name}>
                                <span className="fw-medium text-dark ff-numerals">{item.client_name.length > 15 ? item.client_name.slice(0, 12) + "..." : item.client_name}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="date_time" name="date_time" variant="outline" onClick={() => handleSearch({ "date_time": item.date_time })}>
                                <span className="fw-medium text-dark ff-numerals">{formatDate(item.date_time)}</span>
                              </Button>
                            </td>
                            <td className="text-left border w-custom p-0 text-center">
                              <Button className="p-1 w-100 h-100 text-center m-0" id="date_time" name="date_time" variant="outline" onClick={() => handleSearch({ "date_time": item.date_time })}>
                                <span className="fw-medium text-dark ff-numerals">{formatHour(item.date_time)}</span>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </Col>

          {/* <Col xl="12" className="">
            <Card className="card-one">
              <Card.Body className="overflow-hidden p-3">
                <div className="finance-info p-3 p-xl-4">
                  <h1 className="finance-value">
                    <span>Total: </span>{dataToChart.length > 0 ? dataToChart[dataToChart.length - 1].saldo_atual : 0}<span>(g)</span>
                  </h1>
                  {/* <h4 className="finance-subvalue mb-3 mb-md-2">
                    <i className="ri-arrow-up-line text-primary"></i>
                    <span className="text-primary">38.63%</span>
                    <small>Comparado ao mês anterior</small>
                  </h4> */}
          {/* </div>
                <div className="mt-5">
                  <ReactApexChart series={seriesBalance} options={optionBalance} type="area" height={430} className="apex-chart-two" />
                </div>
              </Card.Body>
            </Card>

          </Col> */}


        </Row>

        <Footer />
        <ToastContainer />
      </div>
    </React.Fragment >
  );
}
