import React, { useContext, useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import ReactLoading from "react-loading";
import {
  Button,
  OverlayTrigger,
  Card,
  Col,
  Row,
  Nav,
  Table,
  Tooltip,
  ProgressBar,
  Form,
  Tab,
  NavLink,
  NavItem,
  Modal,
  Accordion,
  Pagination,
  Spinner,
  Fade,
  ModalHeader,
} from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { apiServices } from "../apiServices/ApiServices";
import { Search } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { productServices } from "../apiServices/productServices";
import { ScreenContext } from "../Context/Screen/context";
import { OrderContext } from "../Context/OrderContext";
import { LogModal } from "../components/LogModal/LogModal";
import { swalErro, swalSucesso } from "../components/Swal";

export default function HelpdeskService() {
  const token = localStorage.getItem("token");
  const utp = localStorage.getItem("utp");
  const page = 3;
  const [count, setCount] = useState([]);
  const [orders, setOrders] = useState([]);

  const { getOrderLog, orderAddFinalWeight } = useContext(OrderContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [tooltipOpen, setTooltipOpen] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [modalHistoryShow, setModalHistoryShow] = useState(false);
  const [modalHistoryContent, setMOdalHistoryContent] = useState([]);

  const [modalViewShow, setModalViewShow] = useState(false);
  const [modalAttachmentShow, setModalAttachmentShow] = useState(false);
  const [modalAttachment, setModalAttachment] = useState(null);

  const handleOpenModalAttachment = (data) => {

    setModalAttachment(data);
    setModalAttachmentShow(true);
  };

  const handleCloseModalAttachment = () => {
    setModalAttachment([]);
    setModalAttachmentShow(false);
  };

  const [modalViewContent, setModalViewContent] = useState([]);
  const [nailing, setNailing] = useState([]);
  const [typeNailing, setTypeNailing] = useState([]);
  const [metal, setMetal] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [module, setModule] = useState([]);

  const [orderLoading, setOrderLoading] = useState(true);
  const [orderViewLoading, setOrderViewLoading] = useState(false);

  const [openModalMetalAtribuicao, setOpenModalMetalAtribuicao] =
    useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalMetalAtribuicaoDetal, setModalDetalAtribuicaoDetal] = useState(
    []
  );

  const [statusToUpdate, setStatusToUpdate] = useState([]);
  const [orderDataToStatus, setOrderDataToStatus] = useState([]);
  const [statusSelecionado, setStatuSelecionado] = useState([]);

  const handleOpenMetalAtribuicaoModal = async (metal) => {
    setOpenModalMetalAtribuicao(true);

    const data = {
      metal: metal,
      order: selectedOrder,
      token: token,
    };

    const response = await productServices.getMetalDetailOrder(data);
    const modalAtribuicaoDetalData = {
      metal: metal,
      ...response.data,
    };
    setModalDetalAtribuicaoDetal(modalAtribuicaoDetalData);
  };

  const { screenWidth, handleResize } = useContext(ScreenContext);
  useEffect(() => {
    handleResize();
  }, [])


  const handleSelectedStatus = (e) => {
    // Converte 'e' para um número
    const id = parseInt(e, 10);

    const statusEncontrado = statusToUpdate.find((status) => status.id === id);

    if (statusEncontrado) {
      const data = {
        id: e,
        name: statusEncontrado.nhandleResizeame,
      };
      setStatuSelecionado(data);
    } else {
      console.log(`Nenhum status encontrado para o ID ${id}`);
      // Lide com a situação em que o objeto não foi encontrado, por exemplo, definindo um valor padrão.
      // Ou execute outra ação apropriada.
    }
  };

  const handleUpdateStatus = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();

    const formData = new FormData(e.target);

    const data = {
      status: statusSelecionado.id,
      order: Array.of(selectedOrder),
      token: token,
      description: formData.get("description"),
    };

    const response = await apiServices.updateStatusOrder(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleAtribuirMetal = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      metal: formData.get("metal"),
      order: selectedOrder,
      token: token,
      text: formData.get("modal_form_metal_input_text"),
    };
    const response = await productServices.atribuirMetal(data);
    ;
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTimeout(() => setOpenModalMetalAtribuicao(false), 2000);
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleOpenEditModal = async (order, status) => {
    setOpenEditModal(true);

    const response = await apiServices.orderStatusList(order, token);
    const data = {
      order: order,
      status: status,
    };
    setStatusToUpdate(response.data);
    setOrderDataToStatus(data);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
    handleOrderView("");
  };

  // Função para alternar a visibilidade do tooltip para uma linha específica
  const toggleTooltip = (orderId) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  const formatDate = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleOrderPdf = async (id) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id_order: id,
      token: token,
    };
    const response = await apiServices.getOrderPdf(data);
    if (response.data.link !== "") {
      var link = response.data.link;
      var anchor = document.createElement("a");
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      // Configure o atributo href e download
      anchor.href = link;
      anchor.setAttribute("target", "_blank");
      toast.dismiss();
      toast.success("Documento gerado com sucesso!");
      // Dispare um clique simulado no elemento de âncora
      setTimeout(() => {
        anchor.click();
      }, 1000);

      // Remova o elemento de âncora
      document.body.removeChild(anchor);
    }
    ;
  };

  const handleGetFundicaoPdf = async id => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id_order: id,
      token: token,
    };
    const response = await apiServices.getOrderFundicaoPdf(data);
    if (response.data.link !== "") {
      var link = response.data.link;
      var anchor = document.createElement("a");
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      // Configure o atributo href e download
      anchor.href = link;
      anchor.setAttribute("target", "_blank");
      toast.dismiss();
      toast.success("Documento gerado com sucesso!");
      // Dispare um clique simulado no elemento de âncora
      setTimeout(() => {
        anchor.click();
      }, 1000);

      // Remova o elemento de âncora
      document.body.removeChild(anchor);
    }
  };

  const handleGetFixPdf = async id => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id_order: id,
      token: token,
    };
    const response = await apiServices.getOrderFixPdf(data);
    if (response.data.link !== "") {
      var link = response.data.link;
      var anchor = document.createElement("a");
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      // Configure o atributo href e download
      anchor.href = link;
      anchor.setAttribute("target", "_blank");
      toast.dismiss();
      toast.success("Documento gerado com sucesso!");
      // Dispare um clique simulado no elemento de âncora
      setTimeout(() => {
        anchor.click();
      }, 1000);

      // Remova o elemento de âncora
      document.body.removeChild(anchor);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let formData = event.target.pesquisar.value;
    if (formData.length > 1) {
      setSearchKey(formData);
    } else if (formData.length === 0) {
      setSearchKey("");
    }
  };

  const handleSearchNoneValue = (event) => {
    let formData = event.target.value;
    if (formData.length === 0) {
      setSearchKey("");
    }
  };

  const handleClickStatus = (event) => {
    setOrderLoading(true);
    setSearchKey(event.target.value);
  };

  const handleOrderHistory = (order) => {
    apiServices.getORderHistory(order, token).then((response) => {
      setMOdalHistoryContent(response.data);
      setModalHistoryShow(true);
    });
  };

  const handleClose = () => {
    setModalHistoryShow(false);
    setOpenEditModal(false);
  };

  const closeModalMetalAtribuicao = () => {
    setOpenModalMetalAtribuicao(false);
  };

  const getMetalDetails = async (order, token) => {
    const response = await apiServices.getMetal(order, token);
    if (response) {
      setMetal(response.data);
    }
  };

  const handleOrderView = async (order) => {
    setSelectedOrder(order);
    setModalViewShow(true);
    try {
      setOrderViewLoading(true);
      const orderResponse = await apiServices.getOrderView(order, token);
      setModalViewContent(orderResponse.data.order);

      setOrderViewLoading(false);
    } catch (error) {
      console.log(error);
      setOrderViewLoading(false);
    }

    await getMetalDetails(order, token);

    await apiServices
      .getModule(order, token)
      .then((response) => {
        setModule(response.data);
      })
      .catch((error) => console.log(error));

    await apiServices
      .getNailing(order, token)
      .then((response) => {
        setNailing(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await apiServices.getTypeNailing(order, token).then((response) => {
      setTypeNailing(response.data);
    });

    await apiServices
      .getAttachment(order, token)
      .then((response) => {
        setAttachment(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handeCloseOrderView = () => {
    setModalViewShow(false);
  };

  const getOrder = async (token, limit, currentPage, searchKey, idStatus, page) => {
    setOrderLoading(true);

    try {
      const response = await apiServices.getOrder(
        token,
        limit,
        currentPage,
        searchKey,
        idStatus,
        page
      );
      setOrders(response.data.orders);
      setTotalPages(response.data.total_pages);
      setTotalCount(response.data.total_count);
      setOrderLoading(false);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  const getCountStatus = async (token) => {
    await apiServices
      .getCountStatus(token)
      .then((response) => {
        setCount(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // PEGA PEDIDOS POR STATUS E TOTAL
  useEffect(() => {
    async function fetchall() {
      await getOrder(token, 10, 1, searchKey, null, page);
    }
    fetchall();
  }, [searchKey]);

  useEffect(() => {
    getCountStatus(token);
  }, [token]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    getOrder(token, 10, pageNumber, searchKey, null, page);
  };

  const countAllOrders = count && count?.reduce((acc, cur) => acc + cur.amount, 0);
  const [newOrderDescriotion, setNewOrderDescription] = useState("");
  const editorRef = useRef(null);
  const handeSubmitEditOrderDescription = async (e) => {
    e.preventDefault();
    let order_description = "";
    if (editorRef.current) {
      order_description = editorRef.current.getContent();
    }
    const payload = {
      order_code: modalViewContent.order_code,
      description: order_description,
    }

    const response = await orderAddFinalWeight(payload)

    if (response.data.status == "success") {
      swalSucesso(response.data.msg)
      handleOrderView(modalViewContent.id)
      setEditingDescriptionMode(false)
    } else {
      swalErro(response.data.msg)
    }
  }

  const [editingDescriptionMode, setEditingDescriptionMode] = useState(false);


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Seja bem vindo à Criart Gold</h4>
          </div>
          {/* <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
          </div> */}
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row>
              <Col xl="2" md="4" sm="6">
                <Button
                  variant="outline-primary"
                  className="mb-3 p-0 w-100 bg-transparent border-0"
                  value=""
                  onClick={handleClickStatus}
                >
                  <Card className="card-one shadow-none w-100" style={{ zIndex: "-1" }}>
                    <Card.Body>
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className={"px-3 py-2 rounded text-white bg-primary"}>
                          <i className="ri-file-list-line"></i>
                        </div>
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <h4 className={`card-value d-flex align-items-baseline mb-0 ${screenWidth < 1100 ? "justify-content-center" : null}`}>
                            {countAllOrders}
                          </h4>
                          <label className="card-label fs-sm fw-medium mb-1">
                            Todos
                          </label>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Button>
              </Col>
              {count.length > 0 ? (
                count.map((card, index) => (
                  <Col xl="2" md="4" sm="6" key={index}>
                    <Button
                      variant="outline-primary"
                      className="p-0 w-100 bg-transparent border-0"
                      value={card.status}
                      onClick={handleClickStatus}
                    >
                      <Card className="card-one  shadow-none w-100 mb-3" style={{ zIndex: "-1" }}>
                        <Card.Body>
                          <div className="d-flex d-sm-block d-xl-flex align-items-center">
                            <div className={"px-3 py-2 rounded text-white bg-primary"}>
                              <i className={card.icon}></i>
                            </div>
                            <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                              <h4 className={`card-value d-flex align-items-baseline mb-0 ${screenWidth < 1100 ? "justify-content-center" : null}`}>
                                {card.amount}
                              </h4>
                              <div className={"ticker-container"} style={{
                                width: screenWidth > 1100 && screenWidth <= 1700 ? card.status?.split("").length + 40 + (screenWidth / 1000 * 8) : ''
                              }}>
                                <label style={{
                                  fontSize: "0.75rem"
                                }} className={`card-label fw-medium mb-1 ${screenWidth > 1100 && screenWidth <= 1700 && card.status?.split("").length > 8 ? "ticker-text" : ""}`} >
                                  {card.status}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Button>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </Col>

          <Col xl="12">
            <Card className="card-one shadow-none">
              <Card.Header className="d-flex">
                <Card.Title as="h6" style={{ minWidth: "120px" }}>
                  <i className="ri-shopping-cart-line"></i> Últimos Pedidos
                </Card.Title>
              </Card.Header>
              <Card.Body className="w-100" style={{ overflowX: "auto" }}>
                <div className={`border form-search mb-3 ${screenWidth < 1100 ? "w-100" : "w-20"}`} >
                  <Form className="d-flex w-100" onSubmit={handleSearch}>
                    <Form.Control
                      className="w-100"
                      placeholder="Pesquisar"
                      onChange={handleSearchNoneValue}
                      id="pesquisar"
                      name="pesquisar"
                    ></Form.Control>
                    <Button type="submit" variant="outline">
                      <Search />
                    </Button>
                  </Form>
                </div>
                <Table className="table-four table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Imagem</th>
                      <th className="text-center">Cod.Pedido</th>
                      <th className="text-center">Cliente</th>
                      <th className="text-center">Cod.Peça</th>
                      <th className="text-center">Data</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody className="w-100 h-100">
                    {orderLoading ? (
                      <>
                        <tr className="w-100 h-100">
                          <td rowSpan={10} colSpan={6}>
                            <Card className="w-100 h-100 d-flex text-center align-items-center justify-content-center">
                              <Card.Body className="w-100 h-100 d-flex text-center align-items-center justify-content-center">
                                <ReactLoading type="bars" color={"#506FD9"} />
                              </Card.Body>
                            </Card>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {orders.map((order, index) => (
                          <tr key={index}>
                            <td
                              className="text-center d-flex justify-content-center"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleOpenModalAttachment(order.FILE)
                              }
                            >
                              <div className="product-thumb">
                                <img width={150} src={order.FILE} alt="" />
                              </div>
                            </td>
                            <td className="text-center">{order.CODE}</td>
                            <td
                              style={{
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "left",
                                maxWidth: "150px",
                                zIndex: "-999",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${order.ID}`}>
                                    {order.NAME_CLIENT}
                                  </Tooltip>
                                }
                              >
                                <span
                                  id={order.ID}
                                  onMouseEnter={() => toggleTooltip(order.ID)}
                                  onMouseLeave={() => toggleTooltip(order.ID)}
                                  className="text-truncate"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {order.NAME_CLIENT}
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {order.PART_CODE}
                            </td>
                            <td className="text-center">
                              {formatDate(order.DATE)}
                            </td>
                            <td className="text-center">{`${order.STATUS} ${order.ORDER_PAGE_NAME != null && order.ID_STATUS == 17 ? `(${order.ORDER_PAGE_NAME})` : ""}`}</td>
                            <td className="text-center justify-content-center align-items-center d-flex">
                              <Button
                                className="mx-1 btn-icon"
                                value={order.ID}
                                onClick={() => {
                                  if (order.ID_TYPE_ORDER == 1) {
                                    handleOrderPdf(order.ID);
                                  } else if (order.ID_TYPE_ORDER == 2) {
                                    handleGetFixPdf(order.ID)
                                  }
                                  else if (order.ID_TYPE_ORDER == 3) {
                                    handleGetFundicaoPdf(order.ID);
                                  }
                                }
                                }
                              >
                                <i className="ri-printer-line fs-18 lh-1"></i>
                              </Button>
                              <LogModal order={order} />
                              <Button
                                className="mx-1 btn-icon"
                                value={order.ID}
                                onClick={() => handleOrderView(order.ID)}
                              >
                                <i className="ri-history-line"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </Table>
                <Modal size="xl" show={modalHistoryShow} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Histórico de pedido</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table className="w-100 p-4">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Usuário</th>
                          <th>Descrição</th>
                          <th>Data e Hora</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalHistoryContent.map((content) => (
                          <tr key={content.id}>
                            <td>{content.status}</td>
                            <td>{content.name}</td>
                            <td style={{ whiteSpace: "no-wrap" }}>
                              {content.description.replace(/<br\s*\/?>/g, " ")}
                            </td>
                            <td>{formatDate(content.dh)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Salvar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="xl"
                  fullscreen
                  show={modalViewShow}
                  onHide={handeCloseOrderView}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Pedido</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {orderViewLoading ? (
                      <>
                        <Row>
                          <Col>
                            <Card className="h-100">
                              <Card.Header>
                                <strong className="text-uppercase">
                                  Dados do Cliente
                                </strong>
                              </Card.Header>
                              <Card.Body className="px-4">
                                <dl className="row">
                                  <dt className="col-sm-3 mb-0">Nome:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    {skin === "Dark" ? (
                                      <Skeleton
                                        count={1}
                                        width={200}
                                        baseColor="#292929"
                                        highlightColor="#1A1A1A"
                                      />
                                    ) : (
                                      <Skeleton count={1} width={200} />
                                    )}
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Telefone:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Email:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Endereço:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Bairro:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>
                                  <dt className="col-sm-3 mb-0">
                                    Código do Cliente:
                                  </dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>
                                </dl>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col>
                            <Card>
                              <Card.Header>
                                <strong>Dados do Pedido</strong>
                              </Card.Header>
                              <Card.Body>
                                <dl className="row">
                                  <dt className="col-sm-3 mb-0">Numero:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={50} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Tipo:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Status:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Data:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>
                                </dl>
                                <div className="divider"></div>
                                <dl className="row d-flex flex-column">
                                  <dt className="mb-0">
                                    Descrição inicial do pedido:
                                  </dt>
                                  <dd className="mb-0">
                                    <Skeleton count={1} width={200} />
                                  </dd>
                                </dl>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Código da Peça: </strong>
                                <Skeleton count={1} width={200} />
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Categoria: </strong>
                                <Skeleton count={1} width={200} />
                              </span>{" "}
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Peso estimado da Peça: </strong>
                                <Skeleton count={1} width={100} />
                              </span>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material: </strong>
                                <Skeleton count={1} width={200} />{" "}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Cor metal: </strong>
                                <Skeleton count={1} width={200} />
                              </span>{" "}
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Quantidade de Peças: </strong>
                                <Skeleton count={1} width={100} />
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material Necessário/peça: </strong>
                                <Skeleton count={1} width={100} />
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material Necessário/Total: </strong>
                                <Skeleton count={1} width={100} />
                              </span>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2 text-center">
                              <span>
                                <strong>Descrição resumida: </strong>
                                <Skeleton count={1} width={200} />
                              </span>{" "}
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Espessura média da chapa: </strong>
                                <Skeleton count={1} width={200} />
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Espessura média da grifas: </strong>
                                <Skeleton count={1} width={200} />
                              </span>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>
                                  Tamanho, largura e espessura do aro:{" "}
                                </strong>
                                <Skeleton count={1} width={20} />
                              </span>{" "}
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>
                                  Tamanho do colar (colares e rivieras):{" "}
                                </strong>
                                <Skeleton count={1} width={20} />
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Argolas: </strong>{" "}
                                <Skeleton count={1} width={200} />
                              </span>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="mb-3">
                          <Col>
                            <Card className="h-100 card-one shadow-none">
                              <Card.Header>
                                <strong className="text-uppercase">
                                  Dados do Cliente
                                </strong>
                              </Card.Header>
                              <Card.Body className="px-4">
                                <dl className="row">
                                  <dt className="col-sm-3 mb-0">Nome:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    {modalViewContent.client_name}
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Telefone:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    <a
                                      style={{ color: "green" }}
                                      href={
                                        "https://wa.me/55" +
                                        modalViewContent.client_phone
                                      }
                                    >
                                      <i className="ri-whatsapp-line"></i>{" "}
                                      {modalViewContent.client_phone}
                                    </a>
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Email:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    {modalViewContent.client_email}
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Endereço:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    {modalViewContent.client_address_street},{" "}
                                    {modalViewContent.client_address_number}
                                  </dd>

                                  <dt className="col-sm-3 mb-0">Bairro:</dt>
                                  <dd className="col-sm-9 mb-0">
                                    {
                                      modalViewContent.client_address_neighborhood
                                    }
                                  </dd>

                                  <dt className="col-sm-3 mb-0">
                                    Código do Cliente:
                                  </dt>
                                  <dd className="col-sm-9 mb-0">
                                    {modalViewContent.client_code}
                                  </dd>
                                </dl>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="card-one shadow-none">
                              <Card.Header>
                                <strong>DADOS DO PEDIDO</strong>
                              </Card.Header>
                              <form onSubmit={handeSubmitEditOrderDescription}>
                                <Card.Body>
                                  <dl className="row">
                                    <dt className="col-sm-3 mb-0">Numero:</dt>
                                    <dd className="col-sm-9 mb-0">
                                      {modalViewContent.order_code}
                                    </dd>

                                    <dt className="col-sm-3 mb-0">Tipo:</dt>
                                    <dd className="col-sm-9 mb-0">
                                      {modalViewContent.order_type}
                                    </dd>

                                    <dt className="col-sm-3 mb-0">Status:</dt>
                                    <dd className="col-sm-9 mb-0">
                                      {modalViewContent.status_name}
                                    </dd>

                                    <dt className="col-sm-3 mb-0">Data:</dt>
                                    <dd className="col-sm-9 mb-0">
                                      {formatDate(modalViewContent.date)}
                                    </dd>
                                  </dl>
                                  <div className="divider"></div>
                                  <dl className="row d-flex flex-column">
                                    <dt className="mb-0">
                                      Descrição inicial do pedido:
                                    </dt>
                                    <dd className="mb-0">

                                      {editingDescriptionMode && utp == "interno" ? <Editor
                                        onInit={(_evt, editor) => editorRef.current = editor}
                                        onChange={(e) => {
                                          setNewOrderDescription(e.target.getContent())
                                          setModalViewContent({ ...modalViewContent, description: e.target.value })
                                        }}
                                        apiKey='gsmvci1znnyamtj9fuwif8r32sjrds5s7unvbjbszdm09jxk'
                                        init={{
                                          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
                                          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                          tinycomments_mode: 'embedded',
                                          tinycomments_author: 'Author name',
                                          mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                          ],
                                          ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                        }}
                                        initialValue={modalViewContent.description} /> : <div dangerouslySetInnerHTML={{ __html: modalViewContent.description }}></div>}

                                    </dd>
                                  </dl>
                                </Card.Body>
                                <Card.Footer className="d-flex flex-row gap-2">

                                  {editingDescriptionMode && utp == "interno" ?
                                    <>
                                      <Button type="submit">
                                        Salvar
                                      </Button>
                                      <Button onClick={() => setEditingDescriptionMode(false)} variant="secondary">
                                        Cancelar
                                      </Button>
                                    </>
                                    : null
                                  }

                                  {utp == 'interno' && !editingDescriptionMode ? <Button onClick={() => setEditingDescriptionMode(true)}>Editar</Button> : null}

                                </Card.Footer>
                              </form>
                            </Card>
                          </Col>
                        </Row>

                        {/* <Row>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Código da Peça: </strong>
                                {modalViewContent.part_code}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Categoria: </strong>
                                {modalViewContent.category}
                              </span>{" "}
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 mx-0 p-2">
                              <span>
                                <strong>Peso estimado da Peça: </strong>
                                {modalViewContent.info_01}
                              </span>
                            </Card>
                          </Col>
                        </Row> */}

                        {/* <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material: </strong>
                                {modalViewContent.material}{" "}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Cor metal: </strong>
                                {modalViewContent.material_color}
                              </span>{" "}
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Quantidade de Peças: </strong>
                                {modalViewContent.metal_weight_unit}(un)
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material Necessário/peça: </strong>
                                {modalViewContent.metal_weight_total}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Material Necessário/Total: </strong>
                                {modalViewContent.metal_weight_total}(g)
                              </span>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2 text-center">
                              <span>
                                <strong>Descrição resumida: </strong>
                                {modalViewContent.short_description}
                              </span>{" "}
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Espessura média da chapa: </strong>
                                {modalViewContent.info_02}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Espessura média da grifas: </strong>
                                {modalViewContent.info_03}
                              </span>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>
                                  Tamanho, largura e espessura do aro:{" "}
                                </strong>
                                {modalViewContent.rim_size}
                              </span>{" "}
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>
                                  Tamanho do colar (colares e rivieras):{" "}
                                </strong>
                                {modalViewContent.info_05}
                              </span>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 p-2">
                              <span>
                                <strong>Argolas: </strong>{" "}
                                {modalViewContent.info_06}
                              </span>
                            </Card>
                          </Col>
                        </Row> */}
                      </>
                    )}

                    <Accordion className="d-flex flex-column gap-3" alwaysOpen>
                      <Accordion.Item className="border" eventKey="0">
                        <Accordion.Header>Metal</Accordion.Header>
                        <Accordion.Body>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                <th>Tipo</th>
                                <th>Peso (G)</th>
                                <th>Data de atribuição</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metal.map((metalDetail) => (
                                <tr>
                                  <td>{metalDetail.metal}</td>
                                  <td>{metalDetail.amount}</td>
                                  <td>{metalDetail.assignment_date}</td>
                                  <td className="text-center">
                                    <Button
                                      disabled={
                                        metalDetail.permission === "0"
                                          ? "disabled"
                                          : ""
                                      }
                                      onClick={() => {
                                        if (metalDetail.permission == "0") {
                                          swalErro("Você não possui permissão para isso!")
                                        } else {
                                          handleOpenMetalAtribuicaoModal(
                                            metalDetail.id
                                          )
                                        }
                                      }
                                      }
                                    >
                                      Atribuir Metal
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="border" eventKey="1">
                        <Accordion.Header>Módulos</Accordion.Header>
                        <Accordion.Body className="d-flex flex-wrap">
                          {module.map((card) => (
                            <Card className="mx-1 mb-2">
                              <Card.Img
                                src={
                                  "https://www.apicriartgold.qtsys.com.br/getfile?type=file&name=" +
                                  card.file
                                }
                                variant="top"
                              />
                              <Card.Body>
                                <Card.Title className="text-dark">
                                  {card.name}
                                </Card.Title>
                                <Card.Title>Peso: {card.weight}</Card.Title>
                              </Card.Body>
                            </Card>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="border" eventKey="2">
                        <Accordion.Header>Cravação</Accordion.Header>
                        <Accordion.Body>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                <th>Módulo</th>
                                <th>Mat.Prima</th>
                                <th>Descrição</th>
                                <th>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metal.map((metalDetail) => (
                                <tr>
                                  <td>{metalDetail.metal}</td>
                                  <td>{metalDetail.amount}</td>
                                  <td>{metalDetail.assignment_date}</td>
                                  <td>ações</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="border" eventKey="3">
                        <Accordion.Header>Tipo de Cravação</Accordion.Header>
                        <Accordion.Body>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                <th>Tipo</th>
                                <th>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {typeNailing.map((type) => (
                                <tr>
                                  <td>{type.name}</td>
                                  <td>{type.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="border" eventKey="4">
                        <Accordion.Header>Anexos</Accordion.Header>
                        <Accordion.Body className="d-flex flex-wrap">
                          {attachment.map((card) => (
                            <Card className="w-25 mx-3">
                              <Card.Img
                                src={
                                  "https://www.apicriartgold.qtsys.com.br/getfile?type=file&name=" +
                                  card.file
                                }
                                variant="top"
                              />
                              <Card.Body>
                                <Card.Title className="text-dark">
                                  {card.type}
                                </Card.Title>
                                <Card.Title>
                                  Descrição: {card.description}
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handeCloseOrderView}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="xl"
                  show={openEditModal}
                  onHide={closeEditModal}
                  onOpened={() => document.body.classList.add("modal-open")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Histórico de pedido</Modal.Title>
                  </Modal.Header>

                  <form onSubmit={handleUpdateStatus}>
                    <Modal.Body>
                      <Card className="card-one d-flex text-center">
                        <Card.Title>
                          Status Atual do Pedido: {orderDataToStatus.status}
                        </Card.Title>
                      </Card>

                      <Card className="card-one d-flex align-items-center justify-content-center text-center w-100">
                        <div
                          className="d-flex flex-wrap justify-content-center"
                          style={{ maxWidth: "80%" }}
                        >
                          {statusToUpdate.map((status) => (
                            <Button
                              style={{ width: "250px", margin: "5px" }}
                              value={status.id}
                              name="status"
                              id="status"
                              onClick={(e) =>
                                handleSelectedStatus(e.target.value)
                              }
                            >
                              {status.name}
                            </Button>
                          ))}
                        </div>
                      </Card>

                      <Card className="mt-3 card-one d-flex text-center">
                        <Card.Title>
                          Novo status: {statusSelecionado.name}
                        </Card.Title>
                      </Card>

                      <Card className="mt-3 card-one d-flex text-center">
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"
                          placeholder="Digite a descrição"
                        ></textarea>
                      </Card>
                    </Modal.Body>
                    <input
                      type="hidden"
                      id="status_id"
                      name="status_id"
                      value={statusSelecionado.id}
                    ></input>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Fechar
                      </Button>
                      <Button variant="primary" type="submit">
                        Atualizar
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>

                <Modal
                  size="xl"
                  show={openModalMetalAtribuicao}
                  onHide={closeModalMetalAtribuicao}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Atrbuir saldo de metal</Modal.Title>
                  </Modal.Header>
                  <form onSubmit={handleAtribuirMetal}>
                    <Modal.Body className="g-3">
                      <Card className="d-flex align-items-center justify-content-center p-3 mb-3">
                        <h4>
                          Material: {modalMetalAtribuicaoDetal.material_name}
                        </h4>
                        <input
                          type="hidden"
                          name="metal"
                          id="metal"
                          value={modalMetalAtribuicaoDetal.metal}
                        ></input>
                      </Card>
                      <Row>
                        <Col>
                          <Card className="d-flex align-items-center justify-content-center">
                            <h4>
                              Quantidade Necessária:{" "}
                              {modalMetalAtribuicaoDetal.require_amount}
                            </h4>
                          </Card>
                        </Col>
                        <Col>
                          <Card className="d-flex align-items-center justify-content-center">
                            <h4>
                              Saldo do Cliente:{" "}
                              {modalMetalAtribuicaoDetal.balance_client}
                            </h4>
                          </Card>
                        </Col>

                        <Col md={12}>
                          <textarea
                            className="mt-3 form-control"
                            name="modal_form_metal_input_text"
                            id="modal_form_metal_input_text"
                            placeholder="Observação"
                          ></textarea>
                        </Col>
                      </Row>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={closeModalMetalAtribuicao}
                      >
                        Fechar
                      </Button>
                      <Button variant="primary" type="submit">
                        Atribuir
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Pagination className="pagination-space pagination-circled mb-0">
                  {Array.from({ length: totalPages }, (_, index) => {
                    if (
                      index + 1 === currentPage ||
                      index + 1 === 1 ||
                      index + 1 === totalPages ||
                      (index + 1 >= currentPage - 2 &&
                        index + 1 <= currentPage + 2)
                    ) {
                      return (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageClick(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      );
                    } else if (
                      (index + 1 === currentPage - 3 && currentPage > 5) ||
                      (index + 1 === currentPage + 3 &&
                        currentPage < totalPages - 4)
                    ) {
                      return (
                        <Pagination.Ellipsis
                          key={index + 1}
                          onClick={() => handlePageClick(index + 1)}
                        />
                      );
                    }
                    return null;
                  })}
                </Pagination>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Modal
          size="lg"
          show={modalAttachmentShow}
          onHide={handleCloseModalAttachment}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-center">
                  <img
                    src={modalAttachment}
                    className="img-fluid"
                    alt="Attachment"
                  />
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>

        <Footer />
        <ToastContainer />
      </div >
    </React.Fragment >
  );
}