import { apiServices } from "../apiServices/ApiServices";

export const GetTreeData = (dados, lote, metal_code, metal_color_code) => {

  const Cores = dados.map((dado) => dado.ORDER_MATERIAL_COLOR_CODE);
  const Metais = dados.map((dado) => dado.MATERIAL_CODE);
  const MetalUnico = Array.from(new Set(Metais));
  var Total = 0;

  var MetalCorArray = [];

  // Itera sobre os dados para criar um array de objetos {metal, cor}
  dados.forEach((dado) => {
    MetalCorArray.push({
      metal: dado.MATERIAL_CODE,
      cor: dado.MATERIAL_COLOR,
    });
  });

  for (let i = 0; i < dados.length; i++) {
    Total += dados[i].MATERIAL_WEIGHT;
  }
  // Obtendo valores únicos para Metal
  const Metal = MetalUnico.map((item, index) => {
    if (MetalUnico.length > 1 && index !== MetalUnico.length - 1) {
      return item;
    } else {
      return item;
    }
  });

  // const CoresSplit = Cores.map((material) => material.split(" "));
  const coresUnicas = Array.from(new Set(Cores.map((material) => material)));

  const Cor = coresUnicas.map((item, index) => {
    if (coresUnicas.length > 1 && index !== coresUnicas.length - 1) {
      return item;
    } else {
      return item;
    }
  });

  const date = new Date();

  const year = date.getFullYear().toString().slice(-2);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const arvore = `${dados.length}-${Metal.join("")}-${Cor.join(
    ""
  )}-0${year}-${month}-${day}-${hour}${minute}${second}`;

  const soma = dados.reduce((total, pedido) => total + pedido.ORDER_WEIGHT, 0);
  const soma_final = dados.reduce(
    (total, pedido) => total + pedido.ORDER_FINAL_WEIGHT,
    0
  );

  const data = {
    tree: arvore,
    metal_code: metal_code,
    metal_color: metal_color_code,
    metalCor: MetalCorArray,
    soma_pedidos: soma,
    order_weight: soma,
    batch: lote.id,
  };
  return data;
};
