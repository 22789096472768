import { createContext, useContext, useEffect, useState } from "react";
import Loading from "../../components/Load/Load";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const handleEndLoad = () => {
    setLoading(false);
  };
  return (
    <LoadingContext.Provider value={{ handleEndLoad }}>
      <Loading loading={loading}>{children}</Loading>
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
