import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Lotes from "../components/Lotes";
import Lista from "../components/Lista";
import { toast, ToastContainer } from "react-toastify";
import { apiServices } from "../apiServices/ApiServices";
import { FloatButton } from "../components/FloatButton";
import { ListaPedidos } from "../components/ListaPedidos/ListaPedidos";
import { Dialog } from "../components/DialogConfirmacao";
import ListaCravacao from "../components/Cravacao/ListaCravacao";
import ControleCravacao from "./Controle/Cravacao";
import { SaidaPedidosCravacao } from "../components/SaidaPedidosCravacao/SaidaPedidosCravacao";

const Cravacao = () => {
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");
  const page = 14;
  const status = 8;
  const [dnone, setDnone] = useState("");
  const [buscar, setBuscar] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const handleGetSelectedUser = user => {
    setSelectedUser(user);
  };

  const [dialogShow, setDialogShow] = useState(false);
  const [responseDialog, setResponseDialog] = useState(false);

  const [showEnterMaterial, setShowEnterMaterial] = useState(false);

  const [permissionUpdate, setPermissionUpdate] = useState(false);

  const [materialRecebido, setMaterialRecebido] = useState(0);
  const [inputs, setInputs] = useState([""]);
  const [materialParent, setMaterialParent] = useState([]);

  const handleGetNextPage = async () => {
    const data = {
      page: page + 1,
      token: token,
    };
    const response = await apiServices.getPage(data);
    if (response) {
      setNextPage(response.data.data);
    }
  };

  const handleGetMaterialParent = e => {
    if (e) {
      setMaterialParent(e);
    }
  };

  const handleGetDataParent = value => {
    if (value) {
      closeModal();
      setBuscar(true);
    }
  };

  const showModalMaterial = () => {
    setShowEnterMaterial(true);
  };

  const closeModalMaterial = () => {
    setShowEnterMaterial(false);
  };

  const handleGetMaterial = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const value = formData.get("material");
  };

  const hideModal = () => {
    setDnone("d-none");
  };

  const closeModal = () => {
    setShow(false);
  };

  const showModal = () => {
    setShow(true);
  };

  const handleChildData = e => {
    setResponseDialog(e);
  };

  const closeDialog = () => {
    setDialogShow(false);
    setDnone("");
  };

  const addInput = () => {
    const newInputs = [...inputs, ""];
    setInputs(newInputs);
  };

  // Função para atualizar o valor de um input específico
  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  useEffect(() => {
    if (responseDialog === true) {
      closeDialog();
      showModal();
      toast.loading("Processando...", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTimeout(() => {
        setPermissionUpdate(true);
        setResponseDialog(false);
      }, 1000);
    }
  }, [responseDialog]);

  useEffect(() => {
    handleGetNextPage();
  }, []);

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Cravação</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Body className="d-flex justify-content-between">
                <div>
                  <Button
                    className="font-lg text-center d-flex align-items-center justify-content-center rounded-md"
                    variant="primary"
                    onClick={() => showModal()}
                  >
                    Iniciar Cravação
                  </Button>
                </div>
                {/* <SaidaPedidosCravacao /> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <ControleCravacao />
          </Col>
          {/* <Col xl="12">
            <ListaCravacao
              nextPage={nextPage}
              titulo={"Cravação"}
              page={page}
              status={status}
              buscar={buscar}
            />
          </Col> */}
        </Row>
      </div>

      <Modal
        size="xl"
        className={dnone}
        backdrop="static"
        show={show}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <h4>Recebimento de pedidos</h4>
        </Modal.Header>
        <Modal.Body>
          <ListaPedidos
            permissionType={[17, 18]}
            permissionUpdate={permissionUpdate}
            materialTotal={materialRecebido}
            page={page}
            user={handleGetSelectedUser}
            status={status}
            sendDataToParent={handleGetDataParent}
            sendMaterialToParent={handleGetMaterialParent}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setDialogShow(true);
              hideModal();
            }}
          >
            Finalizar
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
      <Dialog
        showDialog={dialogShow}
        closeDialog={closeDialog}
        sendDataToParent={handleChildData}
        title={"Iniciar fundição"}
        question={"Deseja realmente começar o acabamento dos pedidos?"}
      />
    </React.Fragment>
  );
};

export default Cravacao;
