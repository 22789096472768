import React, { useContext, useEffect, useState } from "react";
import Header from "../layouts/Header";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Lotes from "../components/Lotes";
import Lista from "../components/Lista";
import { toast, ToastContainer } from "react-toastify";
import { apiServices } from "../apiServices/ApiServices";
import { FloatButton } from "../components/FloatButton";
import { ListaPedidos } from "../components/ListaPedidos/ListaPedidos";
import { Dialog } from "../components/DialogConfirmacao";
import { BalanceContext } from "../Context/BalanceContext/BalanceContext";
import EntradaInsumos from "../components/EntradaInsumos/EntradaInsumos";
import { Link } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import ListaOurives from "../components/ListaOurives/Lista";
import { RetornoOurives } from "../components/Ourives/RetornoPedidosOurives";
import { SaidaPedidos } from "../components/ListaPedidos/Acabamento/SaidaPedidos";
import { OrderContext } from "../Context/OrderContext";

const Acabamento = () => {
  const { listUser } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");
  const page = 13;
  const status = 18;
  const [dnone, setDnone] = useState("");
  const [buscar, setBuscar] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  const [dialogShow, setDialogShow] = useState(false);
  const [responseDialog, setResponseDialog] = useState(false);

  const [showEnterMaterial, setShowEnterMaterial] = useState(false);

  const [permissionUpdate, setPermissionUpdate] = useState(false);

  const [materialRecebido, setMaterialRecebido] = useState(0);
  const [inputs, setInputs] = useState([""]);
  const [materialParent, setMaterialParent] = useState([]);

  const { getOperationType, getOperationCode, listMovimentationType } =
    useContext(BalanceContext);

  const [operationTypeState, setOperationType] = useState([]);
  const [operationCode, setOperationCode] = useState([]);

  const [movimentationType, setMovimentationType] = useState([]);

  const handleGetOperationType = async () => {
    const response = await getOperationType();
    setOperationType(response.data);

    const payload = {
      id_operation_type: 1,
    };

    const responseOperationCode = await getOperationCode(payload);
    setOperationCode(responseOperationCode.data.sort());

    const responseMovimentationType = await listMovimentationType();
    setMovimentationType(responseMovimentationType.data.result);
  };
  const [statusToFetch, setStatusToFetch] = useState(false);
  const handleGetStatusToFetch = e => {
    setStatusToFetch(e);
  };

  useEffect(() => {
    handleGetOperationType();
  }, []);

  const handleGetNextPage = async () => {
    const data = {
      page: page + 1,
      token: token,
    };
    const response = await apiServices.getPage(data);
    if (response) {
      setNextPage(response.data.data);
    }
  };

  const handleGetMaterialParent = e => {
    if (e) {
      setMaterialParent(e);
    }
  };

  const handleGetDataParent = value => {
    if (value) {
      closeModal();
      setBuscar(true);
    }
  };

  const [selectedUser, setSelectedUser] = useState(null);

  const handleGetSelectedUser = user => {
    setSelectedUser(user);
  };

  const showModalMaterial = () => {
    setShowEnterMaterial(true);
  };

  const closeModalMaterial = () => {
    setShowEnterMaterial(false);
  };

  const handleGetMaterial = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const value = formData.get("material");
  };

  const hideModal = () => {
    setDnone("d-none");
  };

  const closeModal = () => {
    setShow(false);
  };

  const [showModalInsumos, setShowModalInsumos] = useState(false);

  const showModalLancamentoInsumos = () => {
    setShowModalInsumos(true);
  };

  const [modalRetornoPedidos, setModalRetornoPedidos] = useState(false);
  const showModalRetornoPedidos = () => {
    setModalRetornoPedidos(true);
  };

  const closeModalRetornoPedidos = () => {
    setModalRetornoPedidos(false);
  };

  const closeModalLancamentoInsumos = () => {
    setShowModalInsumos(false);
  };
  const showModal = () => {
    setShow(true);
  };

  const [modalSaidaShow, setModalSaidaShow] = useState(false);
  const openModalSaida = () => {
    setModalSaidaShow(true);
  };
  const { resetListOrders } = useContext(OrderContext);
  const handleCloseModalSaida = () => {
    setModalSaidaShow(false);
    resetListOrders();
  };

  const handleChildData = e => {
    setResponseDialog(e);
  };

  const closeDialog = () => {
    setDialogShow(false);
    setDnone("");
  };

  const addInput = () => {
    const newInputs = [...inputs, ""];
    setInputs(newInputs);
  };

  // Função para atualizar o valor de um input específico
  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  useEffect(() => {
    if (responseDialog === true) {
      closeDialog();
      showModal();
      toast.loading("Processando...", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTimeout(() => {
        setPermissionUpdate(true);
        setResponseDialog(false);
      }, 1000);
    }
  }, [responseDialog]);

  useEffect(() => {
    handleGetNextPage();
  }, []);

  const localVisualizacaoStorage = localStorage.getItem("show_infos_ourives");
  const [visualizacao, setVisualizacao] = useState(
    localVisualizacaoStorage ? JSON.parse(localVisualizacaoStorage) : false
  );

  const alternarVisualizacao = () => {
    setVisualizacao(!visualizacao);
  };

  useEffect(() => {
    localStorage.setItem("show_infos_ourives", JSON.stringify(visualizacao));
  }, [visualizacao]);

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Acabamento</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Body className="d-flex gap-3 justify-content-between">
                <div className="d-flex flex-row gap-3">
                  <Button
                    className="font-lg text-center d-flex align-items-center justify-content-center rounded-md"
                    variant="primary"
                    onClick={() => showModal()}
                  >
                    Atribuir Pedidos
                  </Button>

                  <Button
                    className="font-lg text-center d-flex align-items-center justify-content-center rounded-md"
                    variant="primary"
                    onClick={() => openModalSaida()}
                  >
                    Saída de Pedidos
                  </Button>

                  <Button
                    className="font-lg text-center d-flex align-items-center justify-content-center rounded-md"
                    variant="primary"
                    onClick={() => showModalLancamentoInsumos()}
                  >
                    Operar Insumos
                  </Button>
                </div>

                <div className="m-0 p-0 h-100 d-flex align-items-center justify-content-center">
                  <Link
                    onClick={alternarVisualizacao}
                    className={
                      "nav-link m-0 d-flex align-items-center justify-content-center p-0"
                    }
                  >
                    <h2 className="m-0 p-0">
                      {visualizacao ? (
                        <i class="ri-eye-fill m-0 p-0"></i>
                      ) : (
                        <i class="ri-eye-close-fill"></i>
                      )}
                    </h2>
                    <span className="d-none m-0 p-0">Toggle Visualização</span>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <ListaOurives
              visualizacao={visualizacao}
              returnStatusFetch={handleGetStatusToFetch}
              setStatusToFetch={statusToFetch}
            />
          </Col>
        </Row>
      </div>

      <Modal
        size="xl"
        className={dnone}
        backdrop="static"
        show={show}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <h4>Recebimento de pedidos</h4>
        </Modal.Header>
        <Modal.Body>
          <ListaPedidos
            permissionType={[14, 15]}
            permissionUpdate={permissionUpdate}
            materialTotal={materialRecebido}
            operationType={operationTypeState}
            operationCode={operationCode}
            user={handleGetSelectedUser}
            movimentationType={movimentationType}
            page={page}
            status={status}
            sendDataToParent={handleGetDataParent}
            sendMaterialToParent={handleGetMaterialParent}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setDialogShow(true);
              hideModal();
            }}
          >
            Finalizar
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        className={dnone}
        backdrop="static"
        keyboard={false}
        show={modalRetornoPedidos}
        onHide={closeModalRetornoPedidos}
      >
        <Modal.Header closeButton>
          <h4>Retorno de pedidos - Ourives</h4>
        </Modal.Header>
        <Modal.Body>
          <RetornoOurives
            permissionType={[14, 15]}
            permissionUpdate={permissionUpdate}
            materialTotal={materialRecebido}
            operationType={operationTypeState}
            operationCode={operationCode}
            movimentationType={movimentationType}
            page={page}
            status={status}
            sendDataToParent={handleGetDataParent}
            sendMaterialToParent={handleGetMaterialParent}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setDialogShow(true);
              hideModal();
            }}
          >
            Finalizar
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal fullscreen show={modalSaidaShow} onHide={handleCloseModalSaida}>
        <Modal.Header closeButton>
          <Modal.Title>Saída de Pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-one">
            <SaidaPedidos
              operationType={operationTypeState}
              movimentationType={movimentationType}
              operationCode={operationCode}
              setStatusToFetch={handleGetStatusToFetch}
              page={page}
            />
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModalSaida} variant="secondary">
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <EntradaInsumos
        show={showModalInsumos}
        permissionType={[14, 15]}
        operationType={operationTypeState}
        movimentationType={movimentationType}
        operationCode={operationCode}
        close={closeModalLancamentoInsumos}
        page={page}
        status={status}
      />
      <ToastContainer />
      <Dialog
        showDialog={dialogShow}
        closeDialog={closeDialog}
        sendDataToParent={handleChildData}
        user={selectedUser}
        title={"Iniciar fundição"}
        question={"Deseja realmente começar o acabamento dos pedidos?"}
      />
    </React.Fragment>
  );
};

export default Acabamento;
