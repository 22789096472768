import api from "./api";

export const DashboardServices = {
    getDataToBalanceController: (data) => {
        return api.post('./balanceController.php', {
            "action": data.action,
            "token": data.token,
            "search": data.search
        })
    },
    
}