import { useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { productServices } from "../../apiServices/productServices";
import { swalErro, swalSucesso } from "../Swal";

export const UpdateModuleImage = ({ module }) => {
	const [image, setImage] = useState("");
	const token = localStorage.getItem("token");
	const [previewImage, setPreviewImage] = useState("");
	const [showModal, setShowModal] = useState(false);

	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const handleSetImage = async e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setPreviewImage(reader.result);
			setImage(reader.result);
		};

		reader.readAsDataURL(file);
	};

	const handleSubmitNewImage = async e => {
		e.preventDefault();
		const base64image = image?.split(",")[1];
		const payload = {
			id_module: module.id,
			image: base64image,
			token: token,
		};

		const response = await productServices.productModuleUpdateImage(payload);
		if (response.data.status == "success") {
			swalSucesso(response.data.msg);
		} else {
			swalErro(response.data.msg);
		}
	};

	return (
		<>
			<Button onClick={handleOpenModal}>Editar Imagem</Button>
			<Modal fullscreen show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Atualizar Imagem</Modal.Title>
				</Modal.Header>
				<form onSubmit={handleSubmitNewImage}>
					<Modal.Body>
						<Card className="w-100 card-one">
							<Card.Body>
								<strong>Módulo: </strong>
								<span>{module.name}</span>
								<Card>
									<Card.Body className="d-flex flex-row gap-3">
										<Card className="w-25">
											<Card.Body>
												<Card.Title>Imagem Antiga</Card.Title>
												<img width={"100%"} src={module.file} alt="" />
											</Card.Body>
										</Card>
										<Card className="w-25">
											<Card.Body>
												<Card.Title>Imagem Nova</Card.Title>
												<img width={"100%"} src={previewImage} alt="" style={{ display: previewImage ? "block" : "none" }} />
											</Card.Body>
										</Card>
									</Card.Body>
								</Card>
							</Card.Body>
						</Card>
						<Card className="mt-3">
							<Card.Body>
								<Form.Group>
									<Form.Label as="h4">Selecionar Imagem</Form.Label>
									<Form.Control onChange={handleSetImage} type="file" />
								</Form.Group>
							</Card.Body>
						</Card>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={handleCloseModal} variant="secondary">
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Salvar
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		</>
	);
};
