import { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  Table,
  ToastContainer,
} from "react-bootstrap";
import { apiServices } from "../../../apiServices/ApiServices";
import withReactContent from "sweetalert2-react-content";
import { Swal } from "sweetalert2/dist/sweetalert2";
import { toast } from "react-toastify";
import { dialog, swalErro, swalSucesso, toastSucesso } from "../../Swal";
import { NailingContext } from "../../../Context/NailingContext/Context";
import { apenasNumeros } from "../../../functions/Mask";
import { FaCheck } from "react-icons/fa6";
import { UserContext } from "../../../Context/UserContext";

const ModalSolicitacao = () => {
  const token = localStorage.getItem("token");
  const [orderNailing, setOrderNailing] = useState([]);
  const { SaveSolicitacao, listaSolicitacao } = useContext(NailingContext);
  const [show, setShow] = useState(false);
  const inputRef = useRef(null);
  const [pedidos, setPedidos] = useState([]);
  const [value, setValue] = useState("");
  const [codigoPedidos, setCodigoPedidos] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { listUser } = useContext(UserContext);
  let timerId = null;
  const handleModalEntered = () => {
    inputRef.current && inputRef.current.focus();
  };
  const handleGetCode = e => {
    toast.dismiss();
    const valor = e.target.value;
    setValue(valor);

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      handleGetOrder(token, valor);
    }, 1000);
  };

  const [clientSelected, setClientSelected] = useState(null);
  const handleSetClient = e => {
    setClientSelected(e.target.value);
  };

  const [clientList, setClientList] = useState([]);
  const handleGetUser = async () => {
    const response = await listUser({
      token: token,
      page: "client",
      limit: 9999,
      permission_type: 1,
    });
    setClientList(response.data.users);
  };

  const handleGetOrder = async (token, order) => {
    let response;

    if (order.length === 10) {
      toast.loading("Processando...");
      response = await apiServices.getOrder(
        token,
        1,
        1,
        order,
        null,
        null,
        true,
        false,
        null,
        null,
        true
      );
    }

    if (response.data.orders.length !== 0) {
      const existe = pedidos.find(
        item => item.CODE === response.data.orders[0].CODE
      );

      const permite = true;

      const mesmoCliente =
        clientSelected == response?.data.orders[0].ID_CLIENTE;

      if (!existe && mesmoCliente) {
        setPedidos(prevPedidos => [...prevPedidos, response.data.orders[0]]);
        setCodigoPedidos(prevPedidos => [
          ...prevPedidos,
          response.data.orders[0].ID,
        ]);
        if (response.data.nailing?.length > 0) {
          setOrderNailing(prevNailing => [
            ...prevNailing,
            ...response.data.nailing,
          ]);
        }
        toast.dismiss();
        toastSucesso("Pedido adicionado com sucesso!");
        setValue("");
        handleModalEntered();
      } else if (!mesmoCliente) {
        toast.dismiss();
        swalErro("Pedido de cliente diferente do cliente selecionado.");
        setValue("");
        handleModalEntered();
      } else {
        toast.dismiss();
        swalErro("Este pedido já foi adicionado.");
        setValue("");
        handleModalEntered();
      }
    } else {
      toast.dismiss();
      swalErro(
        "Nenhum pedido encontrato com este código. Para mais informações, entre em contato com o nosso setor de suporte!"
      );
      setValue("");
      handleModalEntered();
    }
  };

  const removeOrder = orderCode => {
    const index = pedidos.findIndex(pedido => pedido.CODE === orderCode);

    if (index > -1) {
      const updatedPedidos = [...pedidos];
      updatedPedidos.splice(index, 1);
      const updatedCodigo = [...updatedPedidos.map(pedido => pedido.ID)];
      setPedidos(updatedPedidos);
      setCodigoPedidos(updatedCodigo);
      toast.success("Pedido removido com sucesso!");
    }

    const updatedNailing = orderNailing.filter(
      pedido => pedido.order_code !== orderCode
    );
    setOrderNailing(updatedNailing);

    const updatedSelectedItems = selectedItems.filter(
      pedido => pedido.order_code !== orderCode
    );

    setSelectedItems(updatedSelectedItems);
  };

  const [amount, setAmount] = useState(null);

  const handleSave = async () => {
    const confirm = await dialog("Deseja realmente salvar a solicitação?");

    if (confirm.isConfirmed) {
      const payload = {
        id_orders: codigoPedidos,
        item: selectedItems,
        amount: amount,
      };

      const response = await SaveSolicitacao(payload);
      if (response.data.status == "success") {
        listaSolicitacao();
        setShow(false);
      }
    }
  };

  const handleSelectItems = item => {
    setSelectedItems(prevState => [...prevState, item]);
  };

  const handleRemoveItem = item => {
    const index = selectedItems.findIndex(
      itemSelected => itemSelected === item
    );
    if (index > -1) {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
    }
  };

  const [item, setItem] = useState({
    description: "",
    amount: "",
  });

  const [description, setDescription] = useState("");
  const [itemAmount, setItemAmount] = useState("");
  const [idOrder, setIdOrder] = useState("");

  const handleAddItem = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const idOrder = formData.get("id_order");
    const codigoPedido = pedidos.find(pedido => pedido.ID == idOrder).CODE;
    const item = {
      id_order: idOrder,
      order_code: codigoPedido,
      description: formData.get("description"),
      amount: formData.get("amount"),
    };
    handleSelectItems(item);
    setDescription("");
    setItemAmount("");
    setIdOrder("");
  };

  return (
    <>
      <ToastContainer />
      <Button
        onClick={() => {
          handleGetUser();
          setShow(true);
        }}
      >
        Abrir solicitação
      </Button>
      <Modal fullscreen show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Abrir Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-one">
            <Card.Body>
              <div className="d-flex flex-row gap-3">
                <div className="w-100 h-100">
                  <Card className="h-100">
                    <Card.Body className="h-100">
                      <Form.Label className="text-center w-100">
                        <strong>Selecione o cliente</strong>
                      </Form.Label>
                      <Form.Select
                        onChange={e => handleSetClient(e)}
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        <option value={"null"}>Selecione o cliente</option>
                        {clientList?.map(client => (
                          <option key={client.ID} value={client.ID}>
                            {client.NAME}
                          </option>
                        ))}
                      </Form.Select>
                    </Card.Body>
                  </Card>
                </div>

                <div className="w-100 h-100">
                  <Card className="h-100">
                    <Card.Body className="h-100">
                      <Form.Label className="text-center w-100">
                        <strong>
                          Aguarde o leitor ou digite o código do pedido
                        </strong>
                      </Form.Label>
                      <Form.Control
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        disabled={clientSelected == null}
                        type="text"
                        placeholder="Digite o código do pedido"
                        className="bg-white display-3 h-20 z-3 w-100 text-center"
                        ref={inputRef}
                        autoFocus={true}
                        id="code-form"
                        value={value}
                        onChange={handleGetCode}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex flex-row gap-3">
            {pedidos.length > 0 ? (
              <Card className="card-one mt-3 w-100">
                <Card.Body>
                  <div className="border-bottom mb-3 ">
                    <Card.Title>Itens para seleção</Card.Title>
                  </div>
                  <Card className="py-3 px-2">
                    <div
                      className="p-1"
                      style={{
                        height: "400px",
                        overflowY: "scroll",
                      }}
                    >
                      {orderNailing?.length > 0 ? (
                        <div className="d-flex flex-column gap-3">
                          {pedidos.map((pedido, index) => (
                            <Card className="card-one">
                              <Card.Body>
                                <div className="border-bottom d-flex flex-row justify-content-between align-items-center">
                                  <Card.Title>
                                    <strong>Pedido</strong>: {pedido.CODE} -{" "}
                                    <strong>Produto</strong>: {pedido.PART_CODE}
                                  </Card.Title>
                                  <Button
                                    onClick={e => removeOrder(pedido.CODE)}
                                    className="mb-3"
                                    variant="danger"
                                  >
                                    <i className="ri-delete-bin-6-line"></i>
                                  </Button>
                                </div>
                                <Table
                                  className=" table-bordered mt-3"
                                  style={{ tableLayout: "fixed" }}
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-center w-60">
                                        Pedido
                                      </th>
                                      <th className="text-center w-60">
                                        Módulo
                                      </th>
                                      <th className="text-center w-60">
                                        Pedra
                                      </th>
                                      <th className="text-center w-60">
                                        Descrição
                                      </th>
                                      <th className="text-center w-60">
                                        Formato
                                      </th>
                                      <th className="text-center w-60">
                                        Quantidade
                                      </th>
                                      <th className="text-center w-60">
                                        Adicionar
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderNailing
                                      ?.filter(o => !selectedItems.includes(o))
                                      .map((o, i) => {
                                        if (o.id_order == pedido.ID) {
                                          return (
                                            <tr>
                                              <td className="text-center">
                                                {o.order_code}
                                              </td>
                                              <td className="text-center">
                                                {o.module}
                                              </td>
                                              <td className="text-center">
                                                {o.stone}
                                              </td>
                                              <td className="text-center">
                                                {o.description}
                                              </td>
                                              <td className="text-center">
                                                {o.format}
                                              </td>
                                              <td className="text-center">
                                                {o.amount}
                                              </td>
                                              <td className="d-flex justify-content-center align-items-center">
                                                <Button
                                                  onClick={() =>
                                                    handleSelectItems(o)
                                                  }
                                                >
                                                  Adicionar
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })}
                                    {orderNailing?.filter(
                                      o => !selectedItems.includes(o)
                                    ).length == 0 ? (
                                      <tr colSpan={6}>
                                        <Alert>
                                          Todos os items já foram adicionados
                                        </Alert>
                                      </tr>
                                    ) : null}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          ))}
                        </div>
                      ) : orderNailing.length == 0 && pedidos?.length > 0 ? (
                        <div className="mt-3 ">
                          <Alert className="text-center">
                            O pedido adicionado não possui dados de cravação.
                          </Alert>
                        </div>
                      ) : null}
                    </div>
                  </Card>
                </Card.Body>
              </Card>
            ) : null}
            {pedidos.length > 0 ? (
              <div className="mt-3 w-100">
                <Card className="card-one h-100 w-100 ">
                  <form onSubmit={handleAddItem}>
                    <Card.Body className="h-100 d-flex flex-column gap-3">
                      <strong>Itens avulsos</strong>
                      <div className="d-flex flex-row gap-3">
                        <Card className="d-flex flex-row gap-3 w-100">
                          <Card.Body>
                            <Form.Group className="w-100">
                              <Form.Label className="text-center w-100">
                                <strong>Detalhes/Nome do item</strong>
                              </Form.Label>
                              <Form.Control
                                disabled={pedidos && pedidos.length === 0}
                                type="text"
                                placeholder="Ex: Corrente Ouro, Insumos de Produção"
                                className="display-3 h-20 z-3 w-100 text-center"
                                id="description"
                                name="description"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group className="w-100">
                              <Form.Label className="text-center w-100">
                                <strong>Quantidade</strong>
                              </Form.Label>
                              <Form.Control
                                disabled={pedidos && pedidos.length === 0}
                                className=""
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder="Em gramas ou em quantidade."
                                value={itemAmount}
                                onChange={e =>
                                  setItemAmount(apenasNumeros(e.target.value))
                                }
                              />
                            </Form.Group>

                            <Form.Group className="w-100">
                              <Form.Label className="text-center w-100">
                                <strong>Pedido</strong>
                              </Form.Label>
                              <Form.Select
                                disabled={pedidos && pedidos.length === 0}
                                className=""
                                name="id_order"
                                id="id_order"
                                value={idOrder}
                                onChange={e => setIdOrder(e.target.value)}
                              >
                                <option>--Selecione--</option>
                                {pedidos &&
                                  pedidos.map((pedido, index) => (
                                    <option key={index} value={pedido.ID}>
                                      {pedido.CODE}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </Card.Body>
                        </Card>
                      </div>
                    </Card.Body>
                    <Card.Footer className="d-flex gap-3">
                      <Button type="submit">Adicionar</Button>
                    </Card.Footer>
                  </form>
                </Card>
              </div>
            ) : null}
          </div>
          {selectedItems?.length > 0 ? (
            <Card className="mt-3 card-one">
              <Card.Body>
                <div className="border-bottom mb-3">
                  <Card.Title>Itens selecionados</Card.Title>
                </div>
                <Table className="" bordered>
                  <thead>
                    <tr>
                      <th className="text-center">Pedido</th>
                      <th className="text-center">Item</th>
                      <th className="text-center">Quantidade</th>
                      <th className="text-center">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItems?.map((item, index) => (
                      <tr>
                        <td className="text-center">{item.order_code}</td>
                        <td className="text-center">{item.description}</td>
                        <td className="text-center">{item.amount}</td>
                        <td className="text-center">
                          <Button onClick={() => handleRemoveItem(item)}>
                            Remover
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShow(false)}
            className="w-25"
            variant="secondary"
          >
            Cancelar
          </Button>
          <Button className="w-25" onClick={handleSave} variant="primary">
            Salvar solicitação
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSolicitacao;
