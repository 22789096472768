import React, { useState, useContext, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { userServices } from "../apiServices/userServices";
import { ToastContainer, toast } from "react-toastify";
import { apiCotacao } from "../apiServices/currencyServices";
import logo from "../assets/img/logo.webp";
import logoBranca from "../assets/img/logo-branca.png";
import { UserContext } from "../Context/UserContext";

export default function Signin() {
  const skin = localStorage.getItem("skin-mode");
  const [passwordField, setPasswordField] = useState("password");
  const handleSetPasswordField = () => {
    if (passwordField === "password") {
      setPasswordField("text");
    } else if (passwordField === "text") {
      setPasswordField("password");
    } else {
      return;
    }
  };
  const { userData, login } = useContext(UserContext);

  const handleDolar = () => {
    const response = apiCotacao("USD-BRL");
    ;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    toast.loading("Verificando Credenciais...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formData = new FormData(event.target);
    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    const response = await login(data);
    if (response.data.status === "success") {
      userServices
        .getUserData(response.data.data, null)
        .then((userDataResponse) => {
          toast.dismiss();
          toast.success("Credenciais válidas!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });

          localStorage.setItem("name", userDataResponse.data.result.NAME);
          setTimeout(() => window.location.reload(), 2000);
        });
      userServices.checkSession(response.data.data).then((response) => {
        localStorage.setItem("usd", response.data.value.usd);
        localStorage.setItem("au", response.data.value.au);
      });
    } else {
      toast.dismiss();
      toast.error("Credenciais inválidas!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

  };

  return (
    <div className="page-sign">
      <Card className="card-sign ">
        <Card.Header className="d-flex flex-column justify-content-center align-items-center">
          <Link to="/" className="mb-3">
            <img
              src={skin && skin === "dark" ? logoBranca : logo}
              width={200}
            ></img>
          </Link>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                id="email"
                placeholder="Digite seu e-mail"
              />
            </div>
            <div className="mb-4 d-flex flex-row gap-1">
              <Form.Group className="w-100">
                <Form.Label className="d-flex flex-column justify-content-between">
                  Senha <Link to="/reset">Esqueceu a senha?</Link>
                </Form.Label>
                <Form.Control
                  className="w-100"
                  type={passwordField}
                  name="password"
                  id="password"
                  placeholder="Digite sua senha"
                />
              </Form.Group>
              <div className="d-flex align-items-end">
                <Button
                  className="text-center d-flex justify-content-center align-items-center mx-auto"
                  onClick={handleSetPasswordField}
                  style={{
                    width: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className={`m-auto ${passwordField === "password"
                      ? "ri-eye-close-line"
                      : "ri-eye-line"
                      }`}
                  ></i>
                </Button>
              </div>
            </div>
            <Button type="submit" variant="primary">
              Entrar
            </Button>

            {/* <div className="divider"><span>or sign in with</span></div>

            <Row className="gx-2">
              <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
              <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
            </Row> */}
          </Form>
        </Card.Body>
        <Card.Footer>
          Não possui uma conta?{" "}
          <Link to="/create/account">Criar minha conta</Link>
        </Card.Footer>
      </Card>
      <ToastContainer />
    </div>
  );
}
