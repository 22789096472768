import { createContext, useContext, useState } from "react";
import { BatchServices } from "../../apiServices/Batch/Service";

const FoundryContext = createContext();

export const FoundryProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const [retailBatch, setRetailBatch] = useState([]);
  const [permissionOpenRetail, setPermissionOpenRetail] = useState(false);
  const handleGetMaterialBatch = async batch => {
    const { id } = batch;
    const payloadMaterialBatch = {
      batch: id,
      token,
    };
    const response = await BatchServices.getMaterialBatch(payloadMaterialBatch);
    setRetailBatch(response.data);

    const permission = response.data.some(item => item.total_weight == null);
    setPermissionOpenRetail(permission);
  };

  return (
    <FoundryContext.Provider
      value={{
        // attributes
        retailBatch,
        permissionOpenRetail,

        //methods
        handleGetMaterialBatch,
      }}
    >
      {children}
    </FoundryContext.Provider>
  );
};

export const useFoundry = () => {
  return useContext(FoundryContext);
};
