import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  return (
    <div className="main-footer">
      <span>&copy; {currentYear && currentYear}. Quantum Systems. All Rights Reserved.</span>
      <span>Designed by: <a href="https://www.linkedin.com/in/arturlimass/" target="_blank">Artur Lima</a></span>
    </div>
  )
}