// Loading.js
import React from "react";
import { Spinner } from "react-bootstrap";
import "./Load.css"; // Vamos usar CSS para estilizar o backdrop

const Loading = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <>
          <div className="loading-backdrop">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only"></span>
            </Spinner>
          </div>
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Loading;
