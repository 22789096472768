import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Table, Button, Alert, Card } from "react-bootstrap";
import { apiServices } from "../../apiServices/ApiServices";
import { Resumo } from "./Resumo";
import { ToastContainer, toast } from "react-toastify";
import { userServices } from "../../apiServices/userServices";
import { BalanceContext } from "../../Context/BalanceContext/BalanceContext";
export const EvolucaoPedidos = ({ permissionType, operationType, nextPage, movimentationType, operationCode, permissionUpdate, page, status }) => {
	const token = localStorage.getItem("token");
	const inputRef = useRef(null);
	const [pedidos, setPedidos] = useState([]);
	const [value, setValue] = useState("");
	const [codigoPedidos, setCodigoPedidos] = useState([]);

	const [usuarios, setUsuarios] = useState([]);
	const [lote, setLote] = useState([]);
	const [userOrder, setUserOrder] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);

	const [selectedOperationCode, setSelectedOperationCode] = useState(null);
	const handleSetOperationCode = e => {
		setSelectedOperationCode(e);
	};

	const [selectedOperationType, setSelectedOperationType] = useState(null);
	const handleSetOperationType = e => {
		setSelectedOperationType(e);
	};

	const [selectedMovimentationType, setMovimentationType] = useState(null);
	const handlesetMovimentationType = e => {
		setMovimentationType(e);
	};

	const [pesoTotal, setPesoTotal] = useState(0);
	const handleSetPesoTotal = e => {
		setPesoTotal(e);
	};

	const [detail, setDetail] = useState(null);
	const handleSetDetail = e => {
		setDetail(e);
	};

	const handleGetUsuarios = async () => {
		const request = {
			token: token,
			pageSearch: page,
			permission_type: permissionType,
			page: 1,
			limit: 9999,
		};
		const response = await userServices.getUsers(request);
		if (response) {
			setUsuarios(response.data.users);
		}
	};

	const handleAddUserToOrder = user => {
		setSelectedUser(user);
	};

	const handleGetData = data => {
		setLote(data);
	};

	const handleModalEntered = () => {
		inputRef.current && inputRef.current.focus();
	};

	const handleUpdate = async () => {
		handleUpdateStatus(codigoPedidos, token, status);
	};

	const handleUpdateStatus = async (orders, token, id_status) => {
		const data = {
			order: orders,
			token: token,
			status: 17,
			operation_code: selectedOperationCode,
			operation_type: 1,
			total_weight: pesoTotal,
			selectedUser: selectedUser,
			movimentation_type: selectedMovimentationType,
			detail: detail,
			id_page: nextPage[0].id,
			description: `Atualização em massa! lote: ${lote.lote}`,
			lote: {
				batch: lote.lote,
				order_weight: lote.peso,
			},
		};
		toast.dismiss();
		try {
			if (orders.length > 0) {
				const response = await apiServices.updateStatusOrder(data);
				if (response.data.status === "success") {
					toast.success(response.data.msg, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
					setTimeout(() => {
						setPedidos([]);
						setLote([]);
						setValue([]);
						setCodigoPedidos([]);
						window.location.reload();
					}, 3000);
				} else {
					toast.error(response.data.msg, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}
		} catch (error) {
			console.error("Erro ao atualizar o status:", error);
		}
	};

	let timerId = null;

	const handleGetOrder = async (token, order) => {
		let response;

		if (order.length === 10) {
			toast.loading("Processando...");
			response = await apiServices.getOrder(token, 1, 1, order, null, null, true);
		}

		if (response.data.orders.length !== 0) {
			const existe = pedidos.find(item => item.CODE === response.data.orders[0].CODE);

			const permite = page == 15 ? response.data.orders[0].ORDER_PAGE === page : response.data.orders[0].ID_STATUS === status && response.data.orders[0].ORDER_PAGE === page;

			if (!existe && permite) {
				setPedidos(prevPedidos => [...prevPedidos, response.data.orders[0]]);
				setCodigoPedidos(prevPedidos => [...prevPedidos, response.data.orders[0].ID]);
				toast.dismiss();
				toast.success("Pedido adicionado com sucesso!");
				setValue("");
				handleModalEntered();
			} else if (!permite) {
				toast.dismiss();
				toast.error("Este pedido não está na etapa autorizada ou não foi pesado.");
				setValue("");
				handleModalEntered();
			} else {
				toast.dismiss();
				toast.error("Este pedido já foi adicionado.");
				setValue("");
				handleModalEntered();
			}
		} else {
			toast.dismiss();
			toast.error("Nenhum pedido encontrato com este código. Para mais informações, entre em contato com o nosso setor de suporte!");
			setValue("");
			handleModalEntered();
		}
	};

	const removeOrder = orderCode => {
		const index = pedidos.findIndex(pedido => pedido.CODE === orderCode);

		if (index > -1) {
			const updatedPedidos = [...pedidos];
			updatedPedidos.splice(index, 1);
			const updatedCodigo = [...updatedPedidos.map(pedido => pedido.CODE)];
			setPedidos(updatedPedidos);
			setCodigoPedidos(updatedCodigo);
			toast.success("Pedido removido com sucesso!");
		}
	};

	const handleGetCode = e => {
		const valor = e.target.value;
		setValue(valor);

		if (timerId) {
			clearTimeout(timerId);
		}

		timerId = setTimeout(() => {
			handleGetOrder(token, valor);
		}, 1000);
	};

	const Metais = pedidos.map(dado => dado.MATERIAL_CODE);
	const MetalUnico = Array.from(new Set(Metais));
	const Metal = MetalUnico.map((item, index) => {
		if (MetalUnico.length > 1 && index !== MetalUnico.length - 1) {
			return item;
		} else {
			return item;
		}
	});

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current && inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (permissionUpdate === true) {
			handleUpdate();
		}
	}, [permissionUpdate]);

	useEffect(() => {
		handleGetUsuarios();
	}, []);

	return (
		<>
			<button
				className="w-100 bg-transparent border border-light"
				onClick={() => {
					if (page !== 13) {
						inputRef.current.focus();
					}
				}}
			>
				<div className="d-flex gap-3 flex-column align-items-center justify-content-center">
					<div className="d-flex w-100 flex-row gap-3 align-items-center justify-content-between">
						{status !== 5 && page !== 11 && page !== 15 ? (
							<Card className="w-100">
								<Card.Body>
									<Form.Group className="w-100">
										<Form.Label className="text-center w-100">Selecione o responsável</Form.Label>
										<Form.Select style={{ fontSize: "20px" }} onChange={e => handleAddUserToOrder(e.target.value, pedidos.ID)} required>
											<option className="text-start" value={null}>
												--Selecione--
											</option>
											{usuarios &&
												usuarios.map(u => (
													<option className="text-start" value={u.ID}>
														{u.NAME}
													</option>
												))}
										</Form.Select>
									</Form.Group>
								</Card.Body>
							</Card>
						) : null}

						{/* {operationType ? (
              <Card className="w-100">
                <Card.Body>
                  <Form.Group className="w-100">
                    <Form.Label className="text-center w-100">
                      Tipo da operação
                    </Form.Label>
                    <Form.Select
                      style={{ fontSize: "20px" }}
                      onChange={(e) =>
                        handleSetOperationType(e.target.value, pedidos.ID)
                      }
                      required
                    >
                      <option className="text-start" value={null}>
                        --Selecione--
                      </option>
                      {operationType &&
                        operationType.map((ot, i) => (
                          <option
                            value={ot.id_operation_type}
                            key={i}
                            className="text-start"
                          >
                            {ot.operation_name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>
            ) : null} */}

						{operationCode ? (
							<Card className="w-100">
								<Card.Body>
									<Form.Group className="w-100">
										<Form.Label className="text-center w-100">Código da operação</Form.Label>
										<Form.Select style={{ fontSize: "20px" }} onChange={e => handleSetOperationCode(e.target.value, pedidos.ID)} required>
											<option className="text-start" value={null}>
												--Selecione--
											</option>
											{operationCode &&
												operationCode.map((o, i) => (
													<option name="operation_code" id="operation_code" className="text-start m-3" value={o.id_operation_code}>
														{o.operation_code_name}
													</option>
												))}
										</Form.Select>
									</Form.Group>
								</Card.Body>
							</Card>
						) : null}

						{movimentationType ? (
							<Card className="w-100">
								<Card.Body>
									<Form.Group className="w-100">
										<Form.Label className="text-center w-100">Tipo da Movimentação</Form.Label>
										<Form.Select style={{ fontSize: "20px" }} onChange={e => handlesetMovimentationType(e.target.value, pedidos.ID)} required>
											<option className="text-start" value={null}>
												--Selecione--
											</option>
											{movimentationType &&
												movimentationType.map((m, i) => {
													if (m.id_movimentation_type !== 2) {
														return (
															<option value={m.id_movimentation_type} key={i} className="text-start">
																{m.movimentation_name}
															</option>
														);
													}
												})}
										</Form.Select>
									</Form.Group>
								</Card.Body>
							</Card>
						) : null}
					</div>

					<div className="w-100 h-100">
						<Card className="h-100">
							<Card.Body className="h-100">
								<Form.Label className="text-center w-100">Aguarde o leitor ou digite o código do pedido</Form.Label>
								<Form.Control
									style={{ fontSize: "20px", fontWeight: "bold" }}
									type="text"
									className="bg-white display-3 h-20 z-3 w-100 text-center"
									ref={inputRef}
									autoFocus={page !== 13}
									id="code-form"
									value={value}
									onChange={handleGetCode}
								/>
							</Card.Body>
						</Card>
					</div>
				</div>

				<div className="mt-3 w-100" style={{ overflowX: "auto" }}>
					<Table className="table-four table-bordered" style={{ tableLayout: "fixed" }}>
						<thead>
							<tr>
								<th className="text-center w-60">Cod.Pedido</th>
								<th className="text-center w-60">Cliente</th>
								<th className="text-center w-60">Cod.Peça</th>
								<th className="text-center w-60">Data</th>
								<th className="text-center w-50">Status</th>
								<th className="text-center w-30">Remover</th>
							</tr>
						</thead>
						<tbody>
							{pedidos.length > 0 ? (
								pedidos.map(pedidos => (
									<tr>
										<td className="text-center w-100">{pedidos.CODE}</td>
										<td className="text-center w-100">{pedidos.NAME_CLIENT}</td>
										<td className="text-center w-100">{pedidos.PART_CODE}</td>
										<td className="text-center w-100">{pedidos.DATE}</td>
										<td className="text-center w-100">{pedidos.STATUS}</td>
										<td className="text-center w-100">
											<Button variant="danger" onClick={e => removeOrder(pedidos.CODE)}>
												<i className="ri-delete-bin-6-line"></i>
											</Button>
										</td>
									</tr>
								))
							) : (
								<tr className="w-100 flex text-center">
									<td colSpan={9}>
										<Alert>Nenhum pedido adicionado</Alert>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					{page !== 13 ? null : (
						<Card className="mt-3 d-flex flex-row gap-3 w-100">
							<div className="h-100 w-100">
								<Card.Body className="h-100">
									<Form.Label className="text-center w-100">Peso total:</Form.Label>
									<Form.Control
										style={{ fontSize: "20px", fontWeight: "bold" }}
										type="text"
										onChange={e => handleSetPesoTotal(e.target.value)}
										value={pesoTotal}
										className="bg-white display-3 h-20 z-3 w-100 text-center"
										name="peso-total"
										id="peso-total"
									/>
								</Card.Body>
							</div>
							<Card className="h-100 w-100 card-one">
								<Card.Body className="h-100">
									<Form.Label>Detalhamento</Form.Label>
									<Form.Control onChange={e => handleSetDetail(e.target.value)} name="detail" id="detail" as="textarea"></Form.Control>
								</Card.Body>
							</Card>
						</Card>
					)}
				</div>
			</button>

			<ToastContainer />
		</>
	);
};
