function apenasNumeros(value) {
  if (value !== "" && value !== null && value !== undefined) {
    const valueCleared = value
      .toString()
      .replace(/[^0-9.,]/g, "")
      .replace(",", ".");
    const valor = Number(valueCleared);
    return isNaN(valor) ? 0 : valueCleared;
  }
  return "";
}

export function apenasNumerosInteiros(value) {
  if (value !== "" && value !== null && value !== undefined) {
    const valueCleared = value.toString().replace(/[^0-9]/g, "");
    const valor = Number(valueCleared);
    return isNaN(valor) ? 0 : valor;
  }
  return 0;
}

const numberFloating = value => {
  let valor = null;
  if (value != "" && value != null && value != undefined) {
    valor = value.toString().replace(/[^\d.,]/g, ""); // Remove tudo exceto dígitos, ponto (.) e vírgula (,)

    // Remover pontos e vírgulas adicionais, exceto o último
    const pontoIndex = valor.indexOf(".");
    const virgulaIndex = valor.indexOf(",");
    if (pontoIndex !== -1 && virgulaIndex !== -1) {
      if (pontoIndex > virgulaIndex) {
        valor = valor.replace(/\./g, "");
      } else {
        valor = valor.replace(/,/g, "");
      }
    }
  }
};

function cpfMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

function cnpjMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1/$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

function celularMask(value) {
  if ((value || false) && value.substr(0, 1) !== "+") {
    const onlyDigits = value.replace(/[^0-9]/g, "");
    let searchPrimeiroSegmento = /(\d{4})(\d)/;
    if (onlyDigits.length > 10) {
      searchPrimeiroSegmento = /(\d{5})(\d)/;
    }
    if (onlyDigits.length == 12) {
      let regexNumeroBrasilCom55 = /(\d{2})(\d{2})(\d{4})(\d{4})/;
      let resultado = regexNumeroBrasilCom55.test(onlyDigits);
      if (resultado) {
        return onlyDigits
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
      }
    }

    if (onlyDigits.length == 13) {
      let regexNumeroBrasilCom55 = /(\d[55])(\d{2})(\d{5})(\d{4})/;
      let resultado = regexNumeroBrasilCom55.test(onlyDigits);
      if (resultado) {
        return onlyDigits
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
      } else {
        return `+${onlyDigits}`;
      }
    }
    return (
      onlyDigits
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(searchPrimeiroSegmento, "$1-$2")
        //.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, "$1")
    );
  } else if ((value || false) && value.substr(0, 1) === "+") {
    const onlyDigits = value.replace(/[^0-9]/g, "");
    if (!verificarDDIBrasileiro(onlyDigits)) {
      return `+${onlyDigits}`;
    }
    let searchSegundoSegmento = /(\d{4})(\d)/;
    if (onlyDigits.length > 10) {
      searchSegundoSegmento = /(\d{5})(\d)/;
    }
    return onlyDigits
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})(\d)/, "($2) $3")
      .replace(searchSegundoSegmento, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  }

  return null;
}

function horaMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1:$2")
    .replace(/(:\d{2})\d+?$/, "$1");
}

function cepMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

function pisMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{5})(\d)/, "$1.$2")
    .replace(/(\d{5}\.)(\d{2})(\d)/, "$1$2-$3")
    .replace(/(-\d{1})\d+?$/, "$1");
}

function identidadeMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{1})\d+?$/, "$1");
}

function realMoeda(value) {
  if (value != "" && value != null && value != undefined) {
    let str = String(value);

    let retorno = str.replace(/\D/g, "").replace(/([0-9]{2})$/g, ",$1");

    if (retorno.length > 6)
      retorno = retorno.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return retorno;
  } else {
    return value;
  }
}

const formatarParaMascaraMoeda = function (value) {
  if (value == undefined) {
    value = "0";
  }
  return Number(value).toFixed(2).toString().replace(".", ",");
};

const limpaMascaraMoeda = function (value) {
  if (value == undefined) {
    value = "0";
  }
  // return value;
  return value.replace("R$ ", "").replaceAll(".", "");
};
const formatarMoeda = function (value) {
  return value
    .toLocaleString("pt-br", { minimumFractionDigits: 2 })
    .replace(".", ",");
};
const formatarParaMascaraMoedaComSeparadorDeMilhares = function (value) {
  if (value == undefined) {
    value = "0";
  }
  let valor = Number(value);
  if (Math.abs(valor) < 1000) {
    return formatarParaMascaraMoeda(value);
  }
  let strComDecimais = Number(value).toFixed(2).toString();
  let arrInteiroSeparadoDosDecimais = strComDecimais.split(".");
  arrInteiroSeparadoDosDecimais[0] = arrInteiroSeparadoDosDecimais[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(".")
    .split("")
    .reverse()
    .join("");
  return arrInteiroSeparadoDosDecimais.join(",");
};

const formatarDeMascaraMoeda = function (strValue) {
  if (strValue || false) {
    return Number(strValue.toString().replace(/\./g, "").replace(",", "."));
  }

  return null;
};

export const formatarMoedaReal = strValue => {
  const formatedNumber = new Intl.NumberFormat(
    "pt-BR",
    {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    },
    Number(strValue).toFixed(2).toString().replace(".", ",")
  );
  return formatedNumber.toString();
};


const formatarDeMascaraController = function (strValue) {
  if (strValue || false) {
    let nvValor = strValue.replace(/\D/g, "");
    nvValor = nvValor.replace(/([0-9]{2})$/g, ".$1");

    return Number(nvValor);
  }

  return null;
};

const formatarParaMascaraMoedaComSeparadorRomano = function (value) {
  let valorNumerico = Number(value || 0);
  let valorComVirgula =
    formatarParaMascaraMoedaComSeparadorDeMilhares(valorNumerico);
  let valorExplodido = valorComVirgula.split(".");
  if (valorNumerico == 0) {
    return valorComVirgula;
  }
  if (valorNumerico >= 1000000000) {
    valorExplodido.pop();
    valorExplodido.pop();
    valorExplodido.pop();

    return valorExplodido.join(".").toString() + "BI";
  }
  if (valorNumerico >= 1000000) {
    valorExplodido.pop();
    valorExplodido.pop();

    return valorExplodido.join(".").toString() + "MI";
  }
  if (Math.abs(valorNumerico) > 1000) {
    valorExplodido.pop();
    return valorExplodido.join(".").toString() + "K";
  }

  return valorComVirgula;
};

const validaCPF = function (strCPF) {
  if (strCPF) {
    strCPF = strCPF.replace(/\D/g, "");
    let soma = 0;
    let resto;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  } else {
    return false;
  }
};

const validaHorario = function (value) {
  return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value);
};

const verificarDDIBrasileiro = function (celular) {
  let telefone = String(celular);

  return telefone.substring(0, 3) == "+55" || telefone.substring(0, 2) == "55";
};

export {
  cpfMask,
  cnpjMask,
  horaMask,
  celularMask,
  cepMask,
  pisMask,
  identidadeMask,
  realMoeda,
  apenasNumeros,
  formatarDeMascaraMoeda,
  formatarMoeda,
  formatarParaMascaraMoeda,
  formatarParaMascaraMoedaComSeparadorRomano,
  formatarParaMascaraMoedaComSeparadorDeMilhares,
  validaCPF,
  validaHorario,
  limpaMascaraMoeda,
  formatarDeMascaraController,
  numberFloating,
};
