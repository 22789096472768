// const baseURL = "http://localhost:8000/controllers";
// const baseURL = process.env.NODE_ENV === 'development' ? "https://api-homologacao.qtsys.com.br/controllers" : "https://www.apicriartgold.qtsys.com.br/controllers";
const baseURL = "https://www.apicriartgold.qtsys.com.br/controllers";
// const baseURL = "https://qapicriartgold.qtsys.com.br/controllers";
export const AppConstants = {
    baseURL
}

// export const baseURLPDF = 'https://qapicriartgold.qtsys.com.br/getfile?type=temp_pdf&name=';
export const baseURLPDF = 'https://www.apicriartgold.qtsys.com.br/getfile?type=temp_pdf&name='

// TODO:
// Criar função de peso final de polimento
// Criar função de pesos adicionais em pedidos
