import api from "../api";

const TreeServices = {
  addRealWeight: (data) => {
    const payload = {
      action: "add_real_weight",
      tree: data.tree,
      batch: data.batch,
      material_color: data.material_color,
      material_color_code: data.material_color_code,
      weight: data.weight,
      field: data.field,
      token: data.token,
    };
    return api.post("/treeController.php", payload);
  },
};

export default TreeServices;
