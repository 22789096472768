import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../Context/OrderContext";
import {
  Alert,
  AlertLink,
  Button,
  Card,
  Dropdown,
  DropdownItem,
  Form,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { formatDate } from "@fullcalendar/core";
import { apiServices } from "../../apiServices/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { Search } from "lucide-react";
import { Dialog } from "../DialogConfirmacao";
import { AccountContext } from "../../Context/AccountContext/AccountContext";

const token = localStorage.getItem("token");

const ListaPedidosCravadores = ({
  selectedUser,
  page,
  handleSendOrderList,
  status,
  operationCode,
  movimentationType,
  setStatusToFetch,
}) => {
  const { getExtract } = useContext(AccountContext);

  const { listOrders, resetListOrders, orderList, orderAddFinalWeight } =
    useContext(OrderContext);

  const [tooltipOpen, setTooltipOpen] = useState({});

  const [selectedOrders, setSelectedOrders] = useState([]);
  useEffect(() => {
    console.log(selectedOrders);
  }, [selectedOrders]);

  const handleCheckboxChange = (orderId) => {
    // Verifica se o pedido já está selecionado
    const isSelected = selectedOrders.includes(orderId);

    if (isSelected) {
      // Remove o pedido dos selecionados
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      // Adiciona o pedido aos selecionados
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const toggleTooltip = (orderId) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  const [dialogShow, setDialogShow] = useState(false);
  const [dadosEvento, setDadosEvento] = useState([]);

  const handleOpenDialog = (e) => {
    e.preventDefault();
    setDadosEvento(e);
    setDialogShow(true);
  };

  const closeDialog = function () {
    setDialogShow(false);
  };

  const handleChildData = (confirmacao) => {
    if (confirmacao === false) {
      toast.dismiss();
      toast.error("Matrícula incorreta do usuário atribuido ao pedido", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    } else {
      handleSubmitModalAddPeso();
    }
  };

  const [modalAddPesoData, setModalAddPesoData] = useState([]);
  const [showModalAddPeso, setShowModalAddPeso] = useState(false);

  const handleSubmitModalAddPeso = async () => {
    const formData = new FormData(dadosEvento.target);
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      id_orders: [modalAddPesoData.ID],
      order_code: modalAddPesoData.CODE,
      movimentation_insert: true,
      movimentation_type: formData.get("movimentation_type"),
      operation_code: 12,
      operation_type: "2",
      selectedUser: selectedUser,
      key: "acabamento_final_weight",
      value: formData.get("acabamento-peso-final")?.replace(",", "."),
      id_page: page,
      token: token,
    };

    const response = await orderAddFinalWeight(data);

    if ((response.data.status = "success")) {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      closeModalAddPeso();
      closeDialog();
      setStatusToFetch(true);
      fetchall();
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const closeModalAddPeso = () => {
    setShowModalAddPeso(false);
  };

  const handleOpenModalAddPeso = (e) => {
    setShowModalAddPeso(true);
    setModalAddPesoData(e);
  };

  const fetchall = () => {
    const payload = {
      page: 1,
      selectedUser: selectedUser,
      evolution_search: true,
      order_page: page,
      limit: 100,
      search: "",
    };
    orderList(payload);
    // handleSendOrderList(listOrders);
  };

  useEffect(() => {
    fetchall();
  }, [selectedUser]);

  const [modalDetail, setModalDetail] = useState([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const handleOpenModalDetail = (e) => {
    setShowModalDetail(true);
    handleGetModalDetail(e);
    setModalDetail(e);
  };

  const handleCloseModalDetail = () => {
    setShowModalDetail(false);
    setModalDetail([]);
  };

  const handleGetExtract = async () => {
    const payload = {
      id: selectedUser,
    };

    const response = await getExtract(payload);
    window.open(response.data.link, "_blank");
  };

  const handleGetModalDetail = (e) => {};

  return (
    <div>
      <ToastContainer />
      <div className="d-flex flex-row  mb-3 justify-content-between flex-wrap gap-3">
        <div className="border form-search ">
          <Form
            className="d-flex w-100"
            onSubmit={(e) => console.log(e.target.value)}
          >
            <Form.Control
              className="w-100"
              placeholder="Pesquisar"
              onChange={(e) => console.log(e.target.value)}
              id="pesquisar"
              name="pesquisar"
            ></Form.Control>
            <Button type="" variant="outline">
              <Search />
            </Button>
          </Form>
        </div>{" "}
        <Button onClick={handleGetExtract}>Gerar Extrato</Button>
      </div>
      {listOrders?.length > 0 ? (
        <Table className="table-four table-bordered">
          <thead>
            <tr>
              <th className="text-center">Imagem</th>
              <th className="text-center">Cod.Pedido</th>
              <th className="text-center">Cliente</th>
              <th className="text-center">Cod.Peça</th>
              <th className="text-center">Peso final</th>
              <th className="text-center">Tipo de Pedido</th>
              <th className="text-center">Usuário Atribuído</th>
              <th className="text-center">Saída</th>
            </tr>
          </thead>
          <tbody className="">
            {listOrders
              ?.filter((o) => o.ACABAMENTO_FINAL_WEIGHT === null)
              ?.map((order) => (
                <tr>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleOpenModalDetail(order.CODE)}
                    className="text-center  d-flex justify-content-center"
                  >
                    <div className="product-thumb">
                      <img src={order.FILE}></img>
                    </div>
                  </td>

                  <td className="text-center">{order.CODE}</td>
                  <td
                    className="text-center"
                    style={{
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                      maxWidth: "150px",
                      zIndex: "-999",
                    }}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${order.ID}`}>
                          {order.NAME_CLIENT}
                        </Tooltip>
                      }
                    >
                      <span
                        id={order.ID}
                        onMouseEnter={() => toggleTooltip(order.ID)}
                        onMouseLeave={() => toggleTooltip(order.ID)}
                        className="text-truncate"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {order.NAME_CLIENT}
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td className="text-center">{order.PART_CODE}</td>
                  <td className="text-center">
                    {order.ACABAMENTO_FINAL_WEIGHT &&
                    order.ACABAMENTO_FINAL_WEIGHT > 0 &&
                    order.ACABAMENTO_FINAL_WEIGHT !== null &&
                    order.ACABAMENTO_FINAL_WEIGHT !== undefined ? (
                      <div className="">
                        <span className="">
                          {order.ACABAMENTO_FINAL_WEIGHT} (g)
                        </span>
                      </div>
                    ) : (
                      <div className="">
                        <span>Peso não adicionado</span>
                      </div>
                    )}
                  </td>
                  <td className="text-center">
                    {order.TYPE_ORDER_DESCRIPTION}
                  </td>
                  <td className="text-center">{order.USERNAME}</td>
                  <td className="text-center">
                    <Button onClick={() => handleOpenModalAddPeso(order)}>
                      Saída
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <Alert>Nenhum pedido atribuído ao ourives selecionando</Alert>
      )}

      <Modal size="xl" show={showModalAddPeso} onHide={closeModalAddPeso}>
        <Modal.Header closeButton>
          <Modal.Title>Saída deaasdasda pedidos</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleOpenDialog}>
          <Modal.Body className="d-flex flex-row gap-3">
            <div className="w-100">
              <Card className="card-one w-100">
                <Card.Body className=" w-100 d-flex flex-column justify-content-center">
                  <Card.Title>
                    <h5>
                      <strong>Pedido:</strong> {modalAddPesoData.CODE}
                    </h5>
                  </Card.Title>

                  <img width={"100%"} src={modalAddPesoData.FILE}></img>
                </Card.Body>
              </Card>
            </div>

            <div className="gap-3 d-flex flex-column w-100 h-100">
              <div className="d-flex flex-row gap-3">
                <Card className="card-one w-100">
                  <Card.Header className="d-flex flex-column justify-content-start align-items-start  gap-2">
                    <Card.Title className="d-flex flex-row align-items-center justify-content-center gap-2">
                      <strong>Tipo de movimentação</strong>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            <h6 className="text-start">
                              O tipo de movimentação pode interferir no calculo
                              da quebra do ourives. Tenha a certeza de que está
                              selecionando a opção correta.
                            </h6>
                          </Tooltip>
                        }
                      >
                        <i class="ri-information-line"></i>
                      </OverlayTrigger>
                    </Card.Title>
                    <Form.Select
                      required
                      name="movimentation_type"
                      id="movimentation_type"
                    >
                      <option value={null}>--Selecione--</option>
                      {movimentationType &&
                        movimentationType.map((m, i) => (
                          <option key={i} value={m.id_movimentation_type}>
                            {m.movimentation_name}
                          </option>
                        ))}
                    </Form.Select>
                  </Card.Header>
                </Card>
              </div>

              <Card className="card-one">
                <Card.Header>
                  <Card.Title>Adicionar Peso</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    type="text"
                    required
                    name={"acabamento-peso-final"}
                    id={"acabamento-peso-final"}
                    step=".03"
                    className="w-100"
                    placeholder="Digite o peso final da peça! "
                  ></Form.Control>
                </Card.Body>
              </Card>

              {/* <Card className="card-one">
                <Card.Header>
                  <Card.Title>Detalhamento</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as={"textarea"}
                    name={"detail"}
                    id={"detail"}
                    className="w-100"
                    placeholder="Digite o detalhamento da Operação! "
                  ></Form.Control>
                </Card.Body>
              </Card> */}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit">Salvar</Button>
            <Button
              variant="secondary"
              onClick={() => {
                closeModalAddPeso();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal size="xl" show={showModalDetail} onHide={handleCloseModalDetail}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do pedido</Modal.Title>
        </Modal.Header>

        <Modal.Body>Detalhes</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalDetail}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        showDialog={dialogShow}
        closeDialog={closeDialog}
        user={selectedUser}
        sendDataToParent={handleChildData}
        title={"Iniciar fundição"}
        question={"Deseja realmente começar o acabamento dos pedidos?"}
      />
    </div>
  );
};

export default ListaPedidosCravadores;
