import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import Header from "../layouts/Header";
import { apiServices } from "../apiServices/ApiServices";
import { useContext, useEffect, useState } from "react";
import { Mold } from "../functions/Mold";
import { MoldServices } from "../apiServices/MoldServices";
import { ToastContainer, toast } from "react-toastify";
import { motion } from "framer-motion";
import { animations, AnimateOnChange } from "react-animation";
import {
  ArrowBigRight,
  ArrowDownRightFromCircleIcon,
  ArrowRightIcon,
  Search,
} from "lucide-react";
import { OrderContext, UpdateOrderPre } from "../Context/OrderContext";
import { Arvore } from "./Arvore";
import { GetTreeData } from "../Context/TreeFunctions";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { TreeContext } from "../Context/TreeContext";
import { BatchServices } from "../apiServices/Batch/Service";
import { BatchRetail } from "./Lotes/Retail";
import { ScreenContext } from "../Context/Screen/context";
import { htmlDialog, swalErro, swalSucesso } from "./Swal";
import { useFoundry } from "../Context/FoundryContext/FoundryContext";

const Lotes = ({ page, nextPage, sendDataToParent }) => {
  const { screenWidth } = useContext(ScreenContext);
  const token = localStorage.getItem("token");
  const [retail, setRetail] = useState([]);
  const { getDefectiveOrder } = useContext(OrderContext);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [mold, setMold] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [lote, setLote] = useState([]);
  const [defectiveOrders, setDefectiveOrders] = useState([]);
  const [totalMaterialAddToOrder, setTotalMaterialAddToOrder] = useState([]); //retail, totalMaterialAddToOrder, totalMaterialToTree
  const { handleGetMaterialBatch, retailBatch, permissionOpenRetail } =
    useFoundry();

  const [totalMaterialToTree, setTotalMaterialTree] = useState([]);

  const [modalTree, setModalTree] = useState([]);

  const filterDataToMaterial = async () => {
    setTotalMaterialAddToOrder([]);
    const result =
      modalData &&
      modalData.reduce((acc, item) => {
        // Check if the item's MATERIAL_COLOR_CODE exists in the accumulator
        if (item.MATERIAL_COLOR_CODE in acc) {
          // Add the ORDER_WEIGHT to the existing entry
          acc[item.MATERIAL_COLOR_CODE].ORDER_FINAL_WEIGHT +=
            item.ORDER_FINAL_WEIGHT || 0;
        } else {
          // Create a new entry for this MATERIAL_COLOR_CODE
          acc[item.MATERIAL_COLOR_CODE] = {
            MATERIAL_COLOR_CODE: item.MATERIAL_COLOR_CODE,
            MATERIAL_NAME: item.MATERIAL_NAME,
            ORDER_FINAL_WEIGHT: item.ORDER_FINAL_WEIGHT
              ? item.ORDER_FINAL_WEIGHT
              : 0 || 0,
          };
        }
        return acc;
      }, {});

    // Convert the result object back into an array
    setTotalMaterialAddToOrder(Object.values(result));
  };
  const [totalMaterialDefective, setTotalMaterialDefective] = useState([]);
  const filterDataToMaterialDefective = async () => {
    setTotalMaterialDefective([]);
    const result = defectiveOrders?.reduce((acc, item) => {
      // Check if the item's MATERIAL_COLOR_CODE exists in the accumulator
      if (item.MATERIAL_COLOR_CODE in acc) {
        // Add the ORDER_WEIGHT to the existing entry
        acc[item.MATERIAL_COLOR_CODE].ORDER_FINAL_WEIGHT +=
          item.ORDER_FINAL_WEIGHT || 0;
      } else {
        // Create a new entry for this MATERIAL_COLOR_CODE
        acc[item.MATERIAL_COLOR_CODE] = {
          MATERIAL_COLOR_CODE: item.MATERIAL_COLOR_CODE,
          MATERIAL_NAME: item.MATERIAL_NAME,
          ORDER_FINAL_WEIGHT: item.ORDER_FINAL_WEIGHT
            ? item.ORDER_FINAL_WEIGHT
            : 0 || 0,
        };
      }
      return acc;
    }, {});
    setTotalMaterialDefective(Object.values(result));
  };

  const filterDataToMaterialTree = async () => {
    setTotalMaterialTree([]);
    const result =
      modalTree &&
      modalTree.reduce((acc, item) => {
        const injectedWeight = item.INJECTED_WEIGHT || 0;
        const realWeight = item.REAL_WEIGHT || 0;
        const retailWeight = item.RETAIL_WEIGHT || 0;

        // Adjust material color code based on its length
        let materialColorCode = item.MATERIAL_COLOR_CODE?.replace("VD", "");

        if (materialColorCode in acc) {
          acc[materialColorCode].INJECTED_WEIGHT += injectedWeight;
          acc[materialColorCode].REAL_WEIGHT += realWeight;
          acc[materialColorCode].RETAIL_WEIGHT += retailWeight;
        } else {
          acc[materialColorCode] = {
            MATERIAL_COLOR_CODE: materialColorCode,
            MATERIAL_CODE: item.MATERIAL_CODE,
            MATERIAL_NAME: item.MATERIAL_NAME,
            INJECTED_WEIGHT: injectedWeight,
            REAL_WEIGHT: realWeight,
            RETAIL_WEIGHT: retailWeight,
          };
        }

        return acc;
      }, {});
    const response = result ? Object.values(result) : null;
    setTotalMaterialTree(response);
  };

  const [accumulatedTree, setAccumulatedTree] = useState([]);
  const accumulateMaterialTree = () => {
    const summary =
      totalMaterialToTree &&
      totalMaterialToTree.reduce((acc, item) => {
        const {
          MATERIAL_COLOR_CODE,
          MATERIAL_NAME,
          INJECTED_WEIGHT,
          REAL_WEIGHT,
          RETAIL_WEIGHT,
        } = item;
        if (MATERIAL_NAME in acc) {
          acc[MATERIAL_NAME].totalInjectedWeight += INJECTED_WEIGHT;
          acc[MATERIAL_NAME].totalRealWeight += REAL_WEIGHT;
          acc[MATERIAL_NAME].totalRetailWeight += RETAIL_WEIGHT;
        } else {
          acc[MATERIAL_NAME] = {
            MATERIAL_COLOR_CODE,
            MATERIAL_NAME,
            totalInjectedWeight: INJECTED_WEIGHT,
            totalRealWeight: REAL_WEIGHT,
            totalRetailWeight: RETAIL_WEIGHT,
          };
        }
        return acc;
      }, {});
    const response = summary ? Object.values(summary) : null;
    setAccumulatedTree(response);
  };

  const [accumulatedRetail, setAccumulatedRetail] = useState([]);
  const accumulateRetail = () => {
    const response =
      retail &&
      retail.reduce((acc, item) => {
        // Define the group key based on the MATERIAL_COLOR_CODE
        const groupKey = ["AM", "RS", "OB"].includes(item.MATERIAL_COLOR_CODE)
          ? "Ouro 18K"
          : "Prata 950";

        // Initialize the group if it doesn't exist
        if (!acc[groupKey]) {
          acc[groupKey] = { items: [], totalWeight: 0 };
        }

        // Push the current item to the group
        acc[groupKey].items.push(item);

        // Accumulate the total weight for the group
        acc[groupKey].totalWeight += item.TOTAL_WEIGHT;

        return acc;
      }, {});

    // Convert the accumulated object into an array format as per your example
    const result = Object.keys(response).map(key => ({
      group: key,
      totalWeight: response[key].totalWeight,
      items: response[key].items,
    }));

    setAccumulatedRetail(result);
  };

  useEffect(() => {
    filterDataToMaterialDefective();
  }, [defectiveOrders]);

  useEffect(() => {
    filterDataToMaterialTree();
  }, [modalTree]);

  useEffect(() => {
    filterDataToMaterial();
  }, [modalData]);

  useEffect(() => {
    accumulateMaterialTree();
  }, [totalMaterialToTree]);

  useEffect(() => {
    accumulateRetail();
  }, [retail]);

  const [quebra, setQuebra] = useState([]);

  useEffect(() => {
    // Calculate the total weight for gold and silver in orders
    let totalPerdaOuro = modalData.filter(
      item => item.MATERIAL_NAME === "Ouro 18K"
    );
    const totalGoldOrderWeight =
      totalMaterialAddToOrder &&
      totalMaterialAddToOrder
        .filter(item => item.MATERIAL_NAME === "Ouro 18K")
        .reduce((acc, item) => acc + item.ORDER_FINAL_WEIGHT, 0) +
        totalPerdaOuro.length * 0.01;

    let totalPerdaPrata = modalData.filter(
      item => item.MATERIAL_NAME === "Prata 950"
    );

    const totalSilverOrderWeight =
      totalMaterialAddToOrder &&
      totalMaterialAddToOrder
        .filter(item => item.MATERIAL_NAME === "Prata 950")
        .reduce((acc, item) => acc + item.ORDER_FINAL_WEIGHT, 0) +
        totalPerdaPrata.length * 0.01;

    let totalPerdaDefeitoOuro = totalMaterialDefective.filter(
      item => item.MATERIAL_NAME === "Ouro 18K"
    );

    const totalGoldDeffectiveOrder =
      totalMaterialDefective
        ?.filter(item => item.MATERIAL_NAME === "Ouro 18K")
        .reduce((acc, item) => acc + item.ORDER_FINAL_WEIGHT, 0) +
      totalPerdaDefeitoOuro.length * 0.01;

    let totalPerdaDefeitoPrata = totalMaterialDefective.filter(
      item => item.MATERIAL_NAME === "Ouro 18K"
    );
    const totalSilverDefectiveOrder =
      totalMaterialDefective
        ?.filter(item => item.MATERIAL_NAME === "Prata 950")
        .reduce((acc, item) => acc + item.ORDER_FINAL_WEIGHT, 0) +
      totalPerdaDefeitoPrata.length * 0.01;

    // Calculate the total weight for gold and silver in trees
    const totalGoldTreeWeight =
      totalMaterialToTree &&
      totalMaterialToTree
        .filter(item => item.MATERIAL_NAME === "Ouro 18K")
        .reduce(
          (acc, item) => acc + (item.INJECTED_WEIGHT + item.REAL_WEIGHT),
          0
        );
    const totalSilverTreeWeight =
      totalMaterialToTree &&
      totalMaterialToTree
        .filter(item => item.MATERIAL_NAME === "Prata 950")
        .reduce(
          (acc, item) => acc + (item.INJECTED_WEIGHT + item.REAL_WEIGHT),
          0
        );

    // Calculate the total weight for gold and silver in retail
    const totalGoldRetailWeight =
      accumulatedRetail &&
      accumulatedRetail
        .filter(item => item.group === "Ouro 18K")
        .reduce((acc, item) => acc + item.totalWeight, 0);
    const totalSilverRetailWeight =
      accumulatedRetail &&
      accumulatedRetail
        .filter(item => item.group === "Prata 950")
        .reduce((acc, item) => acc + item.totalWeight, 0);

    // Calculate the final quantities
    const qSilver =
      totalSilverTreeWeight -
      totalSilverOrderWeight -
      totalSilverRetailWeight -
      totalSilverDefectiveOrder;
    const qGold =
      totalGoldTreeWeight -
      totalGoldOrderWeight -
      totalGoldRetailWeight -
      totalGoldDeffectiveOrder;

    setQuebra([
      { label: "Ouro 18k", field: "quebra_ouro", value: qGold },
      { label: "Prata 950", field: "quebra_prata", value: qSilver },
    ]);
  }, [accumulatedRetail, totalMaterialAddToOrder, totalMaterialToTree]);

  const handleGetDefectiveOrder = async () => {
    const payload = {
      batch: lote.id,
    };
    const response = await getDefectiveOrder(payload);
    setDefectiveOrders(response.data.result);
  };

  useEffect(() => {
    handleGetDefectiveOrder();
  }, [lote]);

  const [selectedLote, setSelectedLote] = useState([]);
  const [selectedMold, setSelectedMold] = useState([]);
  const [pesoEstimado, setPesoEstimado] = useState(0);
  const [showModalAddPeso, setShowModalAddPeso] = useState(false);
  const [modalAddPesoData, setModalAddPesoData] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [showRetalho, setShowRetalho] = useState(false);
  const [pedidosAdicionados, setPedidosAdicionados] = useState([]);

  const [modalArvorePedidos, setModalArvorePedidos] = useState([]);

  const [materialChecked, setMaterialChecked] = useState([
    {
      metal_code: null,
      metal_color_code: ["VD"],
    },
  ]);

  const handleAddMaterialChecked = value => {
    if (value.metal_color_code === "" && pedidosAdicionados.length > 0) {
      toast.error("Selecione o material", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }

    if (
      pedidosAdicionados &&
      pedidosAdicionados.some(
        i => i.ORDER_MATERIAL_COLOR_CODE !== value.material_color_code
      )
    ) {
      toast.error("Existem pedidos adicionados com material diferente deste.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    } else {
      if (
        materialChecked &&
        Array.isArray(materialChecked) &&
        materialChecked.some(
          item =>
            item.metal_color_code &&
            item.metal_color_code[1] === value.metal_color_code &&
            item.metal_code === value.metal_code
        )
      ) {
        setMaterialChecked([{ metal_code: null, metal_color_code: ["VD"] }]);
      } else {
        setMaterialChecked(() => [
          {
            metal_code: value.metal_code,
            metal_color_code: ["VD", value.metal_color_code],
          },
        ]);
      }
    }
  };

  const arvoreData = GetTreeData(
    pedidosAdicionados,
    lote,
    materialChecked[0].metal_code,
    materialChecked[0].metal_color_code[1]
      ? materialChecked[0].metal_color_code[1]
      : null
  );

  const [searchValue, setSearchValue] = useState("");
  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  const [filteredData, setFilteredData] = useState(
    modalArvorePedidos
      ? [...modalArvorePedidos].sort((a, b) => a.ORDER_CODE - b.ORDER_CODE)
      : []
  );

  const handleResetFilteredData = () => {
    setFilteredData(
      modalArvorePedidos
        ? [...modalArvorePedidos].sort((a, b) => a.ORDER_CODE - b.ORDER_CODE)
        : []
    );
  };

  const alterarFiltro = () => {
    let filteredData = modalArvorePedidos;

    if (
      searchValue !== "" &&
      searchValue !== null &&
      searchValue !== undefined
    ) {
      filteredData = filteredData.filter(
        item => item && item.ORDER_CODE.includes(searchValue)
      );
    }

    if (materialChecked && materialChecked[0].metal_color_code?.length > 1) {
      filteredData = filteredData.filter(
        item =>
          item &&
          materialChecked?.some(
            material =>
              material.metal_color_code?.includes(
                item.ORDER_MATERIAL_COLOR_CODE || "VD"
              ) && material.metal_code === item.MATERIAL_CODE
          )
      );
    }

    let sortedFilteredData = filteredData.sort(
      (a, b) => a.ORDER_CODE - b.ORDER_CODE
    );
    return sortedFilteredData;
  };

  useEffect(() => {
    const filtered = alterarFiltro();
    setFilteredData(filtered);
  }, [materialChecked, searchValue, modalArvorePedidos]);

  const [showModalArvore, setShowModalArvore] = useState(false);

  const [camposRetalhos, setCamposRetalhos] = useState([]);

  const [existeVariasCorres, setExisteVariasCores] = useState(false);
  const [tree, setTree] = useState([]);

  const arrayCamposRetalhos = [
    {
      id: "AU",
      name: "AU - Ouro Amarelo",
      code: "ouro_amarelo",
    },
    {
      id: "AU",
      name: "AU - Ouro Branco",
      code: "ouro_branco",
    },
    {
      id: "AU",
      name: "AU - Ouro Rose",
      code: "ouro_rose",
    },
    {
      id: "AG",
      name: "AG - Prata",
      code: "prata",
    },
  ];

  useEffect(() => {
    if (!modalTree || modalTree.length === 0) {
      // Se modalTree for vazio, não faça nada
      return;
    }
    const encontrarItensCorrespondentes = (modalTree, arrayCamposRetalhos) => {
      const itensCorrespondentesSet = new Set();

      modalTree.forEach(item => {
        const materialCode = item.MATERIAL_CODE;
        const itemCorrespondente = arrayCamposRetalhos.find(
          camposItem => camposItem.code === materialCode
        );

        if (itemCorrespondente) {
          itensCorrespondentesSet.add(itemCorrespondente);
        }
      });

      return Array.from(itensCorrespondentesSet);
    };

    // Encontra todos os itens correspondentes com base nos códigos de material em modalTree
    const itensCorrespondentes = encontrarItensCorrespondentes(
      modalTree,
      arrayCamposRetalhos
    );
    setCamposRetalhos(itensCorrespondentes);

    const existe =
      modalTree && modalTree.some(item => item.MATERIAL_CODE === "varias");
    setExisteVariasCores(existe);
  }, [modalTree]);

  const [modalAttachmentShow, setModalAttachmentShow] = useState(false);
  const [modalAttachment, setModalAttachment] = useState(null);

  const handleOpenModalAttachment = data => {
    setModalAttachment(data);
    setModalAttachmentShow(true);
  };

  const handleCloseModalAttachment = () => {
    setModalAttachment([]);
    setModalAttachmentShow(false);
  };

  const [tubo, setTubo] = useState(null);
  const [dNone, setDnone] = useState("");
  const [peso, setPeso] = useState(0);

  const [modalDefeito, setShowModalDefeito] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [treeInitialWeight, setTreeInitialWeight] = useState(0);

  const [pesoRetalho, setPesoRetalho] = useState([]);

  const hideModal = () => {
    setDnone("d-none");
  };

  const removeHide = () => {
    setDnone("");
  };

  const handleOpenModalArvore = data => {
    hideModal();
    const modalArvoreDataNotNull = data.filter(data => data.TREE === null);
    setModalArvorePedidos(modalArvoreDataNotNull);
    setShowModalArvore(true);
    getMold();
  };

  const closeModalArvore = () => {
    removeHide();
    setShowModalArvore(false);
    setMaterialChecked([{ metal_code: null, metal_color_code: ["VD"] }]);
    setPedidosAdicionados([]);
    setTreeInitialWeight(0);
    setPeso(0);
    handleResetFilteredData();
  };

  const handleAddPedido = data => {
    const isAlreadyAdded =
      pedidosAdicionados &&
      pedidosAdicionados.some(pedido => pedido.ORDER_CODE === data.ORDER_CODE);

    // Verifica se o metal do novo pedido é igual ao metal de pelo menos um dos pedidos já adicionados
    const hasSameMetal =
      pedidosAdicionados &&
      pedidosAdicionados.some(
        pedido => pedido.MATERIAL_CODE === data.MATERIAL_CODE
      );

    // Se o pedido já estiver na lista ou se o metal for diferente, exibe um erro e não adiciona o pedido
    if (pedidosAdicionados && pedidosAdicionados.length > 0 && isAlreadyAdded) {
      toast.error("O pedido que tentou adicionar já está adicionado!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (
      pedidosAdicionados &&
      pedidosAdicionados.length > 0 &&
      !hasSameMetal
    ) {
      toast.error(
        "O pedido que tentou adicionar possui metal diferente dos pedidos já adicionados!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else {
      // Caso contrário, adiciona o novo pedido à lista de pedidos adicionados
      setPedidosAdicionados([...pedidosAdicionados, data]);
      setModalArvorePedidos(prevState => {
        return prevState.filter(
          pedido => pedido.ORDER_CODE !== data.ORDER_CODE
        );
      });
      setSearchValue("");
    }
  };

  const handleRemovePedido = pedido => {
    setModalArvorePedidos([...modalArvorePedidos, pedido]);
    setPedidosAdicionados(prevState => {
      return prevState.filter(item => item.ORDER_CODE !== pedido.ORDER_CODE);
    });
    setTreeInitialWeight(0);
  };

  const handleSaveArvore = async () => {
    if (arvoreData.metal_color === null) {
      toast.error("Selecione um metal!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const pedidos = pedidosAdicionados.map(pedido => pedido.ORDER);
    if (tubo > 0 && pedidos.length > 0) {
      const data = {
        order: pedidos,
        arvore: arvoreData,
        peso_molde: peso,
        molde: selectedMold.id,
        id_page: page,
        quantidadeNecessaria: treeInitialWeight,
        tubo: tubo,
        token: token,
      };

      const response = await apiServices.updateStatusOrder(data);

      if (response.data.status === "success") {
        toast.dismiss();
        toast.success("Pedidos adicionados e árvore salva com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        closeModalArvore();
        handleGetIndexTree(lote.id);
        handleGetOrderBatch(lote);
        setTreeInitialWeight(0);
      } else {
        toast.dismiss();
        toast.error(
          "Erro ao salvar pedido, entre em contato com o nosso suporte!",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
      }
    } else {
      toast.dismiss();
      toast.error(
        "O tubo não foi informado ou os pedidos não foram inseridos!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    }
  };

  const handleGetResponseBatch = d => {
    if (d) {
      handleGetOrderBatch(lote);
      handleGetIndexTree(lote.id);
      handleGetMaterialReceived(lote.id);
      handleCloseModalRetail();
    }
  };

  const [timeLineBatch, setTimeLineBatch] = useState([]);
  const handleGetTimeLineBach = () => {
    //AQUI VAI FAZER A REQUISIÇÃO
  };

  const [modalRetaiLData, setModalRetailData] = useState([]);
  const [showModalRetail, setShowModalRetail] = useState(false);

  const handleGetRetail = async () => {
    const payload = {
      batch: lote.id,
      token: token,
    };
    const response = await BatchServices.listMaterialOrder(payload);
    setModalRetailData(response.data.result);
  };

  const handleOpenModalRetail = e => {
    handleGetRetail();
    setShowModalRetail(true);
  };

  const handleCloseModalRetail = () => {
    setModalRetailData([]);
    setShowModalRetail(false);
  };

  const handleOpenModalAddPeso = e => {
    setShowModalAddPeso(true);
    localStorage.setItem("modalRef", e.ORDER);
    setModalAddPesoData(e);

    hideModal();
  };

  const closeModalAddPeso = () => {
    removeHide();
    setShowModalAddPeso(false);
  };

  const handleSetPeso = e => {
    const campo = e.target;
    let valor = campo.value.replace(/[^\d.,]/g, ""); // Remove tudo exceto dígitos, ponto (.) e vírgula (,)

    // Remover pontos e vírgulas adicionais, exceto o último
    const pontoIndex = valor.indexOf(".");
    const virgulaIndex = valor.indexOf(",");
    if (pontoIndex !== -1 && virgulaIndex !== -1) {
      if (pontoIndex > virgulaIndex) {
        valor = valor.replace(/\./g, "");
      } else {
        valor = valor.replace(/,/g, "");
      }
    }

    // Substituir a vírgula por ponto para formatar o número corretamente
    valor = valor.replace(",", ".");

    setPeso(valor);
    campo.value = valor;
  };

  const handleUpdateStatus = async () => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      modalData: modalData,
      page: page,
      token: token,
    };
    const response = await UpdateOrderPre(data);
    if (response.data.status === "success") {
      setShowModal(false);
      setModalData([]);
      handleGetBatch();
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      //window.location.reload();
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleGetBatch = async () => {
    const data = {
      token: token,
      id_page: page,
    };

    const response = await apiServices.getBatch(data);
    if (response.data.status === "success") {
      setLotes(response.data.batch);
    }
  };

  // const handleGetTree = async () => {
  //   const data = {
  //     token: token,
  //   };

  //   const response = await apiServices.getTree(data);
  //   if (response) {
  //     setTree(response.data.resultado);
  //   }
  // };

  const handleGetIndexTree = async d => {
    setModalTree([]);
    const data = {
      token: token,
      batch: d,
    };

    const response = await apiServices.getIndextree(data);
    if (response) {
      setModalTree(response.data.resultado);
    }
  };

  const handleUpdateMold = value => {
    var selectedMoldId = parseInt(value);
    var selectedMoldObject;

    if (selectedMoldId === 0) {
      selectedMoldObject = {
        multiplication_factor: 1,
      };
    } else {
      selectedMoldObject = mold.find(m => m.id === selectedMoldId);
    }

    setSelectedMold(selectedMoldObject);
  };

  const getMold = async () => {
    const data = {
      token: localStorage.getItem("token"),
    };
    const response = await MoldServices.getMold(data);

    if (response) {
      setMold(response.data);
    }
  };

  const handleCloseModal = async () => {
    setShowModal(false);
    setRetail([]);
  };

  const { addRealWeight } = useContext(TreeContext);

  const handleAddRealWeight = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      tree: formData.get("tree"),
      real_weight: formData.get("real-weight"),
    };
    const response = await addRealWeight(payload);
    handleGetIndexTree(lote.id);
  };

  const handleGetOrderBatch = async d => {
    const data = {
      batch: d.id,
      token: token,
    };
    const response = await apiServices.getOrderBatch(data);
    if (response) {
      setModalData(response.data.result);
      setRetail(response.data.retail);
      setSelectedLote(d);
    }
  };

  const handleOpenModal = async data => {
    if (data.batch) {
      const batch = data.batch;
      setLote(batch);
      handleGetMaterialBatch(batch);
      handleGetIndexTree(batch.id);
      handleGetMaterialReceived(batch.id);
      setShowModal(true);
      try {
        handleGetOrderBatch(batch);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSubmitModalAddPeso = async e => {
    e.preventDefault();
    // Obtém o peso final do formulário
    const formData = new FormData(e.target);
    const pesoFinal = parseFloat(formData.get("peso-final").replace(",", ".")); // Garante que o formato do número seja correto
    toast.dismiss();
    const materialCorrespondente = materialReceived.find(
      material =>
        material.material_color_code == modalAddPesoData.MATERIAL_COLOR_CODE
    );

    // if (materialCorrespondente.weight < pesoFinal) {
    //   toast.info("Peso insuficiente!", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    //   return;
    // }

    const payload = {
      peso_final: formData.get("peso-final"),
      order: [modalAddPesoData.ORDER],
      operator: "-",
      id_page: page,
      token: token,
    };

    const htmlContent = `
      <div>
        <h2><strong>Peso: </strong>${payload.peso_final} (g)</h2>
      </div>
    `;

    const confirm = await htmlDialog(
      `Você confirma este peso no pedido ${modalAddPesoData.ORDER_CODE}?`,
      htmlContent
    );

    if (!confirm.isConfirmed) {
      return;
    }

    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const response = await apiServices.updateStatusOrder(payload);

    if ((response.data.status = "success")) {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleOpenModal({ batch: lote });
      handleGetOrderBatch(lote);
      handleGetIndexTree(lote.id);
      filterDataToMaterialTree();
      filterDataToMaterial();

      // handleGetMaterialReceived(lote);
      closeModalAddPeso();

      setTimeout(() => {
        const refId = localStorage.getItem("modalRef");
        const refElement = document.getElementById(`row_id_ref_${refId}`);
        if (refElement) {
          refElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleGoToRef = () => {
    setTimeout(() => {
      const refId = localStorage.getItem("modalRef");
      const refElement = document.getElementById(`row_id_ref_${refId}`);
      if (refElement) {
        refElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  const [modalFinalizacao, setModalFinalizaPedido] = useState(false);
  const [modalFinalizacaoData, setModalFinalizacaoData] = useState([]);
  const handleFinalizaFundicao = e => {
    setModalFinalizaPedido(true);
    setModalFinalizacaoData(e);
  };

  const handleCloseModalFinalizacao = () => {
    setModalFinalizaPedido(false);
    setModalFinalizacaoData([]);
  };

  const handleSubmitFinalizacao = async e => {
    e.preventDefault();
    const payload = {
      order: [modalFinalizacaoData.ORDER],
      token: token,
      status: 11,
      id_type_order: 3,
    };

    const response = await apiServices.updateStatusOrder(payload);
    if (response.data.status == "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleCloseModalFinalizacao();
      handleGetOrderBatch(lote);
    }
  };

  const handleModalDefeito = order => {
    localStorage.setItem("modalRef", order.ORDER);
    setShowModalDefeito(true);
    setSelectedOrder(order);

    hideModal();
  };

  const handleCloseModalDefeito = () => {
    setShowModalDefeito(false);
    removeHide();
  };

  const handleSubmitModalDefeito = async e => {
    e.preventDefault();
    toast.loading("Salvando", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      description: e.target.description.value,
      order: [selectedOrder.ORDER],
      status: 22,
      page: 11,
      token: token,
    };
    const response = await apiServices.updateStatusOrder(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success("Defeito informado com sucesso!!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleOpenModal({ batch: lote });
      handleGetOrderBatch(lote);
      handleGetDefectiveOrder();
      handleCloseModalDefeito();
      setTimeout(() => {
        const refId = localStorage.getItem("modalRef");
        const refElement = document.getElementById(`row_id_ref_${refId}`);
        if (refElement) {
          refElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    } else {
      toast.dismiss();
      toast.error(
        "Erro ao salvar pedido, entre em contato com o nosso suporte!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    }
  };

  const handleChangeRetail = e => {
    e.preventDefault();
    const { id, name, value } = e.target;

    setPesoRetalho(prevValores => {
      const novoArray = [...prevValores];
      const index = novoArray.findIndex(
        item => item.metal === id && item.color === name
      );

      if (index !== -1) {
        // Se o objeto existe, edita diretamente
        novoArray[index] = {
          ...novoArray[index],
          weight: value,
        };
      } else {
        // Se não existe, adiciona um novo objeto
        novoArray.push({
          metal: id,
          color: name,
          weight: value,
        });
      }
      return novoArray;
    });
  };

  const handleSubmitRetail = async e => {
    toast.loading("Salvando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();
    const data = {
      token: token,
      batch: selectedLote.id,
      retalho: pesoRetalho,
    };
    toast.dismiss();
    const response = await apiServices.saveRetail(data);
    if (response.data.status === "success") {
      handleGetIndexTree(lote.id);
      handleGetOrderBatch(lote);
      swalSucesso(response.data.msg);
    } else if (response.data.status === "info") {
      handleGetIndexTree(lote.id);
      handleGetOrderBatch(lote);
      toast.info(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      swalErro(response.data.msg);
    }
  };

  useEffect(() => {
    handleGetBatch();
    // handleGetTree();
  }, []);

  useEffect(() => {
    // Sua lógica para calcular o valor inicial da árvore
    let value = 0;

    if (materialChecked[0].metal_code !== "AG") {
      value = selectedMold.multiplication_factor * peso;
    } else {
      value = 10 * peso;
    }
    setTreeInitialWeight(value);
  }, [selectedMold, peso, materialChecked]);

  const [permitido, setPermitido] = useState(false);

  useEffect(() => {
    const permitido =
      modalData.every(pedido => pedido.ORDER_FINAL_WEIGHT !== null) &&
      modalData.length > 0 &&
      retail.length > 0;

    const permitidoRetalho =
      modalData.every(pedido => pedido.ORDER_FINAL_WEIGHT !== null) &&
      modalData.length > 0;
    setShowButton(permitido);
    setShowRetalho(permitidoRetalho);
    setPermitido(permitido);
  }, [modalData]);

  const [modalADdQuantidadeOpen, setModalAddQuantidadeOpen] = useState(false);
  const [modalAddQuantidade, setModalAddQuantidade] = useState([]);
  const handleOpenModalQuantidade = e => {
    setModalAddQuantidadeOpen(true);
    setModalAddQuantidade(e);
  };

  const handleCloseModalQuantidade = () => {
    setModalAddQuantidadeOpen(false);
    setModalAddQuantidade([]);
  };

  const [arrayMaterial, setArrayMaterial] = useState([
    {
      id: "AU",
      name: "AU - Ouro Amarelo",
      color_code: "AM",
      code: "ouro_amarelo",
      weight: 0.0,
    },
    {
      id: "AU",
      name: "AU - Ouro Branco",
      color_code: "OB",
      code: "ouro_branco",
      weight: 0.0,
    },
    {
      id: "AU",
      name: "AU - Ouro Rose",
      color_code: "RS",
      code: "ouro_rose",
      weight: 0.0,
    },
    {
      id: "AG",
      name: "AG - Prata",
      color_code: "AG",
      code: "prata",
      weight: 0.0,
    },
  ]);

  const handleAddMAterialBatch = async e => {
    e.preventDefault();
    const payload = {
      batch: modalAddQuantidade.id,
      token: token,
      materials: arrayMaterial,
    };

    const response = await BatchServices.addMaterialBatch(payload);
  };

  const handleSetMaterial = e => {
    const code = e.target.dataset.code;
    const newWeight = parseFloat(e.target.value);

    setArrayMaterial(currentMaterials =>
      currentMaterials.map(material =>
        material.code === code ? { ...material, weight: newWeight } : material
      )
    );
  };

  const [materialReceived, setMaterialReceived] = useState([]);
  const handleGetMaterialReceived = async batch => {
    const payload = {
      batch: batch,
      token: token,
    };
    setMaterialReceived([]);
    const response = await BatchServices.getMaterialReceived(payload);
    setMaterialReceived(response.data.result);
  };

  const [tabDetalhes, setTabDetalhes] = useState(0);
  const handleSetTabDetalhes = tab => {
    setTabDetalhes(tab);
  };

  const [modalAddPesoArvoreData, setModalAddPesoArvoreData] = useState([]);
  const [modalAddPesoArvore, setModalAddPesoArvore] = useState(false);

  const handleOpenModalAddPesoArvore = (tree, field) => {
    localStorage.setItem("tree_ref", `tree_${tree.ID}`);
    const updatedTree = {
      ...tree,
      ["field"]: field,
    };
    setModalAddPesoArvoreData(updatedTree);
    setModalAddPesoArvore(true);
  };

  const handleCloseModalAddPesoArvore = () => {
    setModalAddPesoArvore(false);
    setModalAddPesoArvoreData(false);
    localStorage.removeItem("tree_ref");
  };

  const handleSubmitAddPesoArvore = async e => {
    e.preventDefault();
    const code =
      modalAddPesoArvoreData.MATERIAL_COLOR &&
      modalAddPesoArvoreData.MATERIAL_COLOR.slice(0, 2);
    const materialReceivedItem = materialReceived.find(
      item => item.material_color_code === code
    );

    const formData = new FormData(e.target);
    const payload = {
      tree: modalAddPesoArvoreData.ID,
      field: modalAddPesoArvoreData.field,
      batch: modalAddPesoArvoreData.BATCH_ID,
      weight: formData.get(modalAddPesoArvoreData.field)?.replace(",", "."),
      material_color: modalAddPesoArvoreData.MATERIAL_COLOR,
      material_color_code: modalAddPesoArvoreData.MATERIAL_COLOR_CODE,
    };
    // if (
    //   payload.field === "retail_weight" &&
    //   materialReceivedItem.weight < payload.weight
    // ) {
    //   toast.error("O peso informado é maior que o peso disponível", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    //   return;
    // }

    const response = await addRealWeight(payload);

    if (response.data.status === "success") {
      swalSucesso(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleCloseModalAddPesoArvore();
      handleGetIndexTree(lote.id);
      filterDataToMaterialTree();
      filterDataToMaterial();

      setTimeout(() => {
        const refId = localStorage.getItem("tree_ref");
        const refElement = document.getElementById(`${refId}`);

        if (refElement) {
          refElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
      handleGetMaterialReceived(lote.id);
    } else {
      swalErro(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      setTimeout(() => {
        const refId = localStorage.getItem("tree_ref");
        const refElement = document.getElementById(`tree_${refId}`);
        if (refElement) {
          refElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="h-100">
        <Card className="card-one h-100">
          <Card.Header>
            <h3> Lotes</h3>
          </Card.Header>
          <Card.Body className="h-100">
            <div className="h-100">
              <Table responsive className="table-four table-bordered h-100">
                <thead className="w-100 text-center">
                  <tr>
                    <th className="text-center">Lote</th>
                    <th className="text-center">Criado Em </th>
                  </tr>
                </thead>
                <tbody className="w-100 text-center">
                  {lotes.map((lote, index) => (
                    <tr key={index}>
                      <td
                        className="text-center text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenModal({ batch: lote })}
                      >
                        <u>{lote.batch}</u>
                      </td>
                      <td className="text-center">{lote.created_at}</td>
                      {/* <td className="text-center">
                          <td className="text-center">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleOpenModalQuantidade(lote)}
                            >
                              {lote.has_material === "true"
                                ? "Material adicionado"
                                : "Adicionar"}
                            </button>
                          </td>
                        </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </div>

      <Modal
        fullscreen
        className={dNone}
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <h4>Sublotes</h4>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column h-100 gap-3">
          <Card className="card shadow-sm">
            <Card.Body
              className={`w-100 d-flex gap-2 justify-content-between ${
                screenWidth < 900 ? "flex-wrap" : null
              }`}
            >
              <div
                className={`d-flex flex-row gap-2 ${
                  screenWidth < 900 ? "w-100" : null
                }`}
              >
                <Button
                  className={screenWidth < 900 ? "w-100" : null}
                  onClick={() => handleOpenModalArvore(modalData)}
                >
                  Adicionar Sublotes
                </Button>
                <Button
                  className={screenWidth < 900 ? "w-100" : null}
                  disabled={!permissionOpenRetail}
                  onClick={handleOpenModalRetail}
                >
                  Adicionar Retalho
                </Button>
              </div>

              <div className={screenWidth < 900 ? "w-100" : null}>
                {showButton ? (
                  <Button
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => handleUpdateStatus()}
                  >
                    Enviar para {nextPage[0].page_description}{" "}
                    <ArrowRightIcon />
                  </Button>
                ) : (
                  <Button
                    className={screenWidth < 900 ? "w-100" : null}
                    disabled
                  >
                    Preenchimento de dados pendentes
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>

          <Card className="mt-3 shadow">
            <Card.Header className="pt-3">
              <h5>Árvores de pedidos</h5>
            </Card.Header>
            <Card.Body className="d-flex h-100 flex-column gap-3">
              {modalTree && Array.isArray(modalTree) && modalTree.length > 0 ? (
                modalTree.map((t, i) => (
                  <Card
                    key={i}
                    id={`tree_${t.ID}`}
                    className="card-one relative h-auto"
                  >
                    <Card.Header className="pt-3 d-flex flex-row justify-content-between">
                      <Card.Title className="d-flex flex-column">
                        <span>
                          <strong>Tubo:</strong> {t.TREE_TUBE}
                        </span>
                        <span>
                          <strong>Cor do metal:</strong>{" "}
                          {t.MATERIAL_COLOR_CODE?.replace("VD", "")}
                        </span>
                      </Card.Title>
                      <Dropdown>
                        <Dropdown.Toggle as={Button} variant="outline-primary">
                          Injetar Material
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                            disabled={
                              t.REAL_WEIGHT > 0 ||
                              t.REAL_WEIGHT != null ||
                              t.REAL_WEIGHT != undefined
                            }
                            onClick={() =>
                              handleOpenModalAddPesoArvore(t, "real_weight")
                            }
                          >
                            Peso real
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() =>
                              handleOpenModalAddPesoArvore(t, "injected_weight")
                            }
                          >
                            Adicionar Material
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() =>
                              handleOpenModalAddPesoArvore(t, "retail_weight")
                            }
                          >
                            Reaproveitamento de Retalho
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Header>
                    <Card.Body className="p-1 h-100 overflow-hidden">
                      <div
                        id="form-batch-update"
                        // onSubmit={handleUpdateBatch}
                      >
                        <div
                          className={`w-100 gap-3 d-flex flex-row ${
                            screenWidth < 900 ? "flex-wrap" : null
                          }`}
                        >
                          <Card className="w-100">
                            <Card.Body>
                              <div>
                                <span name="peso-estimado" id="peso-estimado">
                                  <strong>Peso dos Pedidos:</strong>{" "}
                                  {t.ORDER_WEIGHT &&
                                    t.ORDER_WEIGHT.toFixed(3).replace(
                                      ".",
                                      ","
                                    )}{" "}
                                  (g)
                                </span>
                              </div>

                              <div>
                                <span name="peso-estimado" id="peso-estimado">
                                  <strong>
                                    Quantidade necessária de material para a
                                    árvore:
                                  </strong>{" "}
                                  {t.INITIAL_WEIGHT && t.INITIAL_WEIGHT
                                    ? t.INITIAL_WEIGHT.toFixed(3).replace(
                                        ".",
                                        ","
                                      )
                                    : 0}{" "}
                                  (g)
                                </span>
                              </div>
                              <div>
                                <span>
                                  <strong>Lote:</strong> {t.BATCH}
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card
                            className="p-0 d-flex w-100 h-100"
                            style={{ height: "100%" }}
                          >
                            <Card.Body
                              className="d-flex flex-column align-items-start h-100"
                              style={{ height: "100%" }}
                            >
                              <div className="">
                                <span>
                                  <strong>Peso Real da Arvore: </strong>
                                  {t.REAL_WEIGHT ||
                                  t.RETAIL_WEIGHT ||
                                  t.INJECTED_WEIGHT
                                    ? (
                                        t.REAL_WEIGHT +
                                        t.RETAIL_WEIGHT +
                                        t.INJECTED_WEIGHT
                                      ).toFixed(3) + " (g)"
                                    : "0 (g)"}
                                </span>
                              </div>

                              <div className="">
                                <span>
                                  <strong>Peso adicionado de Retalhos: </strong>{" "}
                                  {t.RETAIL_WEIGHT
                                    ? t.RETAIL_WEIGHT.toFixed(3).replace(
                                        ".",
                                        ","
                                      ) + " (g)"
                                    : "0 (g)"}{" "}
                                </span>
                              </div>
                              <div className="">
                                <span>
                                  <strong>Peso adicional injetado: </strong>{" "}
                                  {t.INJECTED_WEIGHT
                                    ? t.INJECTED_WEIGHT.toFixed(3).replace(
                                        ".",
                                        ","
                                      ) + " (g)"
                                    : "0 (g)"}
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                          {/* {t.REAL_WEIGHT > 0 ? null : (
                            <div className="border py-2 px-1 mt-2 w-100 gap-2 align-items-center">
                              <form
                                id="formTreeRealWeight"
                                name="formTreeRealWeight"
                                onSubmit={handleAddRealWeight}
                              >
                                <Form.Group className="d-flex flex-row gap-2 align-items-center">
                                  <strong style={{ width: "100px" }}>
                                    Peso real:{" "}
                                  </strong>
                                  <Form.Control
                                    type="number"
                                    name="real-weight"
                                    id="real-weight"
                                    step={".01"}
                                    placeholder="Digite o peso real da árvore de pedidos"
                                  ></Form.Control>
                                  <Form.Control
                                    type="hidden"
                                    value={t.ID}
                                    name="tree"
                                    id="tree"
                                  ></Form.Control>
                                  <Button
                                    form="formTreeRealWeight"
                                    type="submit"
                                  >
                                    Salvar
                                  </Button>
                                </Form.Group>
                              </form>
                            </div>
                          )} */}
                        </div>
                      </div>

                      <Table className="mt-3 table-four table-bordered">
                        <thead>
                          <tr>
                            <th className="text-center">Imagem</th>
                            <th className="text-center">Código do Pedido</th>

                            <th className="text-center">Peso do pedido</th>
                            <th className="text-center">
                              Peso final do pedido
                            </th>
                            <th className="text-center">Cod.Peça</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData
                            .filter(d => d.TREE === t.ID)
                            .map(data => (
                              <tr
                                key={data.ORDER}
                                id={`row_id_ref_${data.ORDER}`}
                              >
                                <td
                                  className="text-center align-middle d-flex justify-content-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    handleOpenModalAttachment(data.FILE)
                                  }
                                >
                                  <div className="product-thumb">
                                    <img width={150} src={data.FILE} alt="" />
                                  </div>
                                </td>
                                <td className="text-center align-middle">
                                  {data.ORDER_CODE}
                                </td>

                                <td className="text-center align-middle">
                                  {data.ORDER_WEIGHT?.toFixed(3)}
                                </td>
                                <td className="text-center align-middle">
                                  {data.ORDER_FINAL_WEIGHT?.toFixed(3)}
                                </td>
                                <td className="text-center align-middle">
                                  {data.PRODUCT_NAME}
                                </td>
                                <td
                                  className={
                                    data.ORDER_ID_STATUS === 22
                                      ? "text-center text-danger align-middle"
                                      : "text-center align-middle"
                                  }
                                >
                                  {data.ORDER_STATUS_NAME}
                                </td>
                                <td className="text-center align-middle">
                                  <DropdownButton
                                    drop={"up"}
                                    className=""
                                    title="Opções"
                                  >
                                    {data.ORDER_FINAL_WEIGHT > 0 &&
                                    data.ORDER_FINAL_WEIGHT !== null ? (
                                      <Dropdown.Item
                                        className="p-3 mb-2 bg-primary text-white text-center"
                                        disabled
                                      >
                                        Peso adicionado
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() =>
                                          handleOpenModalAddPeso(data)
                                        }
                                      >
                                        Adicionar peso
                                      </Dropdown.Item>
                                    )}

                                    {data.ID_TYPE_ORDER === 3 ? (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() =>
                                          handleFinalizaFundicao(data)
                                        }
                                      >
                                        Finalizar pedido de fundição
                                      </Dropdown.Item>
                                    ) : null}

                                    {data.ORDER_FINAL_WEIGHT > 0 &&
                                    data.ORDER_ID_STATUS !== 22 ? (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() => handleModalDefeito(data)}
                                      >
                                        Não conforme
                                      </Dropdown.Item>
                                    ) : data.ORDER_ID_STATUS === 22 ? (
                                      <Dropdown.Item
                                        disabled
                                        className="p-3 text-center"
                                      >
                                        Pedido não conforme!
                                      </Dropdown.Item>
                                    ) : (
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>
                                            Informe o peso da peça antes de
                                            acusar não conformidade
                                          </Tooltip>
                                        }
                                      >
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="p-3 text-center text-secondary"
                                        >
                                          Informar defeito
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                  </DropdownButton>
                                </td>
                              </tr>
                            ))}
                          {defectiveOrders
                            ?.filter(d => d.TREE === t.ID)
                            .map(data => (
                              <tr
                                key={data.ORDER}
                                id={`row_id_ref_${data.ORDER}`}
                              >
                                <td
                                  className="text-center align-middle d-flex justify-content-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={e =>
                                    handleOpenModalAttachment(data.FILE)
                                  }
                                >
                                  <div className="product-thumb">
                                    <img width={150} src={data.FILE} alt="" />
                                  </div>
                                </td>
                                <td className="text-center align-middle">
                                  {data.ORDER_CODE}
                                </td>

                                <td className="text-center align-middle">
                                  {data.ORDER_WEIGHT?.toFixed(3)}
                                </td>
                                <td className="text-center align-middle">
                                  {data.ORDER_FINAL_WEIGHT?.toFixed(3)}
                                </td>
                                <td className="text-center align-middle">
                                  {data.PRODUCT_NAME}
                                </td>
                                <td
                                  className={
                                    "text-center text-danger align-middle"
                                  }
                                >
                                  Peça com defeito
                                </td>
                                <td className="text-center align-middle">
                                  <DropdownButton
                                    drop={"up"}
                                    className=""
                                    title="Opções"
                                  >
                                    {data.ORDER_FINAL_WEIGHT > 0 &&
                                    data.ORDER_FINAL_WEIGHT !== null ? (
                                      <Dropdown.Item
                                        className="p-3 mb-2 bg-primary text-white text-center"
                                        disabled
                                      >
                                        Peso adicionado
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() =>
                                          handleOpenModalAddPeso(data)
                                        }
                                      >
                                        Adicionar peso
                                      </Dropdown.Item>
                                    )}

                                    {data.ID_TYPE_ORDER === 3 ? (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() =>
                                          handleFinalizaFundicao(data)
                                        }
                                      >
                                        Finalizar pedido de fundição
                                      </Dropdown.Item>
                                    ) : null}

                                    {data.ORDER_FINAL_WEIGHT > 0 &&
                                    data.ORDER_ID_STATUS !== 22 ? (
                                      <Dropdown.Item
                                        className="p-3 text-center"
                                        onClick={() => handleModalDefeito(data)}
                                      >
                                        Não conforme
                                      </Dropdown.Item>
                                    ) : data.ORDER_ID_STATUS === 22 ? (
                                      <Dropdown.Item
                                        disabled
                                        className="p-3 text-center"
                                      >
                                        Pedido não conforme!
                                      </Dropdown.Item>
                                    ) : (
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>
                                            Informe o peso da peça antes de
                                            acusar não conformidade
                                          </Tooltip>
                                        }
                                      >
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="p-3 text-center text-secondary"
                                        >
                                          Informar defeito
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                  </DropdownButton>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <Alert className="mt-3" variant="warning">
                  Nenhuma árvore adicionada
                </Alert>
              )}
            </Card.Body>
          </Card>

          {/* {defectiveOrders?.length > 0 ? (
            <Card className="h-100 card-one">
              <Card.Body>
                <Card.Title>Pedidos com defeito</Card.Title>
                <Table className="mt-3 table-four table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Imagem</th>
                      <th className="text-center">Código do Pedido</th>
                      <th className="text-center">Tubo</th>
                      <th className="text-center">Cor do Material</th>
                      <th className="text-center">Peso final do pedido</th>
                      <th className="text-center">Cod.Peça</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defectiveOrders
                      .sort((a, b) =>
                        a.MATERIAL_COLOR_CODE.localeCompare(
                          b.MATERIAL_COLOR_CODE
                        )
                      )
                      .map((data) => (
                        <tr key={data.ORDER} id={`row_id_ref_${data.ORDER}`}>
                          <td
                            className="text-center align-middle d-flex justify-content-center"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              handleOpenModalAttachment(data.FILE)
                            }
                          >
                            <div className="product-thumb">
                              <img width={150} src={data.FILE} alt="" />
                            </div>
                          </td>
                          <td className="text-center align-middle">
                            {data.ORDER_CODE}
                          </td>

                          <td className="text-center align-middle">
                            {data.TUBE}
                          </td>

                          <td className="text-start align-middle">
                            {data.MATERIAL_COLOR_CODE &&
                            data.MATERIAL_COLOR_CODE === "AM"
                              ? data.MATERIAL_COLOR_CODE + " - Ouro Amarelo 18k"
                              : data.MATERIAL_COLOR_CODE === "OB"
                              ? data.MATERIAL_COLOR_CODE + " - Ouro Branco 18k"
                              : data.MATERIAL_COLOR_CODE === "RS"
                              ? data.MATERIAL_COLOR_CODE + " - Ouro Rosa 18k"
                              : data.MATERIAL_COLOR_CODE === "AG"
                              ? data.MATERIAL_COLOR_CODE + " - Prata 950"
                              : null}
                          </td>
                          <td className="text-center align-middle">
                            {data.ORDER_FINAL_WEIGHT}
                          </td>
                          <td className="text-center align-middle">
                            {data.PRODUCT_NAME}
                          </td>
                          <td
                            className={"text-center text-danger align-middle"}
                          >
                            Peça com defeito
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : null} */}

          <Card className={`shadow ${permitido ? "" : "d-none"}`}>
            <div className="px-3 mt-3">
              <Card.Title>Detalhamento</Card.Title>
            </div>
            <Card.Body className="d-flex flex-column gap-3">
              {/* totalMaterialDefective */}
              <Card>
                <Card.Body>
                  <Card.Title>Peso final dos pedidos</Card.Title>
                  <div className="d-flex flex-row gap-2">
                    {totalMaterialAddToOrder &&
                      totalMaterialAddToOrder.map((m, i) => (
                        <Card className="w-100">
                          <Card.Body>
                            <div className="d-flex flex-row">
                              <span>
                                <strong>{m.MATERIAL_COLOR_CODE}</strong>:{" "}
                                {m.ORDER_FINAL_WEIGHT?.toFixed(3)} (g)
                              </span>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                  </div>
                </Card.Body>
              </Card>

              {defectiveOrders?.length > 0 ? (
                <Card>
                  <Card.Body>
                    <Card.Title>Peso final dos pedidos com defeito</Card.Title>
                    <div className="d-flex flex-row gap-2">
                      {totalMaterialDefective &&
                        totalMaterialDefective.map((m, i) => (
                          <Card key={i} className="w-100">
                            <Card.Body>
                              <div className="d-flex flex-row">
                                <span>
                                  <strong>{m.MATERIAL_COLOR_CODE}</strong>:{" "}
                                  {m.ORDER_FINAL_WEIGHT} (g)
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  </Card.Body>
                </Card>
              ) : null}
              {/* {materialReceived && materialReceived.length > 0
                ? materialReceived.map((m, i) => (
                    <AnimateOnChange className="w-100">
                      <Card key={i} className="w-100">
                        <Card.Body className="">
                          <div className="d-flex flex-column flex-wrap">
                            <div className="flex-grow-1">
                              <strong>{m.material_color}: </strong>{" "}
                              {m.weight > 0 ? m.weight : 0} (g)
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </AnimateOnChange>
                  ))
                : null} */}

              <Card className="w-100">
                <Card.Body>
                  <Card.Title>Retalhos adicionados</Card.Title>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    {retail &&
                      retail.map((r, i) => (
                        <Card key={i} className="w-100">
                          <Card.Body className="">
                            <div className="d-flex flex-column flex-wrap">
                              <div className="flex-grow-1">
                                <strong>{r.MATERIAL_COLOR_CODE}: </strong>{" "}
                                {r.TOTAL_WEIGHT?.toFixed(3)} (g)
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                  </div>
                </Card.Body>
              </Card>

              <Card className="w-100">
                <Card.Body className="d-flex flex-column gap-3">
                  <div>
                    <Card.Title>Peso adicionados em árvores</Card.Title>
                    <div className="d-flex flex-row gap-2 justify-content-between">
                      {totalMaterialToTree &&
                        totalMaterialToTree.map((t, i) => (
                          <Card key={i} className="w-100">
                            <Card.Body className="">
                              <div className="d-flex flex-column flex-wrap">
                                <div className="flex-grow-1">
                                  <strong>{t.MATERIAL_COLOR_CODE}: </strong>{" "}
                                  {(t.INJECTED_WEIGHT + t.REAL_WEIGHT).toFixed(
                                    3
                                  )}{" "}
                                  (g)
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  </div>
                  <div>
                    <Card.Title>Total</Card.Title>
                    <div className="d-flex flex-row gap-2 justify-content-between">
                      {accumulatedTree &&
                        accumulatedTree.map((t, i) => (
                          <Card key={i} className="w-100">
                            <Card.Body className="">
                              <div className="d-flex flex-column flex-wrap">
                                <div className="flex-grow-1">
                                  <strong>{t.MATERIAL_NAME}: </strong>{" "}
                                  {(
                                    t.totalInjectedWeight + t.totalRealWeight
                                  ).toFixed(3)}{" "}
                                  (g)
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="w-100">
                <Card.Body>
                  <Card.Title>Quebra</Card.Title>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    {quebra &&
                      quebra.map((q, i) => {
                        if (q.value !== 0) {
                          return (
                            <Card key={i} className="w-100">
                              <Card.Body className="">
                                <div className="d-flex flex-column flex-wrap">
                                  <div className="flex-grow-1">
                                    <strong>{q.label}: </strong>{" "}
                                    {q.value?.toFixed(3)} (g)
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          );
                        }
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        fullscreen
        backdrop="static"
        keyboard={false}
        show={showModalArvore}
        onHide={closeModalArvore}
      >
        <Modal.Header className="" closeButton>
          <div className="w-100">
            <h5>
              <strong>Lote:</strong> {lote.batch}
            </h5>
            <h6>Árvore de pedidos</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-one">
            <Card.Header>
              <div className="d-flex w-100 flex-column gap-2 justify-content-start">
                <h5>Pedidos a serem selecionados</h5>
                <div className="d-flex flex-row gap-2 ">
                  <div className="border form-search w-100 mb-3">
                    <Form.Control
                      className="w-100"
                      placeholder="Pesquisar"
                      onChange={handleSearch}
                      value={searchValue}
                      id="pesquisar"
                      name="pesquisar"
                    ></Form.Control>
                  </div>
                </div>
                <div
                  className={`w-100 d-flex flex-row gap-3 justify-content-between ${
                    screenWidth < 800 ? "flex-wrap" : null
                  }`}
                >
                  <Button
                    variant={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(i => i.metal_color_code === "AM")
                        ? "primary"
                        : "outline-primary"
                    }
                    active={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(
                        item =>
                          item.metal_color_code &&
                          item.metal_color_code[1] === "AM" &&
                          item.metal_code === "AU"
                      )
                    }
                    onClick={e =>
                      handleAddMaterialChecked({
                        metal_code: "AU",
                        metal_color_code: "AM",
                      })
                    }
                    className="w-100 mb-2"
                  >
                    AM - Ouro Amarelo
                  </Button>

                  <Button
                    variant={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(i => i === "OB")
                        ? "primary"
                        : "outline-primary"
                    }
                    active={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(
                        item =>
                          item.metal_color_code &&
                          item.metal_color_code[1] === "OB" &&
                          item.metal_code === "AU"
                      )
                    }
                    onClick={e =>
                      handleAddMaterialChecked({
                        metal_code: "AU",
                        metal_color_code: "OB",
                      })
                    }
                    className="w-100 mb-2"
                  >
                    OB - Ouro Branco
                  </Button>

                  <Button
                    variant={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(i => i === "RS")
                        ? "primary"
                        : "outline-primary"
                    }
                    active={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(
                        item =>
                          item.metal_color_code &&
                          item.metal_color_code[1] === "RS" &&
                          item.metal_code === "AU"
                      )
                    }
                    onClick={e =>
                      handleAddMaterialChecked({
                        metal_code: "AU",
                        metal_color_code: "RS",
                      })
                    }
                    className="w-100 mb-2"
                  >
                    RS - Ouro Rosé
                  </Button>

                  <Button
                    variant={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(i => i === "AG")
                        ? "primary"
                        : "outline-primary"
                    }
                    active={
                      materialChecked &&
                      Array.isArray(materialChecked) &&
                      materialChecked.some(
                        item =>
                          item.metal_color_code &&
                          item.metal_color_code[1] === "AG" &&
                          item.metal_code === "AG"
                      )
                    }
                    onClick={e =>
                      handleAddMaterialChecked({
                        metal_code: "AG",
                        metal_color_code: "AG",
                      })
                    }
                    className="w-100 mb-2"
                  >
                    AG - Prata
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {filteredData && filteredData.length > 0 ? (
                <Table
                  style={{ width: "100%" }}
                  className={`${
                    screenWidth < 900 ? "overflow-x-auto" : null
                  } card-one table-two table-bordered`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Código do Pedido</th>
                      {/* <th className="text-center">Lote</th> */}
                      <th className="text-center">Peso do pedido</th>
                      <th className="text-center">Material</th>
                      <th className="text-center">Cor do material</th>
                      <th className="text-center">Cod.Peça</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody
                    className={`w-100 ${
                      screenWidth < 900 ? "overvlow-x-auto" : null
                    }`}
                  >
                    {filteredData
                      .filter(data => data.TREE === null)
                      .map(data => (
                        <tr key={data.ORDER_CODE}>
                          <td className="text-center align-middle">
                            {data.ORDER_CODE}
                          </td>
                          {/* <td className="text-center align-middle">
                            {data.BATCH}
                          </td> */}
                          <td className="text-center align-middle">
                            {data.ORDER_WEIGHT}
                          </td>
                          <td className="text-center align-middle">
                            {data.MATERIAL_CODE}
                          </td>
                          <td className="text-center align-middle">
                            {data.MATERIAL_COLOR}
                          </td>
                          <td className="text-center align-middle">
                            {data.PRODUCT_NAME}
                          </td>
                          <td className="text-center align-middle">
                            <Button
                              disabled={
                                materialChecked &&
                                materialChecked &&
                                materialChecked[0].metal_color_code?.length ===
                                  1
                              }
                              onClick={() => handleAddPedido(data)}
                            >
                              Adicionar
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div>
                  <Alert variant="success">
                    Todos os pedidos foram adicionados!
                  </Alert>
                </div>
              )}
            </Card.Body>
          </Card>

          <Card className="mt-3  card-one">
            <Card.Header>
              <h5>Pedidos selecionados</h5>
            </Card.Header>
            <Card.Body>
              <Card>
                <Card.Body>
                  {arvoreData && arvoreData.metal_code !== ""
                    ? arvoreData.tree
                    : "0"}
                </Card.Body>
              </Card>
              <Table className="mt-3 card-one table-two table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Código do Pedido</th>
                    {/* <th className="text-center">Lote</th>
                     */}
                    <th className="text-center">Peso do pedido</th>
                    <th className="text-center">Material</th>
                    <th className="text-center">Cor do material</th>
                    <th className="text-center">Cod.Peça</th>
                  </tr>
                </thead>
                <tbody>
                  {pedidosAdicionados &&
                  Array.isArray(pedidosAdicionados) &&
                  pedidosAdicionados.length > 0 ? (
                    pedidosAdicionados.map(data => (
                      <tr key={data.ORDER_CODE}>
                        <td className="text-center align-middle text-success">
                          <strong>{data.ORDER_CODE}</strong>
                        </td>
                        {/* <td className="text-center align-middle text-success">
                          {data.BATCH}
                        </td>
                         */}
                        <td className="text-center align-middle text-success">
                          {data.ORDER_WEIGHT}
                        </td>
                        <td className="text-center align-middle text-success">
                          {data.MATERIAL_CODE}
                        </td>
                        <td className="text-center align-middle text-success">
                          {data.MATERIAL_COLOR}
                        </td>
                        <td className="text-center align-middle text-success">
                          {data.PRODUCT_NAME}
                        </td>
                        <td className="text-center alignt-middle">
                          <Button
                            variant="danger"
                            onClick={() => handleRemovePedido(data)}
                          >
                            Remover
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9} className="text-center">
                        nenhum pedido adicionado
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="card-one mt-3 d-flex justify-content-between gap-3 w-100">
            <Card.Body
              className={`d-flex flex-row gap-3 ${
                screenWidth < 800 ? "flex-wrap" : null
              }`}
            >
              <Form.Group className="w-100">
                <Form.Label>Tipo de molde</Form.Label>
                <Form.Select
                  disabled={lote.type_mold !== null}
                  name="molde-select"
                  id="molde-select"
                  value={lote.type_mold}
                  onChange={e => handleUpdateMold(e.target.value)}
                >
                  <option value={0}>--Selecione--</option>
                  {mold.map((m, i) => (
                    <option value={m.id} key={i}>
                      {m.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>Peso do molde</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={0}
                  step={"0.01"}
                  value={peso}
                  name="peso-molde"
                  id="peso-molde"
                  onChange={e => handleSetPeso(e)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>Tubo</Form.Label>
                <Form.Select
                  className="w-100"
                  type="number"
                  onChange={e => setTubo(e.target.value)}
                  required
                >
                  <option>--Selecione--</option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "1")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "1")
                    }
                    value="1"
                  >
                    1
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "2")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "2")
                    }
                    value="2"
                  >
                    2
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "3")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "3")
                    }
                    value="3"
                  >
                    3
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "4")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "4")
                    }
                    value="4"
                  >
                    4
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "5")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "5")
                    }
                    value="5"
                  >
                    5
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "6")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "6")
                    }
                    value="6"
                  >
                    6
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "7")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "7")
                    }
                    value="7"
                  >
                    7
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "8")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "8")
                    }
                    value="8"
                  >
                    8
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "9")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "9")
                    }
                    value="9"
                  >
                    9
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "10")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "10")
                    }
                    value="10"
                  >
                    10
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "11")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "11")
                    }
                    value="11"
                  >
                    11
                  </option>
                  <option
                    className={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "12")
                        ? "bg-danger text-white"
                        : null
                    }
                    disabled={
                      modalTree && modalTree.some(t => t.TREE_TUBE == "12")
                    }
                    value="12"
                  >
                    12
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Card>

          <Card className="card-one mt-3 mb-3">
            <Card.Header>
              <h5>Resumo</h5>
            </Card.Header>
            <Card.Body>
              <div className="w-100 gap-3 d-flex flex-row">
                <Card className="w-100">
                  <Card.Body
                    className={`d-flex flex-row justify-content-between ${
                      screenWidth < 800 ? "flex-wrap" : null
                    }`}
                  >
                    <div>
                      <span name="peso-estimado" id="peso-estimado">
                        <strong>Peso dos Pedidos:</strong>{" "}
                        {arvoreData
                          ? arvoreData.soma_pedidos.toFixed(3).replace(".", ",")
                          : 0}
                      </span>
                    </div>
                    <div>
                      <span name="peso-estimado" id="peso-estimado">
                        <strong>
                          Quantidade necessária de material para a árvore:
                        </strong>{" "}
                        {treeInitialWeight.toFixed(3).replace(".", ",")}
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong>Lote:</strong> {lote.batch}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveArvore()}>Salvar</Button>
          <Button variant="secondary" onClick={closeModalArvore}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={showModalAddPeso} onHide={closeModalAddPeso}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar peso do pedido após fundição</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitModalAddPeso}>
          <Modal.Body className="gap-3 d-flex flex-column">
            <Card className="card-one">
              <Card.Body>
                <div>
                  <span>
                    <h4>
                      <strong>Pedido:</strong> {modalAddPesoData.ORDER_CODE}
                    </h4>
                  </span>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-one">
              <Card.Header>Adicionar Peso:</Card.Header>
              <Card.Body>
                <Form.Control
                  type="number"
                  name="peso-final"
                  id="peso-final"
                  step=".01"
                  className="w-100"
                  placeholder="Digite o peso final da peça! "
                ></Form.Control>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Salvar</Button>
            <Button
              variant="secondary"
              onClick={() => {
                closeModalAddPeso();
                removeHide();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal size="xl" show={modalDefeito} onHide={handleCloseModalDefeito}>
        <Modal.Header closeButton>
          <Modal.Title>Peça com defeito</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmitModalDefeito}>
          <Modal.Body>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <h6>
                      <strong>Pedido:</strong> {selectedOrder.ORDER_CODE}
                    </h6>
                    <h6>
                      <strong>Produto: </strong>
                      {selectedOrder.PRODUCT_NAME}
                    </h6>
                    <h6>
                      <strong>Material: </strong>
                      {selectedOrder.MATERIAL_NAME}
                    </h6>
                  </Col>
                  <Col>
                    <h6>
                      <strong>Peso estimado: </strong>
                      {selectedOrder.ORDER_WEIGHT}
                    </h6>
                    <h6>
                      <strong>Peso Fundição:</strong>{" "}
                      {selectedOrder.ORDER_FINAL_WEIGHT} (g)
                    </h6>
                    <h6>
                      <strong>Tubo:</strong> 1
                    </h6>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Form.Group className="mt-3">
              <Form.Label>
                <strong>Detalhe o defeito da peça:</strong>
              </Form.Label>
              <Form.Control
                id="description"
                name="description"
                as="textarea"
                rows={3}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              Salvar
            </Button>
            <Button onClick={handleCloseModalDefeito} variant="secondary">
              Cancelar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        size="lg"
        show={modalAttachmentShow}
        onHide={handleCloseModalAttachment}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-center">
                <img
                  src={modalAttachment}
                  className="img-fluid"
                  alt="Attachment"
                />
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal show={modalFinalizacao} onHide={handleCloseModalFinalizacao}>
        <Modal.Header closeButton>
          <Modal.Title>Finalizar Pedido</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmitFinalizacao}>
          <Card className="card-one">
            <Modal.Body className="card-one">
              <h4>Deseja realmente finalizar o pedido abaixo?</h4>

              <span>Código: {modalFinalizacaoData.ORDER_CODE}</span>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Finalizar
              </Button>
              <Button onClick={handleCloseModalFinalizacao} variant="secondary">
                Cancelar
              </Button>
            </Modal.Footer>
          </Card>
        </form>
      </Modal>

      <Modal fullscreen show={showModalRetail} onHide={handleCloseModalRetail}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar retalho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="d-flex gap-3 flex-column">
              <BatchRetail
                batch={lote}
                sendDataToParent={handleGetResponseBatch}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal show={modalADdQuantidadeOpen} onHide={handleCloseModalQuantidade}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Quantidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-one">
            <Card.Body>
              <Card.Title>
                <strong>Lote: </strong>
                {modalAddQuantidade.batch}
                <hr></hr>
                {arrayMaterial.map((c, i) => (
                  <div key={i}>
                    <span>
                      <strong>{c.name}</strong>
                      <Form.Control
                        type="number"
                        data-code={c.code}
                        value={c.weight}
                        onChange={handleSetMaterial}
                      ></Form.Control>
                    </span>
                  </div>
                ))}
              </Card.Title>
            </Card.Body>
            <Card.Footer className="d-flex gap-2">
              <Button onClick={handleAddMAterialBatch} variant="primary">
                Salvar
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  handleCloseModalQuantidade();
                }}
              >
                Cancelar
              </Button>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalAddPesoArvore}
        onHide={handleCloseModalAddPesoArvore}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalAddPesoArvoreData.field == "real_weight"
              ? "Adicionar peso real (inicial) da Árvore"
              : modalAddPesoArvoreData.field === "retail_weight"
              ? "Adicionar peso reaproveitado de Retalho na Árvore"
              : "Injetar mais material na Árvore"}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmitAddPesoArvore}>
          <Modal.Body>
            <Card className="card-one">
              <Card.Body>
                <div className="d-flex flex-column gap-3">
                  <Card>
                    <Card.Body>
                      <span className="d-flex flex-row gap-1">
                        <strong>Tubo: </strong>
                        {modalAddPesoArvoreData &&
                          modalAddPesoArvoreData.TREE_TUBE}
                      </span>

                      <span className="d-flex flex-row gap-1">
                        <strong>Material: </strong>{" "}
                        <span>
                          {modalAddPesoArvoreData &&
                            modalAddPesoArvoreData.METAL_CODE}
                        </span>
                        <span> - </span>
                        <span>
                          {modalAddPesoArvoreData &&
                            modalAddPesoArvoreData.MATERIAL_NAME}
                        </span>
                      </span>

                      <span className="d-flex flex-row gap-1">
                        <strong>Cor do material: </strong>
                        {modalAddPesoArvoreData &&
                          modalAddPesoArvoreData.MATERIAL_COLOR}
                      </span>
                    </Card.Body>
                  </Card>

                  <Form.Control
                    name={modalAddPesoArvoreData.field}
                    id={modalAddPesoArvoreData.field}
                  ></Form.Control>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              Salvar
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleCloseModalAddPesoArvore();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Lotes;
