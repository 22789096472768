import api from "./api";

export const MoldServices = {

    getMold: (data) => {
        return api.post('/materialController.php', {
            "action": "list_type_mold",
            "token": data.token
        })
    }
}