import { useContext, useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { NailingContext } from "../../../Context/NailingContext/Context";
import { FaArrowRightLong } from "react-icons/fa6";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { dialog, swalErro, swalSucesso } from "../../Swal";

const AlteraStatus = ({ solicitacao }) => {
  const skinMode = localStorage.getItem("skin-mode");
  const [show, setShow] = useState(false);
  const {
    ListRequestStatus,
    listaSolicitacao,
    requestStatus,
    AtualizaSolicitacao,
  } = useContext(NailingContext);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [active, setActive] = useState(null);
  const handleSelectStatus = async status => {
    const MySwalContent = () => (
      <strong
        style={{
          display: "inline-block",
          padding: "2px 6px",
          fontWeight: "bold",
          backgroundColor: skinMode === "dark" ? "" : status.status_color,
          borderRadius: "2px",
          marginRight: "1em",
          color: skinMode === "dark" ? "" : status.text_color,
        }}
      >
        {status.status_name}
      </strong>
    );
    const confirm = await dialog(
      `Deseja alterar o status da solicitação para  <br><strong>${status.status_name}</strong>?`
    );
    if (confirm.isConfirmed) {
      const payload = {
        id_request: solicitacao.id,
        request_status: status.id,
      };

      const response = await AtualizaSolicitacao(payload);
      if (response.data.status === "Success") {
        swalSucesso(response.data.message);
        listaSolicitacao();
      } else {
        swalErro(response.data.message);
      }
    }
  };

  const activeStyle = {
    cursor: "default",
    display: "block",
    fontSize: "inherit",
    width: "100%",
    userSelect: "none",
    backgroundColor: "#091E420F",
    padding: "6px 12px",
    boxSizing: "border-box",
    boxShadow: "inset 2px 0px 0px var(--ds-border-selected, #0052CC)",
  };

  const noActiveStyle = {
    cursor: "default",
    display: "block",
    fontSize: "inherit",
    width: "100%",
    userSelect: "none",
    padding: "6px 12px",
    boxSizing: "border-box",
  };

  return (
    <>
      <Dropdown className="w-50">
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          Alterar Status
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="border-0 px-1 py-3"
          style={{
            boxShadow:
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
          }}
        >
          {requestStatus.map((status, index) => (
            <Dropdown.Item
              className="rounded-0 d-flex flex-row justify-content-between align-items-center gap-3"
              onMouseEnter={() => setActive(status.id)}
              style={active === status.id ? activeStyle : noActiveStyle}
              key={index}
              onClick={() => handleSelectStatus(status)}
            >
              <span>{status.status_description}</span>
              <div className="d-flex flex-row align-items-center gap-1">
                {" "}
                <strong>
                  <FaArrowRightLong />
                </strong>
                {"  "}
                <strong
                  style={{
                    display: "inline-block",
                    padding: "2px 6px",
                    fontWeight: "bold",
                    backgroundColor:
                      skinMode === "dark" ? "" : status.status_color,
                    borderRadius: "2px",
                    marginRight: "1em",
                    color: skinMode === "dark" ? "" : status.text_color,
                  }}
                >
                  {status.status_name}
                </strong>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default AlteraStatus;
