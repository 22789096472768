import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

import "./styles/styles.css";

import { ToastContainer, toast } from "react-toastify";

export default function ResumoExecutivo() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const token = localStorage.getItem("token");

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 mb-0 p-lg-4">
        <Row className="g-3 mb-0 d-flex flex-wrap">
        <iframe width="1920" height="1100" allowFullScreen="true" src="https://app.powerbi.com/view?r=eyJrIjoiZTk5YzlhNWUtMzU2MS00NjFlLWI3Y2YtN2U5MzQyMWZmNzY3IiwidCI6ImQwZGY3YTFjLWVjMzgtNDkwMi1hOTYyLTQyNWZkYjdlNWE5ZSJ9"></iframe>
          </Row>
        <Footer />
        <ToastContainer />
      </div>
    </React.Fragment >
  );
}
