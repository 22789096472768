import React, { useEffect, useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import "./style.css";

function SideModal({ operation, showModal, closeModal }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    closeModal(false)
  };

  return (
    <>
      <Card
        className={`side-modal ${show ? "enter" : null}`}
        style={{ height: "auto" }}
      >
        <Card.Body>
          <Card.Title>
            <h3>Detalhamento</h3>
          </Card.Title>
          <Button onClick={handleClose}>Fechar</Button>
          <hr />
          <div>Pedidos: 2515599, 588000</div>
          <div>Quantidade de Material</div>
        </Card.Body>
      </Card>
    </>
  );
}

export default SideModal;
