import { createContext, useContext, useState } from "react";
import { NotaService } from "../../apiServices/NotaServices/Services";
import { toastErro, toastSucesso } from "../../components/Swal";
import { productServices } from "../../apiServices/productServices";

const NotaContext = createContext();

export const NotaProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const [notas, setNotas] = useState([]);
  const [notaDetalhes, setNotaDetalhes] = useState([]);
  const [calculoNotas, setCalculoNotas] = useState([]);
  const [calculoPedido, setCalculoPedido] = useState([])

  const listNotas = async (data) => {
    const payload = {
      token: token,
      action: "lista_nota",
      note_status: data.selectedStatus,
      selected_client: data.clienteSelecionado,
      selected_note: data.notaSelecionada
    }
    const response = await NotaService.GetNotas(payload);
    setNotas(response.data.notas);
  }

  const getNotaDetalhes = async (id_nota) => {

    const response = await NotaService.GetNotaDetalhes(token, id_nota);
    setNotaDetalhes(response.data.detalhes);
  }

  const geraPdfNota = async (id_nota) => {
    const response = await NotaService.GeraPdfNota(token, id_nota);
    console.log(response)
    return response;
  }

  const calcularNotas = async (data) => {
    const payload = {
      id_orders: data.order,
      cobra_ouro: false,
      cobra_prata: false,
      adicionais: data.adicionais,
      montagem: data.montagem,
      corrente: data.corrente,
      action: "calcular_notas",
      id_config: '1',
      token: token
    }

    const response = await NotaService.CalcularNotas(payload);
    setCalculoNotas(response.data.calculo_nota)
    return response
  }

  const alteraStatusNota = async (data) => {
    const payload = {
      action: "altera_status_nota",
      note_id: data.note_id,
      note_status: data.note_status,
      token: token,
    }

    const response = await NotaService.AlteraStatusNota(payload)
    console.log(response)
    if (response.data.status == "success") {
      toastSucesso("Nota atualizada com sucesso!");
      listNotas("pendente")

    } else {
      toastErro("Erro ao atualizar a nota!");
    }
    return response
  }

  const calcularPedido = async (data) => {

    const payload = {
      id_orders: data.pedidos,
      adicionais: data.adicionais,
      montagem: data.montagem,
      corrente: data.corrente,
      cobra_ouro: false,
      cobra_prata: false,
      action: "calcular_notas",
      id_config: '1',
      token: token
    }

    const response = await NotaService.CalcularNotas(payload);
    setCalculoPedido(response.data.calculo_nota)
    return response
  }

  const listaCorrentes = async () => {
    const response = await NotaService.ListaCorrentes(token);
    return response;
  }

  const verificaProdutoProduzido = async (data) => {
    const payload = {
      token,
      id_product: data.id_product,
      id_order: data.id_order,
    }
    const response = await productServices.verificaProdutoProduzido(payload)
    console.log(response)
  }

  const geraExcel = async (data) => {
    try {
      const payload = {
        token: token,
        action: "export_nota_excel",
        note_status: data.selectedStatus,
        selected_client: data.clienteSelecionado,
        selected_note: data.notaSelecionada
      };

      // Faça a requisição para obter a URL do arquivo
      const response = await NotaService.GetNotas(payload);
      const { file_name, download_url } = response.data.arquivo;

      // Crie um link temporário para o download
      const link = document.createElement('a');
      link.href = download_url; // URL do arquivo
      link.target = '_blank'; // Abrir o arquivo em uma nova aba ou janela
      link.download = file_name; // Nome do arquivo
      document.body.appendChild(link);
      link.click(); // Aciona o download
      document.body.removeChild(link); // Remove o link após o download
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };





  return (
    <NotaContext.Provider value={{
      listNotas,
      notas,
      getNotaDetalhes,
      notaDetalhes,
      geraPdfNota,
      calcularNotas,
      calculoNotas,
      alteraStatusNota,
      calcularPedido,
      calculoPedido,
      verificaProdutoProduzido,
      setCalculoPedido,
      setCalculoNotas,
      geraExcel
    }}>
      {children}
    </NotaContext.Provider>
  )
}

export const useNota = () => {
  return useContext(NotaContext);
}