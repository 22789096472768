import React, { useContext, useState } from "react";
import { Modal, Button, Card, Alert, Badge } from "react-bootstrap";
import { OrderContext } from "../../Context/OrderContext";
import { ScreenContext } from "../../Context/Screen/context";
import {
  BsClock,
  BsFillPersonFill,
  BsFileText,
  BsExclamationCircle,
} from "react-icons/bs";

import { FaRegClock } from "react-icons/fa6";

export const LogModal = ({ order }) => {
  const { orderLog, getOrderLog } = useContext(OrderContext);
  const { screenWidth } = useContext(ScreenContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleOpenModal = () => {
    getOrderLog({ order: order.ID });
    setShow(true);
  };

  return (
    <>
      <Button variant="primary" onClick={handleOpenModal}>
        <i className="ri-file-info-line"></i>
      </Button>
      <Modal fullscreen show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Log de Alterações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            <strong>Pedido: {order.CODE}</strong>
          </h5>
          <hr />

          {orderLog?.history?.length > 0 ? (
            orderLog?.history?.map((log, index) => (
              <Card key={index} className="mb-3">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <strong>
                        {" "}
                        <FaRegClock size={16} />
                      </strong>
                      <strong className="ms-2">
                        {new Date(log.dh).toLocaleString("pt-BR")}
                      </strong>
                      {" - "}
                      {log["status"]}
                    </div>
                    <div>
                      <strong className="mb-1">{log["name"]}</strong>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))
          ) : (
            <Alert variant="info" className="d-flex align-items-center">
              <BsExclamationCircle size={24} className="me-2" />
              Nenhum log de cadastro encontrado. Pedido cadastrado em:{" "}
              {new Date(order.DATE).toLocaleString("pt-BR")}
            </Alert>
          )}

          {orderLog?.logs?.length > 0 ? (
            orderLog?.logs?.map((log, index) => (
              <Card key={index} className="mb-3">
                <Card.Body>
                  <div className="d-flex align-items-center flex-row justify-content-between">
                    <div>
                      <strong>
                        <FaRegClock size={16} />{" "}
                        <span className="ms-2">
                          {new Date(log.data).toLocaleString("pt-BR")}
                        </span>
                      </strong>
                      {" - "}
                      {log.status == "Pré-aceitação" ? (
                        <>
                          <span>{log.status}</span>
                          {" - "}
                          <span>{log.pagina}</span>
                        </>
                      ) : (
                        <span>{log.status}</span>
                      )}
                    </div>
                    <strong>{log.alterado_por}</strong>
                  </div>
                </Card.Body>
              </Card>
            ))
          ) : (
            <Alert variant="info" className="d-flex align-items-center">
              <BsExclamationCircle size={24} className="me-2" />
              Nenhum log de alterações encontrado.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
