import { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Card,
  ListGroup,
  Form,
  Dropdown,
  Alert,
  Image,
} from "react-bootstrap";
import { NailingContext } from "../../../Context/NailingContext/Context";
import { ScreenContext } from "../../../Context/Screen/context";
import { OrderContext } from "../../../Context/OrderContext";
import { Link } from "react-router-dom";
import { apenasNumeros } from "../../../functions/Mask";
import { FaCheck } from "react-icons/fa6";
import { CustomToggle } from "../../CustomToggle/Toggle";
import { useDropzone } from "react-dropzone";

export const ModalSolicitacaoDetalhes = ({ id, client = false, children }) => {
  const { screenWidth } = useContext(ScreenContext);
  const {
    detalhesSolicitacao,
    RecebimentoItens,
    resendRequestEmail,
    SaveRequestItemFile,
  } = useContext(NailingContext);
  const { GetRequestPdf } = useContext(OrderContext);
  const [show, setShow] = useState(false);
  const [solicitacoes, setSolicitacoes] = useState([]);

  const [cravacaoState, setCravacaoState] = useState([]);

  const handleSetCravacao = () => {
    if (solicitacoes && solicitacoes.dados_pedidos) {
      const novosItensDeCravacao = solicitacoes?.dados_pedidos?.flatMap(
        pedido => pedido.nailing
      );
      setCravacaoState([]);
      setCravacaoState(cravacaoAtual => [
        ...cravacaoAtual,
        ...novosItensDeCravacao,
      ]);
    }
  };

  useEffect(() => {
    handleSetCravacao();
  }, [solicitacoes]);

  const handleGetDetail = async () => {
    const response = await detalhesSolicitacao({ id_request: id });
    setSolicitacoes([]);
    setSolicitacoes(response.data.result); // Ajuste conforme a estrutura real da sua resposta
    setShow(true);
  };

  const handleGetPdf = async () => {
    const response = await GetRequestPdf({ id_request: id });
  };

  const handleInputChange = (event, idOrder, idCravacao) => {
    const { name, value } = event.target;
    setCravacaoState(prevState =>
      prevState.map(item =>
        item.id_order === idOrder && item.id === idCravacao
          ? { ...item, receivedAmount: apenasNumeros(value) }
          : item
      )
    );
  };
  const preparePayload = () => {
    return cravacaoState.map(item => ({
      id_order: item.id_order,
      id_request_item: item.id,
      received_amount: item.receivedAmount,
    }));
  };

  const handleRecebimento = async () => {
    const payload = preparePayload();
    const response = await RecebimentoItens(payload);
  };

  const handleSaveRecebimento = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = [
      {
        id_order: formData.get("id_order"),
        id_request_item: formData.get("id_request_item"),
        defective_detail: formData.get("defective_detail"),
        defective_amount: formData.get("defective_amount"),
        received_amount: formData.get("received_amount"),
      },
    ];

    const response = await RecebimentoItens(payload);
    if (response.data.status == "Success") {
      handleGetDetail();
      handleSetCravacao();
      setShowEditInput({
        cravacao: "",
        show: false,
      });
    }
  };

  const [showEditInput, setShowEditInput] = useState({
    cravacao: "",
    show: false,
  });

  const handleResendRequestEmail = async () => {
    const response = await resendRequestEmail({
      id_request: id,
    });
  };

  const [modalDefeito, setShowModalDefeito] = useState(false);
  const [selectedCravacaoDefeito, setSelectedCravacaoDefeito] = useState({});

  const handleOpenModalDefeito = cravacaoDefeito => {
    setShowModalDefeito(true);
    setSelectedCravacaoDefeito(cravacaoDefeito);
    setShow(false);
  };

  const handleFileChange = file => {
    setFile(file);
  };

  const [modalInsereAnexoShow, setModalInsereAnexoShow] = useState(false);
  const [selectedCravacaoAnexo, setSelectedCravacaoAnexo] = useState({});
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleOpenModalInsereAnexo = cravacaoAnexo => {
    setModalInsereAnexoShow(true);
    setSelectedCravacaoAnexo(cravacaoAnexo);
    setShow(false);
  };

  const handleSaveRequestItemFile = async e => {
    e.preventDefault();
    const payload = {
      id_request_item: selectedCravacaoAnexo.id,
      file_name: description,
      file: file,
    };

    const response = await SaveRequestItemFile(payload);
    if (response.data.status == "Success") {
      handleGetDetail();
      handleSetCravacao();
      setShowEditInput({
        cravacao: "",
        show: false,
      });
    }
  };

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result); // Atualiza a URL de preview com o resultado do FileReader
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null); // Limpa o preview se nenhum arquivo for selecionado
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="h-100 text-primary border-0 bg-transparent text-start"
        style={{
          cursor: "pointer",
        }}
        onClick={handleGetDetail}
      >
        {children}
      </Button>
      <Modal
        size="xl"
        show={show}
        fullscreen
        onHide={() => {
          setShow(false);
          setCravacaoState([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhes da Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {solicitacoes && (
            <>
              <Card className="card-one mb-3">
                <Card.Header as="h5">Solicitação</Card.Header>
                <Card.Body>
                  <div className="d-flex flex-column gap-2">
                    <span>
                      <strong>ID:</strong> {solicitacoes?.solicitacao?.id}
                    </span>
                    <span>
                      <strong>Data:</strong>{" "}
                      {new Date(
                        solicitacoes?.solicitacao?.created_at
                      ).toLocaleDateString("pt-BR")}{" "}
                      {new Date(
                        solicitacoes?.solicitacao?.created_at
                      ).toLocaleTimeString("pt-BR")}
                    </span>

                    {!client ? (
                      <span>
                        <strong>Cliente:</strong>{" "}
                        {solicitacoes?.solicitacao?.client_name}
                      </span>
                    ) : null}
                    <span>
                      <strong>Criado por:</strong>{" "}
                      {solicitacoes?.solicitacao?.created_by}
                    </span>
                  </div>
                </Card.Body>
              </Card>
              {solicitacoes?.dados_pedidos?.map((pedido, index) => (
                <>
                  <Card key={index} className="card-one  mb-3">
                    <Card.Header>
                      <Card.Title>Pedido {pedido.order_code}</Card.Title>
                    </Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Produto:</strong> {pedido.product}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Material:</strong> {pedido.material_name} (
                        {pedido.material_color})
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex flex-row  gap-3">
                        {
                          <Card
                            className="w-30"
                            style={{
                              height: "520px",
                              boxSizing: "border-box",
                              overflow: "hidden",
                            }}
                          >
                            <Card.Body>
                              <div className="d-flex flex-column gap-1">
                                <img
                                  src={`https://www.apicriartgold.qtsys.com.br/getfile?type=file&name=${pedido.attachment[0]?.file}`}
                                  width="100%"
                                  alt=""
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        }
                        <Card className="card-one w-100">
                          <Card.Body>
                            <div className="border-bottom">
                              <h4>Itens da Solicitação</h4>
                            </div>
                            <div className="mt-3">
                              <div
                                className="p-1 d-flex flex-column gap-3"
                                style={{
                                  height: "400px",
                                  overflow: "auto",
                                }}
                              >
                                {pedido.nailing.map(
                                  (cravacao, cravacaoIndex) => (
                                    <Card
                                      key={cravacaoIndex}
                                      className="card-one mb-3"
                                    >
                                      <Card.Header className="d-flex flex-row justify-content-between">
                                        <Card.Title>
                                          {cravacao.stone} -{" "}
                                          {cravacao.description}
                                        </Card.Title>

                                        {!client ? (
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle}>
                                              <i className="ri-more-2-fill"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  setShowEditInput({
                                                    cravacao: cravacao.id,
                                                    show: true,
                                                  })
                                                }
                                              >
                                                Editar
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleOpenModalDefeito(
                                                    cravacao
                                                  )
                                                }
                                              >
                                                Informar Defeito
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : null}
                                      </Card.Header>
                                      <Card.Body>
                                        {cravacaoState &&
                                          cravacaoState.map(
                                            (cravacaoItem, i) => {
                                              if (
                                                cravacaoItem.id_order ===
                                                  cravacao.id_order &&
                                                cravacaoItem.id === cravacao.id
                                              ) {
                                                return (
                                                  <div
                                                    key={`${cravacaoItem.id}_${cravacaoItem.id_order}`}
                                                    className="mb-2"
                                                  >
                                                    <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                                      <div className="d-flex flex-column">
                                                        {cravacao.module !==
                                                          null &&
                                                        cravacao.module !==
                                                          undefined ? (
                                                          <div className="d-flex flex-row gap-2 align-items-center">
                                                            <span>Módulo:</span>
                                                            <strong>
                                                              {cravacao.module}
                                                            </strong>
                                                          </div>
                                                        ) : null}

                                                        <div className="d-flex flex-row gap-2 align-items-center">
                                                          <span>
                                                            Quantidade
                                                            Necessária:
                                                          </span>
                                                          <strong>
                                                            {cravacao.amount <
                                                            10
                                                              ? "0" +
                                                                cravacao.amount
                                                              : cravacao.amount}{" "}
                                                            Un
                                                          </strong>
                                                        </div>
                                                        <div className="d-flex flex-row gap-2 align-items-center">
                                                          <span>
                                                            Recebido:{" "}
                                                          </span>
                                                          <strong>
                                                            {cravacao.received}
                                                          </strong>
                                                        </div>
                                                      </div>
                                                      {cravacao.received !==
                                                        null &&
                                                      Number(
                                                        cravacao.received
                                                      ) > 0 ? (
                                                        <div className="d-flex  flex-row  justify-content-between align-items-center">
                                                          <div className="d-flex align-items-center">
                                                            <span
                                                              style={{
                                                                marginRight:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Item recebido
                                                            </span>
                                                            <FaCheck
                                                              style={{
                                                                color: "green",
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>

                                                    {(cravacao.received !==
                                                      null &&
                                                      Number(
                                                        cravacao.received
                                                      ) == 0 &&
                                                      client == false) ||
                                                    (showEditInput.cravacao ==
                                                      cravacaoItem.id &&
                                                      showEditInput.show ==
                                                        true &&
                                                      client == false) ? (
                                                      <Card>
                                                        <Card.Body>
                                                          <div>
                                                            <Card.Title>
                                                              Quantidade
                                                              Recebida
                                                            </Card.Title>
                                                          </div>
                                                          <form
                                                            onSubmit={
                                                              handleSaveRecebimento
                                                            }
                                                            className="d-flex flex-column gap-3"
                                                          >
                                                            <input
                                                              type="hidden"
                                                              name="id_request_item"
                                                              id="id_request_item"
                                                              value={
                                                                cravacaoItem.id
                                                              }
                                                            />
                                                            <input
                                                              type="hidden"
                                                              name="id_order"
                                                              id="id_order"
                                                              value={
                                                                cravacaoItem.id_order
                                                              }
                                                            />
                                                            <Form.Control
                                                              type="text"
                                                              name={`received_amount`}
                                                              id={`received_amount`}
                                                              className="form-control"
                                                              value={
                                                                cravacaoItem.receivedAmount
                                                              }
                                                              onChange={e =>
                                                                handleInputChange(
                                                                  e,
                                                                  cravacao.id_order,
                                                                  cravacao.id
                                                                )
                                                              }
                                                            />
                                                            <div>
                                                              <Button type="submit">
                                                                Receber
                                                              </Button>
                                                            </div>
                                                          </form>
                                                        </Card.Body>
                                                      </Card>
                                                    ) : null}

                                                    {cravacaoItem.defective_amount >
                                                      0 ||
                                                    cravacaoItem.defective_amount !==
                                                      null ? (
                                                      <Card className="mt-3">
                                                        <Card.Body>
                                                          <Alert variant="danger">
                                                            Contém item com
                                                            defeito
                                                          </Alert>

                                                          <div className="d-flex flex-row gap-3">
                                                            <Card>
                                                              <Card.Body>
                                                                <strong>
                                                                  Quantidade:{" "}
                                                                </strong>
                                                                <span>
                                                                  {
                                                                    cravacaoItem.defective_amount
                                                                  }
                                                                </span>
                                                              </Card.Body>
                                                            </Card>
                                                            <Card className="w-50">
                                                              <Card.Body>
                                                                <div className="border-bottom mb-2">
                                                                  <strong className="mb-1">
                                                                    Detalhes:{" "}
                                                                  </strong>
                                                                </div>

                                                                <span
                                                                  style={{
                                                                    overflow:
                                                                      "hidden",
                                                                    whiteSpace:
                                                                      "pre-wrap",
                                                                  }}
                                                                >
                                                                  {
                                                                    cravacaoItem.defective_detail
                                                                  }
                                                                </span>
                                                              </Card.Body>
                                                            </Card>
                                                          </div>
                                                        </Card.Body>
                                                      </Card>
                                                    ) : null}

                                                    {/* <Card className="mt-3">
                                                      <Card.Body>
                                                        <Button
                                                          onClick={() =>
                                                            handleOpenModalInsereAnexo(
                                                              cravacaoItem
                                                            )
                                                          }
                                                        >
                                                          Anexo
                                                        </Button>
                                                      </Card.Body>
                                                    </Card> */}
                                                  </div>
                                                );
                                              } else {
                                                return null;
                                              }
                                            }
                                          )}
                                      </Card.Body>
                                    </Card>
                                  )
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </>
              ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            flexWrap: "nowrap",
          }}
          className="d-flex flex-row justify-content-end gap-3"
        >
          <div className="w-100 d-flex justify-content-end gap-3">
            {client ? null : (
              <Button className="w-10" onClick={handleResendRequestEmail}>
                Enviar e-mail
              </Button>
            )}
            <Button
              className="w-10"
              variant="secondary"
              onClick={() => {
                setShow(false);
                setCravacaoState([]);
              }}
            >
              Fechar
            </Button>
          </div>

          {/* <div className="w-50  d-flex flex-row justify-content-end gap-3">
            <Button
              className="w-50"
              variant="primary"
              onClick={handleRecebimento}
            >
              Salvar Recebimento
            </Button>
            {/* <Button className="w-50" variant="primary  " onClick={handleGetPdf}>
              Gerar PDF
            </Button> */}
          {/*</div> */}

          {/* <Button
            className="w-15"
            variant="warning"
            onClick={() => setShow(false)}
          >
            Arquivar solicitação
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={modalDefeito}
        onHide={() => {
          setShowModalDefeito(false);
          setShow(true);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Defeito</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSaveRecebimento}>
          <Modal.Body>
            <div className="d-flex flex-row gap-3">
              <Card className="w-100">
                <Card.Body>
                  <div className="d-flex flex-row gap-2">
                    <strong>Pedido:</strong>
                    <span>{selectedCravacaoDefeito.order_code}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Solicitação</strong>
                    <span>{selectedCravacaoDefeito.id_request}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Modulo</strong>
                    <span>{selectedCravacaoDefeito.module}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Pedra</strong>
                    <span>{selectedCravacaoDefeito.stone}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Descrição</strong>
                    <span>{selectedCravacaoDefeito.description}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Formato</strong>
                    <span>{selectedCravacaoDefeito.format}</span>
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <strong>Quantidade Necessária</strong>
                    <span>{selectedCravacaoDefeito.amount}</span>
                  </div>
                </Card.Body>
              </Card>

              <Card className="w-100">
                <Card.Body>
                  <input
                    type="hidden"
                    name="id_order"
                    value={selectedCravacaoDefeito.id_order}
                  />
                  <input
                    type="hidden"
                    name="id_request_item"
                    value={selectedCravacaoDefeito.id}
                  />
                  <Form.Group>
                    <Form.Label>Quantidade de itens com Defeito</Form.Label>
                    <Form.Control
                      type="text"
                      name="defective_amount"
                      id="defective_amount"
                      placeholder="Ex: 3.45 para gramas ou 48 para Unidades"
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Descrição do Defeito</Form.Label>
                    <Form.Control
                      as={"textarea"}
                      type="text"
                      name="defective_detail"
                      id="defective_detail"
                      placeholder="Digite aqui a descrição do defeito"
                    ></Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Salvar</Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModalDefeito(false);
                setShow(true);
              }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        size="xl"
        show={modalInsereAnexoShow}
        onHide={() => {
          setModalInsereAnexoShow(false);
          setShow(true);
        }}
      >
        <Modal.Header>Inserir Anexo</Modal.Header>
        <form onSubmit={handleSaveRequestItemFile}>
          <Modal.Body>
            <Card>
              <Card.Body>
                <Form className="d-flex flex-column gap-3">
                  <Form.Group>
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Dê um título para o arquivo"
                      value={description}
                      onChange={handleDescriptionChange}
                      className={`p-2`} // Ajuste para mobile
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Documento</Form.Label>
                    <div
                      {...getRootProps()}
                      className="dropzone p-3 border border-dashed"
                      style={{
                        minHeight: "150px",
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <span className="d-flex flex-row justify-content-center align-items-center">
                        <svg
                          version="1.1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 65 62"
                          style={{
                            width: "60%",
                            height: "60%",
                            color: "#fcfcfc",
                          }}
                          enable-background="new 0 0 65 65"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <defs />
                          <path
                            fill="#b4b4b4"
                            d="M56.807,30.456V10.291c0-3.653-2.972-6.625-6.625-6.625H7.131c-3.653,0-6.625,2.972-6.625,6.625v35.192  c0,3.653,2.972,6.625,6.625,6.625h25.604c2.772,5.464,8.434,9.226,14.969,9.226c9.258,0,16.79-7.532,16.79-16.79  C64.494,38.642,61.426,33.451,56.807,30.456z M7.131,49.108c-1.999,0-3.625-1.626-3.625-3.625V10.291  c0-1.999,1.626-3.625,3.625-3.625h43.051c1.999,0,3.625,1.626,3.625,3.625v18.628c-1.895-0.743-3.948-1.165-6.103-1.165  c-0.989,0-1.953,0.103-2.896,0.268l-4.89-3.975c-0.007-0.005-0.014-0.008-0.021-0.014c-0.042-0.033-0.087-0.059-0.132-0.087  c-0.041-0.026-0.08-0.054-0.123-0.075c-0.032-0.016-0.066-0.026-0.1-0.04c-0.058-0.024-0.115-0.049-0.175-0.066  c-0.022-0.006-0.046-0.007-0.069-0.013c-0.07-0.016-0.142-0.031-0.213-0.036c-0.028-0.002-0.058,0.001-0.086,0  c-0.066-0.001-0.133-0.003-0.199,0.005c-0.036,0.004-0.071,0.015-0.107,0.021c-0.06,0.011-0.119,0.021-0.177,0.04  c-0.033,0.011-0.065,0.027-0.099,0.041c-0.06,0.023-0.118,0.047-0.175,0.078c-0.006,0.004-0.013,0.005-0.019,0.009l-6.792,3.903  l-8.074-7.523c-0.008-0.008-0.02-0.01-0.028-0.017c-0.136-0.122-0.288-0.213-0.451-0.277c-0.02-0.008-0.039-0.016-0.059-0.023  c-0.163-0.056-0.331-0.082-0.502-0.08c-0.022,0-0.044,0-0.066,0.001c-0.167,0.009-0.332,0.048-0.49,0.114  c-0.023,0.009-0.045,0.018-0.068,0.029c-0.116,0.055-0.228,0.121-0.331,0.208L6.073,33.246c-0.631,0.536-0.708,1.483-0.171,2.114  c0.296,0.349,0.719,0.528,1.143,0.528c0.344,0,0.689-0.117,0.971-0.357l14.275-12.137l11.96,11.146  c-2.086,2.798-3.338,6.254-3.338,10.004c0,1.584,0.235,3.11,0.647,4.564H7.131z M36.25,32.302l-2.521-2.349l5.097-2.928l2.469,2.006  C39.414,29.812,37.715,30.931,36.25,32.302z M47.704,58.334c-7.604,0-13.79-6.187-13.79-13.79s6.187-13.79,13.79-13.79  s13.79,6.187,13.79,13.79S55.308,58.334,47.704,58.334z"
                          />
                          <path
                            fill="#b4b4b4"
                            d="M11.871,20.932c3.488,0,6.326-2.838,6.326-6.326s-2.838-6.326-6.326-6.326s-6.326,2.838-6.326,6.326  S8.383,20.932,11.871,20.932z M11.871,11.279c1.834,0,3.326,1.492,3.326,3.326s-1.492,3.326-3.326,3.326s-3.326-1.492-3.326-3.326  S10.037,11.279,11.871,11.279z"
                          />
                          <path
                            fill="#b4b4b4"
                            d="M48.672,36.147c-0.006-0.005-0.013-0.008-0.02-0.013c-0.021-0.019-0.045-0.034-0.067-0.051  c-0.035-0.025-0.072-0.049-0.109-0.071c-0.024-0.015-0.047-0.031-0.072-0.045c-0.022-0.012-0.047-0.021-0.069-0.032  c-0.039-0.018-0.078-0.032-0.118-0.047c-0.028-0.011-0.056-0.023-0.085-0.032c-0.023-0.007-0.048-0.011-0.071-0.017  c-0.04-0.01-0.081-0.017-0.122-0.023c-0.03-0.005-0.06-0.012-0.09-0.015c-0.027-0.003-0.056-0.002-0.083-0.003  c-0.021-0.001-0.04-0.006-0.061-0.006s-0.04,0.005-0.061,0.006c-0.027,0.001-0.056,0-0.083,0.003c-0.03,0.003-0.06,0.01-0.09,0.015  c-0.041,0.007-0.082,0.014-0.122,0.023c-0.023,0.006-0.048,0.01-0.071,0.017c-0.029,0.009-0.057,0.021-0.085,0.032  c-0.04,0.015-0.079,0.029-0.118,0.047c-0.022,0.011-0.046,0.021-0.069,0.032c-0.024,0.014-0.048,0.03-0.073,0.045  c-0.037,0.022-0.073,0.046-0.107,0.071c-0.023,0.017-0.047,0.032-0.068,0.051c-0.007,0.005-0.014,0.008-0.02,0.013l-6.021,5.085  c-0.633,0.534-0.713,1.48-0.178,2.113c0.296,0.352,0.72,0.532,1.146,0.532c0.342,0,0.686-0.116,0.967-0.354l3.553-3.001v11.272  c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5V40.523l3.553,3.001c0.281,0.238,0.625,0.354,0.967,0.354  c0.427,0,0.851-0.181,1.146-0.532c0.535-0.633,0.455-1.579-0.178-2.113L48.672,36.147z"
                          />
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  {previewUrl && (
                    <div className="preview-container">
                      <Image src={previewUrl} alt="Preview" thumbnail />
                    </div>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShow(false)} variant="secondary">
              Fechar
            </Button>
            <Button type="submit" variant="primary">
              Salvar Alterações
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
