import api from "../api";

export const BatchServices = {
  listMaterialOrder: (data) => {
    return api.post("/orderController.php", {
      action: "listMaterialOrder",
      token: data.token,
      batch: data.batch
    })
  },
  addMaterialBatch: (data) => {
    return api.post("/orderController.php", {
      action: "addMaterialBatch",
      token: data.token,
      batch: data.batch,
      materials: data.materials
    })
  },
  getMaterialReceived: (data) => {
    return api.post("/orderController.php", {
      action: "listMaterialReceived",
      token: data.token,
      batch: data.batch
    })
  },
  getBatchHistory: (data) => {
    return api.post("/orderController.php", {
      action: "get_batch_history",
      token: data.token,
      batch: data.batch
    })
  },
  getMaterialBatch: (data) => {
    const { token, batch } = data
    return api.post("/orderController.php", {
      action: "get_foundry_material",
      token,
      batch
    })
  }
}