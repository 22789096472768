import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const AlertaBalanco = ({ list }) => {
  const [alertShown, setAlertShown] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSeven, setShowSeven] = useState(false);

  const now = new Date();

  const storedDateThree = localStorage.getItem("hour_three")
    ? new Date(localStorage.getItem("hour_three"))
    : null;
  const storedDateFive = new Date(localStorage.getItem("hour_five"));
  const storedDateSeven = new Date(localStorage.getItem("hour_seven"));

  const differenceInMillisecondsThree = now - storedDateThree;
  const differenceInHoursThree =
    differenceInMillisecondsThree / (1000 * 60 * 60);

  const differenceInMillisecondsFive = now - storedDateFive;
  const differenceInHoursFive = differenceInMillisecondsFive / (1000 * 60 * 60);

  const differenceInMillisecondsSeven = now - storedDateSeven;
  const differenceInHoursSeven =
    differenceInMillisecondsSeven / (1000 * 60 * 60);

  const arrayThree = list?.filter(
    (u) => u.total_break >= 3 && u.total_break < 5
  );

  const arrayFive = list?.filter(
    (u) => u.total_break >= 5 && u.total_break < 7
  );

  const arraySeven = list?.filter((u) => u.total_break >= 7);

  useEffect(() => {
    if (arrayThree?.length > 0 && !storedDateThree) {
      localStorage.setItem("hour_three", new Date());
      setShowThree(true);
    }
  }, [arrayThree]);

  useEffect(() => {
    if (arrayFive?.length > 0 && differenceInHoursFive > 3) {
      localStorage.setItem("hour_five", new Date());
      setShowFive(true);
    } else if (arrayFive?.length > 0 && !storedDateFive) {
      localStorage.setItem("hour_five", new Date());
      setShowFive(true);
    }
  }, [arrayFive]);

  useEffect(() => {
    if (
      arraySeven?.length > 0 &&
      storedDateSeven &&
      differenceInHoursSeven > 1
    ) {
      localStorage.setItem("hour_seven", new Date());
      setShowSeven(true);
    } else if (arraySeven?.length > 0 && !storedDateSeven) {
      localStorage.setItem("hour_seven", new Date());
      setShowSeven(true);
    }
  }, [arraySeven]);

  useEffect(() => {
    const currentDate = new Date();

    const array = list?.filter((u) => {
      const createdAt = new Date(u?.account_balance?.created_at);
      const differenceInMilliseconds = currentDate - createdAt;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      return differenceInDays > 30;
    });

    const userList = array?.map((u) => `<span>${u?.user_name}</span>`).join("");

    if (array?.length > 0 && !alertShown) {
      withReactContent(Swal).fire({
        title: "Atenção",
        icon: "warning",
        html: `
          <p>O último balanço dos ourives abaixo foram atualizados há mais de 30 dias.</p>
          <div style="display: flex; flex-direction: column">${userList}</div>
        `,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
      setAlertShown(true);
    }
  }, [list, alertShown]);

  useEffect(() => {
    // if (showThree) {
    //   const userList = arrayThree
    //     ?.map((u) => `<span>${u?.user_name}</span>`)
    //     .join("");

    //   withReactContent(Swal).fire({
    //     title: "Atenção",
    //     icon: "warning",
    //     html: `
    //       <p>A quebra do(s) ourives abaixo ultrapassaram 3(g).</p>
    //       <div style="display: flex; flex-direction: column">${userList}</div>
    //     `,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //   });
    // }

    if (showFive) {
      const userList = arrayFive
        ?.map((u) => `<span>${u?.user_name}</span>`)
        .join("");

      withReactContent(Swal).fire({
        title: "Atenção",
        icon: "warning",
        html: `
          <p>A quebra do(s) ourives abaixo ultrapassaram 5(g). Considere realizar o balanço do ouríves.</p>
          <div style="display: flex; flex-direction: column">${userList}</div>
        `,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
    }

    if (showSeven) {
      const userList = arraySeven
        ?.map((u) => `<span>${u?.user_name}</span>`)
        .join("");

      withReactContent(Swal).fire({
        title: "Atenção",
        icon: "warning",
        html: `
          <p>A quebra do(s) ourives abaixo ultrapassaram 7(g). Considere realizar o balanço do ouríves.</p>
          <div style="display: flex; flex-direction: column">${userList}</div>
        `,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
    }
  }, [showThree, showFive, showSeven]);

  return null; // Retornando null para evitar renderização de elementos desnecessários
};
