import { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Button, Col, Card, Form, Row, Modal } from "react-bootstrap";
import { ManutencaoPedidos } from "../../components/ManutencaoPedidos";
import { Dialog } from "../../components/DialogConfirmacao";
import { toast, ToastContainer } from "react-toastify";

const Manutencao = () => {
  const [dnone, setDnone] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const handleOpenModal = () => {
    setModalShow(true);
  };

  const [dialogShow, setDialogShow] = useState(false);
  const [responseDialog, setResponseDialog] = useState(false);

  const [tab, setTab] = useState(1);
  const handleChangeTab = (e) => {
    setTab(e);
  };

  const [permissionUpdate, setPermissionUpdate] = useState(false);

  const handleChildData = (e) => {
    setResponseDialog(e);
  };

  const closeDialog = () => {
    setDialogShow(false);
    setDnone("");
  };

  const hideModal = () => {
    setDnone("d-none");
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  useEffect(() => {
    if (responseDialog === true) {
      closeDialog();
      handleOpenModal();
      toast.loading("Processando...", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTimeout(() => {
        setPermissionUpdate(true);
        setResponseDialog(false);
      }, 1000);
    }
  }, [responseDialog]);
  return (
    <>
      <ToastContainer />
      <div className="main main-app p-3 p-lg-4 d-flex gap-3 flex-column">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Manutenção de Pedidos</h4>
          </div>
        </div>
        <Row className="h-100">
          <Col md={12} style={{ display: tab === 1 ? "block" : "none" }}>
            <Card className="card-one">
              <Card.Body className="h-100">
                <ManutencaoPedidos permissionUpdate={permissionUpdate} />
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setDialogShow(true);
                    hideModal();
                  }}
                >
                  Finalizar
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>

      {/* <Modal
        className={dnone}
        size="xl"
        show={modalShow}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manutenção de Pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-one">
            <Card.Body>
              <ManutencaoPedidos permissionUpdate={permissionUpdate} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setDialogShow(true);
              hideModal();
            }}
          >
            Finalizar
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Dialog
        showDialog={dialogShow}
        closeDialog={closeDialog}
        sendDataToParent={handleChildData}
        title={"Iniciar fundição"}
        question={"Deseja realmente retroceder os pedidos para tela inicial?"}
      />
    </>
  );
};

export default Manutencao;
