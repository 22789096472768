import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { BalanceContext } from "../../Context/BalanceContext/BalanceContext";
import { AccountContext } from "../../Context/AccountContext/AccountContext";
import {
  cnpjMask,
  cpfMask,
  formatarParaMascaraMoeda,
  realMoeda,
} from "../../functions/Mask";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMediaQuery } from "react-responsive";
import SideModal from "../../components/SideBarDetail/SideModal";
import { RetornoOurives } from "../../components/Ourives/RetornoPedidosOurives";
import { OrderContext } from "../../Context/OrderContext";
import { ToastContainer, toast } from "react-toastify";
import Lista from "../../components/Lista";
import ListaPedidosOurives from "../../components/ListaOurives/ListaPedidosOurives";
import { UserContext } from "../../Context/UserContext";
import Balance from "../../components/Balance";
import { Link } from "react-router-dom";
import { userServices } from "../../apiServices/userServices";

const PaginaUsuario = () => {
  const { userData, getUserData, getUserDataToExtract } =
    useContext(UserContext);

  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    getUserDataToExtract();
  }, []);

  useEffect(() => {
    setSelectedUser({
      id_user: userData[0]?.ID,
      permission_type: userData[0]?.PERMISSION_CODE,
    });
  }, [userData]);

  const id = selectedUser.id_user;
  const page = 13;
  const status = 18;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1000px)" });

  const localVisualizacaoStorage = localStorage.getItem("show_infos_ourives");
  const [visualizacao, setVisualizacao] = useState(
    localVisualizacaoStorage ? JSON.parse(localVisualizacaoStorage) : false
  );

  const alternarVisualizacao = () => {
    setVisualizacao(!visualizacao);
  };

  const { listOrders, orderList, orderAddFinalWeight } =
    useContext(OrderContext);

  const handleFetchOrderList = async function () {
    const payload = {
      page: 1,
      selectedUser: id,
      order_page: page,
      limit: 100,
      evolution_search: null,
      search: null,
      id_status: status,
    };
    orderList(payload);
  };

  const { operationType, operationCode, movimentationType } =
    useContext(BalanceContext); // Estados

  const {
    operationList,
    listOperation,
    totalBreak,
    getTotalBreak,
    getOperationType,
    listMovimentationType,
    getOperationCode,
  } = useContext(BalanceContext); // Metodos

  const { account, accountDetail, accountDebitCredit } =
    useContext(AccountContext); // Estados

  const { getAccount, getAccountDetail, getAccountDebitCredit } =
    useContext(AccountContext); // Métodos

  const [permissionFetch, setPermissionFetch] = useState(false);
  const handleUpdateFetch = () => {
    setPermissionFetch(true);
  };

  const handleFetchAll = () => {
    getAccount(selectedUser.id_user, selectedUser.permission_type);
    getTotalBreak(selectedUser);
    getOperationType();
    getOperationCode({ id_operation_type: 2 });
    listMovimentationType();
    listOperation(selectedUser.id_user, selectedUser.permission_type);
    getAccountDetail(selectedUser.id_user, selectedUser.permission_type);
    getAccountDebitCredit(selectedUser.id_user, selectedUser.permission_type);
    setPermissionFetch(false);
    handleFetchOrderList();
  };

  useEffect(() => {
    handleFetchAll();
  }, []);

  useEffect(() => {
    handleFetchAll();
  }, [selectedUser]);

  const [showModal, setShowModal] = useState(false);
  const [modalDetail, setModalDetail] = useState([]);
  const [modalPedidos, setModalPedidos] = useState(false);
  const handleOpenModalPedidos = () => {
    setModalPedidos(true);
  };

  const handleCloseModalPedidos = () => {
    setModalPedidos(false);
  };

  const [modalRetorno, setModalRetorno] = useState(false);

  const handleOpenModal = e => {
    setShowModal(true);
    setModalDetail(e);
  };

  const handleCloseModal = e => {
    setShowModal(false);
    setModalDetail([]);
  };

  const handleCloseModalRetorno = () => {
    setModalRetorno(false);
  };

  const [modalAddPeso, setModalAddPeso] = useState(false);
  const [modalAddPesoData, setModalAddPesoData] = useState([]);

  const [modalClass, setModalClass] = useState("");
  const handleOpenModalPeso = e => {
    setModalAddPesoData(e.data);
    setModalAddPeso(e.open);
    setModalClass("d-none");
  };

  const closeModalAddPeso = () => {
    setModalAddPeso(false);
    setModalClass("");
  };

  const handleUpdatePeso = async e => {
    e.preventDefault();
    setPermissionFetch(false);
    const formData = new FormData(e.target);

    const payload = {
      key: "acabamento_final_weight",
      value: formData.get("acabamento-final-weight"),
      movimentation_insert: true,
      id_orders: [modalAddPesoData.ID],
      order_code: modalAddPesoData.CODE,
      id_user: id,
    };

    const response = await orderAddFinalWeight(payload);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleFetchAll();
      closeModalAddPeso();
    }
  };

  const [selectedTab, setSelectedTag] = useState("movimentacoes");
  const handleChangeTab = e => {
    setSelectedTag(e);
  };

  const [orders, setOrders] = useState([]);
  const handleGetOrderList = e => {
    setOrders(listOrders);
  };

  const [modalEvolucao, setModalEvolucao] = useState(false);
  const handleOpenEvolucaoPedidos = e => {
    setModalEvolucao(true);
  };

  const handleCloseModalEvolucao = () => {
    setModalEvolucao(false);
  };

  const { getExtract } = useContext(AccountContext);

  const handleGetExtract = async () => {
    const payload = {
      id: id,
    };

    const response = await getExtract(payload);
    window.open(response.data.link, "_blank");
  };
  const saldoAtual =
    (-accountDebitCredit.account_debit || 0) -
    (-accountDebitCredit.account_credit || 0) +
    (accountDebitCredit.account_balance?.prev_balance || 0);

  const valorTotal = operationList
    .filter(i => i.operation_value !== null && i.operation_value !== undefined)
    ?.reduce((acc, curr) => acc + curr.operation_value * curr.total_weight, 0);

  const exibeValorTotal = operationList.some(i => i.permission_code === 15);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(operationList);
  }, [operationList]);

  const [filterOperation, setFilterOperation] = useState(null);

  useEffect(() => {
    if (
      filterOperation !== null &&
      filterOperation !== undefined &&
      filterOperation !== ""
    ) {
      setFilteredData(operationList.filter(o => o.icon_id == filterOperation));
    } else {
      setFilteredData(operationList);
    }
  }, [filterOperation]);

  const handleSearchFilter = searchText => {
    if (searchText === "") {
      // Se o campo de pesquisa estiver vazio, restaura a lista completa
      setFilteredData(operationList);
    } else {
      // Filtra a lista com base no texto de pesquisa
      const filtered = operationList.filter(
        o =>
          o.id_orders &&
          o.id_orders.split(", ").some(id => id.includes(searchText))
      );
      setFilteredData(filtered);
    }
  };

  const handleFilteredData = e => {
    if (filterOperation === e) {
      setFilterOperation(null);
    } else {
      setFilterOperation(e);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <div className="d-flex flex-column gap-3">
            <Card className="card-one border">
              <Card.Body>
                <div>
                  <Card.Body className="d-flex justify-content-between flex-wrap">
                    <h4 className="w-50">
                      {account.fantasy_name
                        ? account.fantasy_name
                        : account.user_name?.split(" ")[0]}{" "}
                      {account.document && account.document.length === 11
                        ? cpfMask(account.document)
                        : cnpjMask(account.document)}
                    </h4>
                    <div className="d-flex flex-row gap-2">
                      {userData?.length > 1
                        ? userData?.map((u, i) => (
                            <Button
                              variant={
                                selectedUser.permission_type ===
                                u.PERMISSION_CODE
                                  ? "primary"
                                  : "outline-primary"
                              }
                              onClick={() =>
                                setSelectedUser({
                                  id_user: u.ID,
                                  permission_type: u.PERMISSION_CODE,
                                })
                              }
                            >
                              {u.USER_TYPE}
                            </Button>
                          ))
                        : null}
                    </div>
                  </Card.Body>
                </div>
              </Card.Body>
            </Card>

            <Row
              className={`${
                selectedTab === "movimentacoes" ? "" : "d-none"
              } d-flex ${isSmallScreen ? "gap-3" : null}`}
            >
              <Col lg={12} md={12}>
                <Card className="card-one border">
                  <Card.Body
                    className="px-4 mt-1"
                    style={{ marginBottom: "-25px", marginLeft: "5px" }}
                  >
                    <Card.Title className="mb-3">
                      <h3>Extrato</h3>
                    </Card.Title>
                    <Card className="my-3">
                      <Card.Body>
                        <div className="d-flex flex-row gap-3 ">
                          <Button
                            id=""
                            name=""
                            variant={
                              filterOperation == 2
                                ? "primary"
                                : "outline-primary"
                            }
                            onClick={e => handleFilteredData(2)}
                            style={{
                              minWidth: "160px",
                            }}
                          >
                            Débitos
                          </Button>

                          <Button
                            id=""
                            name=""
                            variant={
                              filterOperation == 1
                                ? "primary"
                                : "outline-primary"
                            }
                            onClick={e => handleFilteredData(1)}
                            style={{
                              minWidth: "160px",
                            }}
                          >
                            Créditos
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                    {/* <Card className="mb-3">
                      <Card.Body className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column gap-2">
                          <span className="span-inter">Saldo Atual</span>
                          <span
                            style={
                              saldoAtual < 0 && visualizacao
                                ? { color: "rgb(255,87,33)" }
                                : saldoAtual > 0 && visualizacao
                                ? { color: "rgb(62, 156, 53)" }
                                : null
                            }
                            className="span-inter2"
                          >
                            {visualizacao ? (
                              <>{saldoAtual?.toFixed(3) + " (g)"}</>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <span className="span-inter">Total Débitos</span>
                          <span className="span-inter2">
                            {visualizacao ? (
                              <>
                                {(
                                  -accountDebitCredit.account_debit || 0
                                ).toFixed(3) + "   (g)"}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <span className="span-inter">Total Créditos</span>
                          <span className="span-inter2">
                            {visualizacao ? (
                              <>
                                {(
                                  -accountDebitCredit.account_credit || 0
                                ).toFixed(3) + "   (g)"}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <span className="span-inter">Quebra Atual</span>
                          <span className="span-inter2">
                            {visualizacao ? (
                              <>
                                {(-totalBreak.total_break || 0).toFixed(3) +
                                  " (g)"}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                        {exibeValorTotal ? (
                          <div className="d-flex flex-column gap-2">
                            <span className="span-inter">
                              Valor total a acertar
                            </span>
                            <span className="span-inter2">
                              {visualizacao ? (
                                <>R$ {formatarParaMascaraMoeda(valorTotal)}</>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        ) : null}
                      </Card.Body>
                    </Card> */}
                  </Card.Body>
                  <Card.Body
                    style={{
                      paddingTop: "0px",
                      paddingLeft: "30px",
                      paddingRight: "25px",
                    }}
                  >
                    <div
                      style={{ maxHeight: "500px" }}
                      className="px-3 border rounded py-0 mb-3 overflow-auto"
                    >
                      {filteredData &&
                        filteredData.map((o, i) => (
                          <Row key={i} className="border-bottom p-3">
                            <Col
                              md={1}
                              sm={12}
                              className="d-flex align-items-center justify-content-center"
                              dangerouslySetInnerHTML={{ __html: o.icon }}
                            ></Col>
                            <Col
                              md={9}
                              className="d-flex flex-row justify-content-between align-items-center"
                            >
                              <div>
                                <div>
                                  <strong>{o.operation_type_name}</strong>
                                </div>

                                <div style={{ color: o.operation_color }}>
                                  <strong>
                                    {o.operator} {o.total_weight} (g) -{" "}
                                    {o.material_code} {o.material_name}
                                  </strong>
                                </div>
                                <strong>{o.operation_code_name}</strong>
                                <div>
                                  <strong>
                                    {o.operation_date &&
                                      new Date(
                                        o.operation_date
                                      ).toLocaleDateString("pt-br")}
                                  </strong>
                                  <strong>
                                    {" "}
                                    {new Date(
                                      o.operation_date
                                    ).toLocaleTimeString("pt-br")}
                                  </strong>
                                  {o.id_orders && o.id_orders.length > 0 ? (
                                    <div>
                                      <strong>Pedidos: </strong>
                                      <span>{o.id_orders && o.id_orders}</span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                            <Col
                              md={2}
                              sm={12}
                              className="d-flex align-items-center justify-content-center"
                            >
                              {o.operation_value && o.operation_value > 0 ? (
                                <div className="d-flex flex-row justify-content-end w-100 m-auto">
                                  <span>
                                    {" "}
                                    <strong>Valor a acertar:</strong> R${" "}
                                    {realMoeda(
                                      (
                                        o.total_weight * o.operation_value
                                      ).toFixed(2)
                                    )}
                                  </span>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col lg={4} md={12}>
              <Card className="card-one border">
                <Card.Body
                  className="px-4 mt-1"
                  style={{ marginBottom: "-10px", marginLeft: "5px" }}
                >
                  <Card.Title>
                    <h3>Detalhamento</h3>
                  </Card.Title>
                  <hr />
                  <div className="table-container">
                    <div className="table-row header thead-dark">
                      <div className="table-cell">Material</div>
                      <div className="table-cell">Saldo em conta</div>
                    </div>
                    <hr style={{ marginTop: "-25px" }} />
                    {accountDetail &&
                      accountDetail.map((ad, i) => (
                        <div className="table-row mb-3" key={i}>
                          <div className="d-flex flex-row gap-2 align-items-center table-cell">
                            <div
                              className="d-flex flex-row  rounded-circle align-items-center justify-content-center  font-weight-bold"
                              style={{
                                border: "1px solid #161616",
                                fontWeight: "bold", // Ajusta o tamanho da fonte para controlar o tamanho do círculo
                                width: "35px", // Define a largura do círculo
                                height: "35px", // Define a altura do círculo
                              }}
                            >
                              {/* Utilize media queries do CSS para ajustar o tamanho do texto */}
              {/* {ad.material_code}
                            </div>
                            {ad.material_name}
                          </div>
                          <div className="d-flex flex-row align-items-center table-cell">
                            {ad.account_amount && ad.account_amount.toFixed(2)}{" "}
                            (g)
                          </div>
                          <hr></hr>
                        </div>
                      ))}
                  </div>
                </Card.Body>
              </Card> */}
              {/* </Col> */}
            </Row>
            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Detalhes da movimentação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card className="card-one">
                  <Card.Body className="d-flex flex-row gap-3 align-items-center">
                    <Card className="card-one p-2 rounded border">
                      <img
                        width={80}
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          modalDetail.icon
                        )}`}
                      />
                    </Card>
                    <div>
                      <h5>{modalDetail.operation_type_name}</h5>
                      <h5>{modalDetail.operation_code_name}</h5>
                      <h5>
                        {" "}
                        Data da movimentação:
                        {modalDetail.operation_date &&
                          new Date(
                            modalDetail.operation_date
                          ).toLocaleDateString("pt-br")}
                      </h5>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <h5>
                      <strong>Tipo de Movimentação: </strong>
                      {modalDetail.movimentation_name}
                    </h5>
                    <h5>
                      <strong>Peso total: </strong>
                      {modalDetail.total_weight} (g)
                    </h5>
                  </Card.Body>
                  {modalDetail.id_orders && modalDetail.id_orders.length > 0 ? (
                    <Card.Body>
                      <Card.Title>
                        <strong>Pedido(s): </strong>
                        {modalDetail.id_orders && modalDetail.id_orders}
                      </Card.Title>
                    </Card.Body>
                  ) : null}

                  <Card>
                    <Card.Body>
                      <Card.Title>Detalhamento</Card.Title>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {modalDetail.operation_detail}
                      </div>
                    </Card.Body>
                  </Card>

                  <Card.Body></Card.Body>
                </Card>
              </Modal.Body>
              <Modal.Footer>
                <Button>Fechar</Button>
                <Button>Salvar</Button>
              </Modal.Footer>
            </Modal>
            <Modal
              fullscreen
              className={modalClass}
              dialogClassName="modal-90w"
              onHide={handleCloseModalRetorno}
              show={modalRetorno}
            >
              <Modal.Header>
                <Modal.Title>Opções de Retorno (Ourives)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <RetornoOurives
                  permissionFetch={permissionFetch}
                  openModalPeso={handleOpenModalPeso}
                  idUser={id}
                />
              </Modal.Body>
            </Modal>
            <Modal size="lg" onHide={closeModalAddPeso} show={modalAddPeso}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Opções de pedido {modalAddPesoData.CODE}
                </Modal.Title>
              </Modal.Header>
              <form onSubmit={handleUpdatePeso}>
                <Modal.Body className="p-3 d-flex flex-row align-items-center gap-2">
                  <div className="w-100">
                    <img
                      style={{ width: "100%" }}
                      src={modalAddPesoData.FILE}
                    ></img>
                  </div>
                  <Card className="card-one w-100">
                    <Card.Body>
                      <Form.Label as="h4">
                        Adicione o peso final do pedido
                      </Form.Label>
                      <Form.Control
                        id="acabamento-final-weight"
                        name="acabamento-final-weight"
                      ></Form.Control>
                    </Card.Body>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit">Salvar</Button>
                </Modal.Footer>
              </form>
            </Modal>
            {/* <Modal
            fullscreen
            show={modalPedidos}
            onHide={handleCloseModalPedidos}
          >
            <Modal.Header closeButton>
              <Modal.Title>Pedidos com ourives</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListaPedidosOurives
                page={page}
                status={status}
                selectedUser={id}
                listOrder={listOrders}
                operationType={operationType}
                operationCode={operationCode}
                movimentationType={movimentationType}
              ></ListaPedidosOurives>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModalPedidos}>Fechar</Button>
            </Modal.Footer>
          </Modal> */}

            <Modal show={modalEvolucao} onHide={handleCloseModalEvolucao}>
              <Modal.Header>
                <Modal.Title>Evolução de Pedidos</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {listOrders &&
                  listOrders.map((order, i) => <div>{order.ORDER_CODE}</div>)}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaginaUsuario;
