import { Button, Card, Form, Modal, ToastContainer } from "react-bootstrap";
import { AccountContext } from "../../../Context/AccountContext/AccountContext";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ModalCravador } from "./Cravador/ModalCravador";

export default function ControleCravacao() {
	const { list, accountList, addNailingValue } = useContext(AccountContext);

	useEffect(function () {
		accountList([17, 18]);
	}, []);

	const [modalAlterarShow, setModalAlterarShow] = useState(false);
	const [modalAlterarData, setModalAlterarData] = useState([]);
	const [nailingValue, setNailingValue] = useState([]);

	const handleOpenModalAlterarValoresCravadores = data => {
		setModalAlterarShow(true);
		setModalAlterarData(data);
		setNailingValue(data?.nailing_value);
	};

	const changeNailingValue = (nailing, event) => {
		const index = nailingValue.findIndex(n => n.id_nailing_value === nailing);
		if (index !== -1) {
			const newArray = [...nailingValue];
			newArray[index] = {
				...newArray[index],
				nailing_value: event?.replace(",", "."),
			};
			setNailingValue(newArray);
		}
	};

	const handleSubmitAlteraValoresCravadores = async e => {
		e.preventDefault();
		toast.loading("Salvando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		const payload = {
			user: modalAlterarData.id_user,
			nailing: nailingValue,
		};

		const response = await addNailingValue(payload);

		if (response.data.status == "success") {
			toast.dismiss();
			toast.success(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
			accountList([17]);
			setModalAlterarShow(false);
		} else {
			toast.dismiss();
			toast.error(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
		}
	};

	return (
		<div>
			<ToastContainer />
			<div className="d-flex flex-column gap-3">
				{list.some(u => u.permission_type == 17) ? (
					<Card className="card-one">
						<Card.Header>
							<Card.Title>Cravadores Internos</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex flex-column gap-3">
							{list?.map((u, i) => {
								if (u.permission_type == 17) {
									return (
										<div className="" key={i}>
											<div className="d-flex justify-content-between">
												<Card className="d-flex w-100 flex-row justify-content-between align-items-center">
													<Card.Body className="d-flex w-100 flex-row justify-content-between align-items-center">
														<Card.Title className="px-2 py-0 m-0 d-flex align-items-center justify-content-center">
															<ModalCravador setter={u} />
														</Card.Title>

														<div className="w-60 d-flex flex-row gap-3 justify-content-between align-items-center">
															{u.nailing_value?.map((v, i) => (
																<span key={i}>
																	<strong>{v?.nailing_type?.charAt(0).toUpperCase() + v?.nailing_type?.toLowerCase().slice(1)}</strong>:{" "}
																	{new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(v.nailing_value))}
																</span>
															))}
														</div>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								}
							})}
						</Card.Body>
					</Card>
				) : null}

				{list.some(u => u.permission_type == 18) ? (
					<Card className="card-one">
						<Card.Header>
							<Card.Title>Cravadores Externos</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex flex-column gap-3">
							{list?.map((u, index_user_list_permission_18) => {
								if (u.permission_type == 18) {
									return (
										<div key={index_user_list_permission_18} className="">
											<div className="d-flex justify-content-between">
												<Card className="d-flex w-100 flex-row justify-content-between align-items-center">
													<Card.Body className="d-flex w-100 flex-row justify-content-between align-items-center">
														<Card.Title className="px-2 py-0 m-0 d-flex align-items-center justify-content-center">
															<ModalCravador setter={u} />
														</Card.Title>

														<div className="w-60 d-flex flex-row gap-3 justify-content-between align-items-center">
															{u.nailing_value?.map((v, index_nailing_value) => (
																<span key={index_nailing_value}>
																	<strong>{v?.nailing_type?.charAt(0).toUpperCase() + v?.nailing_type?.toLowerCase().slice(1)}</strong>:{" "}
																	{new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(v.nailing_value))}
																</span>
															))}
														</div>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								}
							})}
						</Card.Body>
					</Card>
				) : null}
			</div>
			<Modal size="xl" show={modalAlterarShow} onHide={() => setModalAlterarShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Alterar Valores de Cravação</Modal.Title>
				</Modal.Header>
				<form onSubmit={handleSubmitAlteraValoresCravadores}>
					<Modal.Body>
						<Card className="card-one">
							<Card.Body>
								<Card.Title> {modalAlterarData?.user_name}</Card.Title>
								<hr></hr>
								<div className="d-flex flex-row gap-3 justify-content-between">
									{nailingValue?.map((v, i) => (
										<Form.Group>
											<Form.Label>
												<strong>{v.nailing_type.charAt(0) + v.nailing_type.toLowerCase().substring(1)}</strong>
											</Form.Label>
											<Form.Control onChange={e => changeNailingValue(v.id_nailing_value, e.target.value)} value={v.nailing_value}></Form.Control>
										</Form.Group>
									))}
								</div>
							</Card.Body>
						</Card>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setModalAlterarShow(false)} variant="secondary">
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Salvar
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		</div>
	);
}
