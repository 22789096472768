import { createContext, useEffect, useState } from "react";
import { userServices } from "../apiServices/userServices";
import { PermissionService } from "../apiServices/permissionService";

const UserContext = createContext();

const token = localStorage.getItem("token");

const UserProvider = ({ children }) => {
	const [userData, setUserData] = useState([]);
	const [clientData, setClientData] = useState({});

	const plNome = localStorage.getItem("name") && localStorage.getItem("name").split(" ")[0][0];
	const plSobrenome = localStorage.getItem("name") && localStorage.getItem("name").split(" ")[1][0];

	const login = async event => {
		try {
			const data = {
				email: event.email,
				password: event.password,
			};
			const response = await userServices.login(data);
			console.log(response);
			if (response.data.status == "success") {
				localStorage.setItem("token", response.data.data);
				localStorage.setItem("utp", response.data.utp);

				const responseData = await userServices.getUserData(response.data.data);
				const responseSideBarOptions = await userServices.getSideBarOptions(response.data.data);

				const permissions = responseSideBarOptions.data.map(permission => {
					return permission.id;
				});

				localStorage.setItem("up", JSON.stringify(permissions));
				setUserData(responseData.data.result);
			} else {
				// Lidar com erro de login
				console.error("Erro de login:", response.data.message);
			}
			return response;
		} catch (error) {
			// Lidar com erro de requisição
			console.error("Erro ao processar o login:", error.message);
		}
	};

	const listUser = async data => {
		const request = {
			token: token,
			pageSearch: data.page,
			permission_type: data.permissionType,
			searchKey: data.searchKey,
			page: 1,
			limit: 9999,
		};
		return await userServices.getUsers(request);
	};

	const getUserData = async () => {
		const response = await userServices.getUserData(token);
		if (response) {
			setUserData(response.data.result);
		}
	};

	const GetPermission = async () => {
		const response = await PermissionService.getPermission({
			token: token,
		});
	};

	const getUsers = async data => {
		const payload = {
			token: token,
			extract_user: data.extract_user,
		};

		const response = await userServices.getUsers(payload);
		if (data.extract_user) {
			setUserData(response.data.result);
		}
	};

	const getUserDataToExtract = async () => {
		const payload = {
			action: "getUserDataToExtract",
			token: token,
		};
		const response = await userServices.getUserDataToExtract(payload);
		setUserData(response.data);
	};

	const getClienteData = async data => {
		const payload = {
			token: token,
			user_id: data.user_id,
		};

		const response = await userServices.getUserDataToEdit(payload);
		setClientData(response.data.result);
	};

	return (
		<UserContext.Provider
			value={{
				userData,
				plNome,
				plSobrenome,
				login,
				listUser,
				GetPermission,
				getUserData,
				getUsers,
				getUserDataToExtract,
				getClienteData,
				clientData,
				setClientData,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export { UserContext, UserProvider };
