import { Button, Card, Form, Nav, Table } from "react-bootstrap";
import { useNota } from "../../Context/NotaContext/NotaContext";
import { useContext, useEffect, useRef, useState } from "react";
import { NotaDetalhes } from "./NotaDeltalhes/NotaDetalhes";
import { FaRegClock } from "react-icons/fa6";
import { BsSendCheck } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import DynamicIcon from "../DynamicIcon/DynamicIcon";
import { UserContext } from "../../Context/UserContext";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { cnpjMask, cpfMask } from "../../functions/Mask";

export const PedidosEmFaturamento = () => {
	const [selectedStatus, setSelectedStatus] = useState("pendente");
	const { listUser } = useContext(UserContext);

	const typeaheadRef = useRef(null);

	const [clientList, setClientList] = useState([]);
	const [isFetchingCliente, setIsFetchingCliente] = useState(false);
	const handleGetUser = async query => {
		if (query.length > 0) {
			setIsFetchingCliente(true);
			const response = await listUser({
				page: "client",
				limit: 9999,
				permissionType: 1,
				searchKey: query,
			});
			setClientList(response.data.users);
			setIsFetchingCliente(false);
		} else {
			setClienteSelecionado(null);
			setIsFetchingCliente(false);
		}
	};

	const { notas, listNotas, geraExcel } = useNota();

	const [clienteSelecionado, setClienteSelecionado] = useState(null);
	const handleSetClienteSelecionado = e => {
		if (e.length > 0) {
			setClienteSelecionado(e[0].ID);
		} else {
			setClienteSelecionado(null);
		}
	};

	const [notaSelecionada, setNotaSelecionada] = useState(null);
	const handleGeraExcel = async () => {
		await geraExcel({ clienteSelecionado, selectedStatus, notaSelecionada });
	};
	useEffect(() => {
		listNotas({ clienteSelecionado, selectedStatus, notaSelecionada });
	}, [selectedStatus, clienteSelecionado, notaSelecionada]);
	return (
		<Card className="card-one">
			<Card.Body>
				<div className="d-flex flex-row justify-content-between">
					<h5>Notas</h5>
					<Button
						onClick={handleGeraExcel}
						className="px-4 d-flex flex-row gap-1"
						style={{
							backgroundColor: "#107c41",
							fontSize: "1.2rem",
						}}
					>
						<i class="ri-file-excel-2-fill"></i>
						Exportar
					</Button>
				</div>
				<Card.Body className="">
					{/* <Card className="w-25">
						<Card.Body>
							<Nav className="nav-sidebar">
								{[
									{ label: "Pendente envio", value: "pendente", icon: <FaRegClock /> },
									{ label: "Enviado", value: "enviado", icon: <BsSendCheck /> },
									{ label: "Cancelado", value: "cancelado", icon: <MdOutlineCancel /> },
								]?.map((status, index) => (
									<Nav.Link key={index} href="" active={status.value == selectedStatus} onClick={() => setSelectedStatus(status?.value)} className="d-flex flex-row gap-3">
										<h5 className="font-xl">{status?.icon}</h5> <span>{status?.label}</span>
									</Nav.Link>
								))}
							</Nav>
						</Card.Body>
					</Card> */}
					<Table bordered hover className="w-100" responsive>
						<thead className="thead-light">
							<tr>
								<th className="text-center">
									<Form.Control
										type="number"
										onChange={e => {
											if (e.target.value.length >= 4) {
												setNotaSelecionada(parseInt(e.target.value));
											} else if (e.target.value.length === 0) {
												setNotaSelecionada(null);
											}
										}}
										placeholder="#"
										className="mt-1"
									/>
								</th>
								<th className="text-center">
									<Form.Control type="date" placeholder="Filtrar Data" className="mt-1" />
								</th>

								<th className="text-center">
									<Form.Control type="text" disabled value="CNPJ/CPF" style={{ backgroundColor: "transparent" }} className="text-center mt-1" />
								</th>

								<th className="text-center">
									<AsyncTypeahead
										ref={typeaheadRef}
										onChange={handleSetClienteSelecionado}
										labelKey={option => option.FANTASY_NAME}
										placeholder="Digite o nome do cliente"
										id="cliente"
										options={clientList}
										isLoading={isFetchingCliente}
										minLength={4}
										onSearch={handleGetUser}
										filterBy={(option, props) => {
											const query = props.text.toLowerCase();
											const nameMatch = option?.FANTASY_NAME?.toLowerCase().includes(query);
											return nameMatch;
										}}
										renderMenuItemChildren={option => (
											<div className="w-100 d-flex flex-row gap-3 align-items-center">
												<h6>{`${option.FANTASY_NAME}`}</h6>
											</div>
										)}
									/>
								</th>

								<th className="text-center">
									<Form.Select onChange={e => setSelectedStatus(e.target.value)}>
										<option value="">Todos</option>
										<option value="pendente">Pendente envio</option>
										<option value="enviado">Enviado</option>
										<option value="cancelado">Cancelado</option>
									</Form.Select>
								</th>

								<th className="text-center">
									<Form.Control type="text" disabled value="Frete" style={{ backgroundColor: "transparent" }} className="text-center mt-1" />
								</th>

								<th className="text-center">
									<Form.Control type="text" disabled value="Valor" style={{ backgroundColor: "transparent" }} className="text-center mt-1" />
								</th>

								<th className="d-flex flex-row align-items-center justify-content-center">Detalhes</th>
							</tr>
						</thead>

						<tbody>
							{notas?.map((nota, i) => (
								<tr key={i}>
									<td className="text-center">{nota.note_id}</td>
									<td className="text-center">{new Date(nota.created_at).toLocaleDateString("pt-BR")}</td>
									<td className="text-center">{nota?.client_document?.length > 11 ? cnpjMask(nota?.client_document) : cpfMask(nota?.client_document)}</td>
									<td className="text-center">{nota.client_fantasy_name ? nota.client_fantasy_name : nota.client_name}</td>
									<td className="text-center">{nota.note_status == "pendente" ? "Pendente" : nota.note_status == "concluido" ? "Concluído" : "Cancelado"}</td>
									<td className="text-center">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(nota.total_frete || 0))}</td>
									<td className="text-center">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(nota.total || 0))}</td>

									<td className="text-center">
										<NotaDetalhes nota={nota} />
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card.Body>
		</Card>
	);
};
