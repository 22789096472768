import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import { apiServices } from "../apiServices/ApiServices";
import {
  Card,
  Pagination,
  Table,
  Button,
  Accordion,
  Modal,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Alert,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { productServices } from "../apiServices/productServices";
import { Search } from "lucide-react";
import { EvolucaoPedidos } from "./ListaPedidos/EvolucaoPedidos";
import { htmlDialog, toastErro, toastInfo, toastSucesso } from "./Swal";

const Lista = ({
  titulo,
  nextPage,
  status,
  page,
  buscar,
  addPesoPrototipagem,
}) => {
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [tooltipOpen, setTooltipOpen] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const handleSearch = event => {
    event.preventDefault();
    let formData = event.target.pesquisar.value;
    if (formData.length > 1) {
      setSearchKey(formData);
    } else if (formData.length === 0) {
      setSearchKey("");
    }
  };
  const handleSearchNoneValue = event => {
    let formData = event.target.value;
    if (formData.length === 0) {
      setSearchKey("");
    }
  };
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [modalHistoryShow, setModalHistoryShow] = useState(false);
  const [modalHistoryContent, setMOdalHistoryContent] = useState([]);

  const [modalViewShow, setModalViewShow] = useState(false);
  const [modalViewContent, setModalViewContent] = useState([]);
  const [nailing, setNailing] = useState([]);
  const [typeNailing, setTypeNailing] = useState([]);
  const [metal, setMetal] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [module, setModule] = useState([]);

  const [openModalMetalAtribuicao, setOpenModalMetalAtribuicao] =
    useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalMetalAtribuicaoDetal, setModalDetalAtribuicaoDetal] = useState(
    []
  );

  const [statusToUpdate, setStatusToUpdate] = useState([]);
  const [orderDataToStatus, setOrderDataToStatus] = useState([]);

  const [ordersIds, setOrdersIds] = useState([]);

  const [modalAddPesoData, setModalAddPesoData] = useState([]);
  const [showModalAddPeso, setShowModalAddPeso] = useState(false);

  const [modalAddPesoPrototipagemData, setModalAddPesoPrototipagemData] =
    useState([]);
  const [showModalAddPesoPrototipagem, setShowModalAddPesoPrototipagem] =
    useState(false);

  const handleOpenModalAddPesoPrototipagem = e => {
    setShowModalAddPesoPrototipagem(true);
    setModalAddPesoPrototipagemData(e);
  };

  const closeModalAddPesoPrototipagem = () => {
    setShowModalAddPesoPrototipagem(false);
  };

  const handleSubmitModalAddPesoPrototipagem = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      required_weight_total: formData.get("peso-inicial"),
      order: [modalAddPesoPrototipagemData.ID],
      id_page: page,
      token: token,
    };

    const response = await apiServices.updateStatusOrder(data);

    if ((response.data.status = "success")) {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      fetchall();
      closeModalAddPesoPrototipagem();
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  let todosAceitacao = null;

  if (orders && Array.isArray(orders) && orders.length > 0) {
    todosAceitacao = orders.every(order => order.ID_STATUS === 17);
  }

  const codigoPaginaCondicional = data => {
    if (data.NAILING_ID !== null) {
      if (page === 12) {
        return page + 1;
      }
    } else if (data.NAILING_ID === null) {
      if (page === 12) {
        return page + 2;
      }
    } else {
      return page + 1;
    }
  };

  const handleUpdateStatusOrder = async order => {
    const data = {
      token: token,
      order: [order.ID],
      id_page: page + 1,
      status: 17,
    };

    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const response = await apiServices.updateStatusOrder(data);
    if (response) {
      if (response.data.status === "success") {
        toast.dismiss();
        fetchall();
        toast.success(response.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const handleSubmitModalAddPeso = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      peso_final: formData.get("peso-final"),
      acabamento_peso_inicial: formData.get("acabamento-peso-inicial"),
      acabamento_peso_final: formData.get("acabamento-peso-final"),
      cravacao_peso_inicial: formData.get("cravacao-peso-inicial"),
      order: [modalAddPesoData.ID],
      id_page: page,
      token: token,
    };
    const response = await apiServices.updateStatusOrder(data);

    if ((response.data.status = "success")) {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      closeModalAddPeso();
      fetchall();
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const closeModalAddPeso = () => {
    setShowModalAddPeso(false);
  };

  const handleOpenModalAddPeso = e => {
    setShowModalAddPeso(true);
    setModalAddPesoData(e);
  };

  const formatDate = data => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleOrderPdf = async id => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id_order: id,
      token: token,
    };
    const response = await apiServices.getOrderPdf(data);
    if (response.data.link !== "") {
      var link = response.data.link;
      var anchor = document.createElement("a");
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      // Configure o atributo href e download
      anchor.href = link;
      anchor.setAttribute("target", "_blank");
      toast.dismiss();
      toast.success("Documento gerado com sucesso!");
      // Dispare um clique simulado no elemento de âncora
      setTimeout(() => {
        anchor.click();
      }, 1000);

      // Remova o elemento de âncora
      document.body.removeChild(anchor);
    }
  };

  const handleOrderHistory = order => {
    apiServices.getORderHistory(order, token).then(response => {
      setMOdalHistoryContent(response.data);
      setModalHistoryShow(true);
    });
  };

  const toggleTooltip = orderId => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  const getMetalDetails = async (order, token) => {
    const response = await apiServices.getMetal(order, token);
    if (response) {
      setMetal(response.data);
    }
  };

  const handleOrderView = async order => {
    setSelectedOrder(order);
    setModalViewShow(true);
    try {
      const orderResponse = await apiServices.getOrderView(order, token);
      setModalViewContent(orderResponse.data.order);
    } catch (error) {
      console.log(error);
    }

    await getMetalDetails(order, token);

    await apiServices
      .getModule(order, token)
      .then(response => {
        setModule(response.data);
      })
      .catch(error => console.log(error));

    await apiServices
      .getNailing(order, token)
      .then(response => {
        setNailing(response.data);
      })
      .catch(error => {
        console.log(error);
      });

    await apiServices.getTypeNailing(order, token).then(response => {
      setTypeNailing(response.data);
    });

    await apiServices
      .getAttachment(order, token)
      .then(response => {
        setAttachment(response.data);
      })
      .catch(error => console.log(error));
  };

  const handeCloseOrderView = () => {
    setModalViewShow(false);
  };

  const handleOpenEditModal = async (order, status) => {
    setOpenEditModal(true);

    const response = await apiServices.orderStatusList(order, token);
    const data = {
      order: order,
      status: status,
    };
    setStatusToUpdate(response.data);
    setOrderDataToStatus(data);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
    handleOrderView("");
  };

  const handleOpenMetalAtribuicaoModal = async metal => {
    setOpenModalMetalAtribuicao(true);

    const data = {
      metal: metal,
      order: selectedOrder,
      token: token,
    };

    const response = await productServices.getMetalDetailOrder(data);
    const modalAtribuicaoDetalData = {
      metal: metal,
      ...response.data,
    };
    setModalDetalAtribuicaoDetal(modalAtribuicaoDetalData);
  };

  const getOrder = async (
    token,
    limit,
    currentPage,
    searchKey,
    status,
    page,
    evolution_search
  ) => {
    try {
      const response = await apiServices.getOrder(
        token,
        limit,
        currentPage,
        searchKey,
        status,
        page,
        evolution_search
      );
      setOrders(response.data.orders);
      setTotalPages(response.data.total_pages);
      toast.dismiss();
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  async function fetchall() {
    await getOrder(token, 1000, 1, searchKey, status, page, true);
  }

  useEffect(() => {
    fetchall();
  }, [searchKey, buscar]);

  const handleSetOrdersId = () => {
    const orderIds =
      orders &&
      Array.isArray(orders) &&
      orders.length > 0 &&
      orders.map(order => order.ID);
    setOrdersIds(orderIds);
  };

  const hadleGetOrderHistory = async () => {
    const data = {
      token: token,
      order: ordersIds,
      page: page,
    };
    if (ordersIds.length > 0) {
      const response = await apiServices.getOrderHistory(data);
      // if (response) {
      //   ;
      // }
    }
  };

  const handlePageClick = pageNumber => {
    setCurrentPage(pageNumber);
    getOrder(token, 10, pageNumber, searchKey, status, page, true);
  };

  useEffect(() => {
    handleSetOrdersId();
  }, [orders]);

  useEffect(() => {
    hadleGetOrderHistory();
  }, [ordersIds]);

  const handleOpenDialog = async pedido => {
    const confirm = await htmlDialog(`O pedido ${pedido.CODE} está conforme?`);
    if (confirm.isConfirmed) {
      const data = {
        order: [pedido.ID],
        id_page: 25,
        status: 17,
        token: token,
        is_approved: true,
      };

      const response = await apiServices.updateStatusOrder(data);
      if (response.data.status == "success") {
        toastSucesso("Pedido atualizado com sucesso!");
      } else {
        toastErro("Erro ao atualizar pedido!");
      }
    } else {
      toastInfo("Não sei o que fazer ainda");
    }
  };

  return (
    <>
      {page === 12 ? null : (
        <div>
          <Card className="card-one d-flex">
            <Card.Header className="d-flex justify-content-between">
              <h3>Pedidos em {titulo}</h3>
            </Card.Header>
            <Card.Body>
              <div className="border form-search w-20 mb-3">
                <Form className="d-flex w-100" onSubmit={handleSearch}>
                  <Form.Control
                    className="w-100"
                    placeholder="Pesquisar"
                    onChange={handleSearchNoneValue}
                    id="pesquisar"
                    name="pesquisar"
                  ></Form.Control>
                  <Button type="submit" variant="outline">
                    <Search />
                  </Button>
                </Form>
              </div>
              {todosAceitacao ? (
                <div className="w-100">
                  <Alert>Nenhum pedido evoluído para {titulo}</Alert>
                </div>
              ) : (
                <Table className="table-four table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Cod.Pedido</th>
                      <th className="text-center">Cliente</th>
                      <th className="text-center">Cod.Peça</th>
                      {page === 13 ? (
                        <>
                          <th className="text-center">
                            Peso inicial acabamento
                          </th>
                          <th className="text-center">Peso final acabamento</th>
                        </>
                      ) : null}
                      {page === 14 ? (
                        <th className="text-center">Peso inicial cravação</th>
                      ) : null}
                      <th className="text-center">Tipo de Pedido</th>
                      <th className="text-center">Data</th>
                      {page !== 11 && page !== 12 ? (
                        <th className="text-center">Usuário Atribuído</th>
                      ) : null}
                      {page === 11 || page === 15 ? (
                        <>
                          <th className="text-center">Status</th>
                          <th className="text-center">Avançar</th>
                        </>
                      ) : (
                        <th className="text-center">Adicionar Peso</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="">
                    {orders && Array.isArray(orders) && orders.length > 0
                      ? orders.map(order => {
                          if (
                            order.ID_STATUS === status &&
                            order.ID_TYPE_ORDER
                          ) {
                            return (
                              <tr key={order.CODE}>
                                <td className="text-center">{order.CODE}</td>
                                <td
                                  className="text-center"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "left",
                                    maxWidth: "150px",
                                    zIndex: "-999",
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-${order.ID}`}>
                                        {order.NAME_CLIENT}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      id={order.ID}
                                      onMouseEnter={() =>
                                        toggleTooltip(order.ID)
                                      }
                                      onMouseLeave={() =>
                                        toggleTooltip(order.ID)
                                      }
                                      className="text-truncate"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {order.NAME_CLIENT}
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td className="text-center">
                                  {order.PART_CODE}
                                </td>
                                {page === 12 ? (
                                  <td className="text-center">
                                    {order.FINAL_WEIGHT}
                                  </td>
                                ) : null}
                                {page === 13 ? (
                                  <>
                                    <td className="text-center">
                                      {order.ACABAMENTO_INITIAL_WEIGHT}
                                    </td>
                                    <td className="text-center">
                                      {order.ACABAMENTO_FINAL_WEIGHT}
                                    </td>
                                  </>
                                ) : null}
                                {page === 14 ? (
                                  <td className="text-center">
                                    {order.CRAVACAO_INITIAL_WEIGHT}
                                  </td>
                                ) : null}
                                <td className="text-center">
                                  {order.TYPE_ORDER_DESCRIPTION}
                                </td>
                                <td className="text-center">
                                  {formatDate(order.DATE)}
                                </td>
                                {page !== 11 && page !== 12 ? (
                                  <td className="text-center">
                                    {order.USERNAME}
                                  </td>
                                ) : null}
                                {page === 11 || page === 15 ? (
                                  <td className="text-center">
                                    {order.STATUS}
                                  </td>
                                ) : null}
                                <td className="text-center">
                                  {page === 11 || page === 15 ? (
                                    <Button
                                      onClick={() =>
                                        handleUpdateStatusOrder(order)
                                      }
                                    >
                                      Avançar
                                    </Button>
                                  ) : page == "16" ? (
                                    <Button
                                      onClick={() => handleOpenDialog(order)}
                                    >
                                      Conformidade
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        handleOpenModalAddPeso(order)
                                      }
                                    >
                                      Adicionar Peso
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })
                      : null}
                  </tbody>
                </Table>
              )}
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center">
              <Pagination className="pagination-space pagination-circled mb-0">
                {Array.from({ length: totalPages }, (_, index) => {
                  if (
                    index + 1 === currentPage ||
                    index + 1 === 1 ||
                    index + 1 === totalPages ||
                    (index + 1 >= currentPage - 2 &&
                      index + 1 <= currentPage + 2)
                  ) {
                    return (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageClick(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    );
                  } else if (
                    (index + 1 === currentPage - 3 && currentPage > 5) ||
                    (index + 1 === currentPage + 3 &&
                      currentPage < totalPages - 4)
                  ) {
                    return (
                      <Pagination.Ellipsis
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                      />
                    );
                  }
                  return null;
                })}
              </Pagination>
            </Card.Footer>
          </Card>
        </div>
      )}

      {orders &&
      orders.some(
        order => order.ID_STATUS === 17 && order.ORDER_PAGE === page
      ) ? (
        <div>
          <Card className="card-one mt-3">
            <Card.Header className="">
              <h4>Pedidos pendentes de evolução para {titulo}</h4>
            </Card.Header>
            <Card.Body>
              <Table className="table-four table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Cod.Pedido</th>
                    <th className="text-center">Cliente</th>
                    <th className="text-center">Cod.Peça</th>
                    <th className="text-center">Lote</th>
                    <th className="text-center">Data</th>
                  </tr>
                </thead>
                <tbody className="">
                  {orders.map((order, index) => {
                    if (order.ID_STATUS === 17 && order.ORDER_PAGE === page) {
                      return (
                        <tr key={index}>
                          <td className="text-center">{order.CODE}</td>
                          <td
                            className="text-center"
                            style={{
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "left",
                              maxWidth: "150px",
                              zIndex: "-999",
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${order.ID}`}>
                                  {order.NAME_CLIENT}
                                </Tooltip>
                              }
                            >
                              <span
                                id={order.ID}
                                onMouseEnter={() => toggleTooltip(order.ID)}
                                onMouseLeave={() => toggleTooltip(order.ID)}
                                className="text-truncate"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {order.NAME_CLIENT}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td className="text-center">{order.PART_CODE}</td>
                          <td className="text-center">{order.BATCH}</td>
                          <td className="text-center">
                            {formatDate(order.DATE)}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      ) : null}

      {/* {orders && orders.some((order) => order.ID_TYPE_ORDER === 2) ? (
        <div>
          <Card className="card-one mt-3">
            <Card.Header className="">
              <h4>Pedidos de conserto em {titulo}</h4>
            </Card.Header>
            <Card.Body>
              <Table className="table-four table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Cod.Pedido</th>
                    <th className="text-center">Cliente</th>
                    <th className="text-center">Cod.Peça</th>
                    <th className="text-center">Lote</th>
                    <th className="text-center">Data</th>
                  </tr>
                </thead>
                <tbody className="">
                  {orders.map((order, index) => {
                    if (order.ID_TYPE_ORDER === 2) {
                      return (
                        <tr key={index}>
                          <td className="text-center">{order.CODE}</td>
                          <td
                            className="text-center"
                            style={{
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "left",
                              maxWidth: "150px",
                              zIndex: "-999",
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${order.ID}`}>
                                  {order.NAME_CLIENT}
                                </Tooltip>
                              }
                            >
                              <span
                                id={order.ID}
                                onMouseEnter={() => toggleTooltip(order.ID)}
                                onMouseLeave={() => toggleTooltip(order.ID)}
                                className="text-truncate"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {order.NAME_CLIENT}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td className="text-center">{order.PART_CODE}</td>
                          <td className="text-center">{order.BATCH}</td>
                          <td className="text-center">
                            {formatDate(order.DATE)}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      ) : null} */}

      {orders &&
      orders.some(order => order.ID_STATUS === 22 || order.ID_STATUS === 24) ? (
        <div>
          <Card className="card-one mt-3">
            <Card.Header className="">
              <h4>Pedidos em manutenção</h4>
            </Card.Header>
            <Card.Body>
              <Table className="table-four table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Cod.Pedido</th>
                    <th className="text-center">Cliente</th>
                    <th className="text-center">Cod.Peça</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Data</th>
                  </tr>
                </thead>
                <tbody className="">
                  {orders.map((order, index) => {
                    if (order.ID_STATUS === 22 || order.ID_STATUS === 24) {
                      return (
                        <tr key={index}>
                          <td className="text-center">{order.CODE}</td>
                          <td
                            className="text-center"
                            style={{
                              whiteSpace: "nowrap",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "left",
                              maxWidth: "150px",
                              zIndex: "-999",
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${order.ID}`}>
                                  {order.NAME_CLIENT}
                                </Tooltip>
                              }
                            >
                              <span
                                id={order.ID}
                                onMouseEnter={() => toggleTooltip(order.ID)}
                                onMouseLeave={() => toggleTooltip(order.ID)}
                                className="text-truncate"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {order.NAME_CLIENT}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td className="text-center">{order.PART_CODE}</td>
                          <td className="text-center">{order.STATUS}</td>
                          <td className="text-center">
                            {formatDate(order.DATE)}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      ) : null}

      <Modal
        size="xl"
        show={modalViewShow}
        backdrop="static"
        keyboard={false}
        onHide={handeCloseOrderView}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Card className="h-100 card-one">
                <Card.Header>
                  <strong className="text-uppercase">Dados do Cliente</strong>
                </Card.Header>
                <Card.Body className="px-4">
                  <dl className="row">
                    <dt className="col-sm-3 mb-0">Nome:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.client_name}
                    </dd>

                    <dt className="col-sm-3 mb-0">Telefone:</dt>
                    <dd className="col-sm-9 mb-0">
                      <a
                        style={{ color: "green" }}
                        href={
                          "https://wa.me/55" + modalViewContent.client_phone
                        }
                      >
                        <i className="ri-whatsapp-line"></i>{" "}
                        {modalViewContent.client_phone}
                      </a>
                    </dd>

                    <dt className="col-sm-3 mb-0">Email:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.client_email}
                    </dd>

                    <dt className="col-sm-3 mb-0">Endereço:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.client_address_street},{" "}
                      {modalViewContent.client_address_number}
                    </dd>

                    <dt className="col-sm-3 mb-0">Bairro:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.client_address_neighborhood}
                    </dd>

                    <dt className="col-sm-3 mb-0">Código do Cliente:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.client_code}
                    </dd>
                  </dl>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="card-one">
                <Card.Header>
                  <strong>DADOS DO PEDIDO</strong>
                </Card.Header>
                <Card.Body>
                  <dl className="row">
                    <dt className="col-sm-3 mb-0">Numero:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.order_code}
                    </dd>

                    <dt className="col-sm-3 mb-0">Tipo:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.order_type}
                    </dd>

                    <dt className="col-sm-3 mb-0">Status:</dt>
                    <dd className="col-sm-9 mb-0">
                      {modalViewContent.status_name}
                    </dd>

                    <dt className="col-sm-3 mb-0">Data:</dt>
                    <dd className="col-sm-9 mb-0">
                      {formatDate(modalViewContent.date)}
                    </dd>
                  </dl>
                  <div className="divider"></div>
                  <dl className="row d-flex flex-column">
                    <dt className="mb-0">Descrição inicial do pedido:</dt>
                    <dd className="mb-0">{modalViewContent.description}</dd>
                  </dl>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12 mt-3">
              <Card className="p-2 d-flex align-items-center card-one">
                <Button
                  className="w-20"
                  onClick={() =>
                    handleOpenEditModal(
                      selectedOrder,
                      modalViewContent.status_name
                    )
                  }
                >
                  Atualizar Pedido
                </Button>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="mt-3 mx-0 p-2">
                <span>
                  <strong>Código da Peça: </strong>
                  {modalViewContent.part_code}
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 mx-0 p-2">
                <span>
                  <strong>Categoria: </strong>
                  {modalViewContent.category}
                </span>{" "}
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 mx-0 p-2">
                <span>
                  <strong>Peso estimado da Peça: </strong>
                  {modalViewContent.info_01}
                </span>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Material: </strong>
                  {modalViewContent.material}{" "}
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Cor metal: </strong>
                  {modalViewContent.material_color}
                </span>{" "}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Quantidade de Peças: </strong>
                  {modalViewContent.metal_weight_unit}(un)
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Material Necessário/peça: </strong>
                  {modalViewContent.metal_weight_total}
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Material Necessário/Total: </strong>
                  {modalViewContent.metal_weight_total}(g)
                </span>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="mt-3 p-2 text-center">
                <span>
                  <strong>Descrição resumida: </strong>
                  {modalViewContent.short_description}
                </span>{" "}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Espessura média da chapa: </strong>
                  {modalViewContent.info_02}
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Espessura média da grifas: </strong>
                  {modalViewContent.info_03}
                </span>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Tamanho, largura e espessura do aro: </strong>
                  {modalViewContent.rim_size}
                </span>{" "}
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Tamanho do colar (colares e rivieras): </strong>
                  {modalViewContent.info_05}
                </span>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3 p-2">
                <span>
                  <strong>Argolas: </strong> {modalViewContent.info_06}
                </span>
              </Card>
            </Col>
          </Row>
          <Accordion alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Metal</Accordion.Header>
              <Accordion.Body>
                <Table bordered>
                  <thead className="table-dark">
                    <tr>
                      <th>Tipo</th>
                      <th>Peso (G)</th>
                      <th>Data de atribuição</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metal.map((metalDetail, index) => (
                      <tr key={index}>
                        <td>{metalDetail.metal}</td>
                        <td>{metalDetail.amount}</td>
                        <td>{metalDetail.assignment_date}</td>
                        <td className="text-center">
                          <Button
                            disabled={
                              metalDetail.permission === "0" ? "disabled" : ""
                            }
                            onClick={() =>
                              handleOpenMetalAtribuicaoModal(metalDetail.id)
                            }
                          >
                            Atribuir Metal
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Módulos</Accordion.Header>
              <Accordion.Body className="d-flex flex-wrap">
                {module.map(card => (
                  <Card className="mx-1 mb-2">
                    <Card.Img
                      src={
                        "https://www.apicriartgold.qtsys.com.br/getfile?type=file&name=" +
                        card.file
                      }
                      variant="top"
                    />
                    <Card.Body>
                      <Card.Title className="text-dark">{card.name}</Card.Title>
                      <Card.Title>Peso: {card.weight}</Card.Title>
                    </Card.Body>
                  </Card>
                ))}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Cravação</Accordion.Header>
              <Accordion.Body>
                <Table bordered>
                  <thead className="table-dark">
                    <tr>
                      <th>Módulo</th>
                      <th>Mat.Prima</th>
                      <th>Descrição</th>
                      <th>Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metal.map((metalDetail, index) => (
                      <tr key={index}>
                        <td>{metalDetail.metal}</td>
                        <td>{metalDetail.amount}</td>
                        <td>{metalDetail.assignment_date}</td>
                        <td>ações</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Tipo de Cravação</Accordion.Header>
              <Accordion.Body>
                modalAddPesoData
                <Table bordered>
                  <thead className="table-dark">
                    <tr>
                      <th>Tipo</th>
                      <th>Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeNailing.map(type => (
                      <tr>
                        <td>{type.name}</td>
                        <td>{type.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Anexos</Accordion.Header>
              <Accordion.Body className="d-flex flex-wrap">
                {attachment.map((card, index) => (
                  <Card key={index} className="w-25 mx-3">
                    <Card.Img
                      src={
                        "https://www.apicriartgold.qtsys.com.br/getfile?type=file&name=" +
                        card.file
                      }
                      variant="top"
                    />
                    <Card.Body>
                      <Card.Title className="text-dark">{card.type}</Card.Title>
                      <Card.Title>Descrição: {card.description}</Card.Title>
                    </Card.Body>
                  </Card>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handeCloseOrderView}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showModalAddPeso} onHide={closeModalAddPeso}>
        <Modal.Header closeButton>
          Adicionar peso do pedido após fundição
        </Modal.Header>
        <Form onSubmit={handleSubmitModalAddPeso}>
          <Modal.Body className="gap-3 d-flex flex-column">
            <Card className="card-one">
              <Card.Header>Dados do Pedido</Card.Header>
              <Card.Body>
                <div>
                  <span>
                    <strong>Pedido:</strong> {modalAddPesoData.CODE}
                  </span>
                </div>
                <div>
                  <span>
                    <strong>Estimado: {modalAddPesoData.ORDER_WEIGHT}</strong>
                  </span>
                </div>
                <div>
                  <span>
                    <strong>
                      Peso fundição: {modalAddPesoData.ORDER_FINAL_WEIGHT}
                    </strong>
                  </span>
                </div>
                <div>
                  <span>
                    <strong>
                      Peso inicial acabamento:{" "}
                      {modalAddPesoData.ACABAMENTO_INITIAL_WEIGHT}
                    </strong>
                  </span>
                </div>
                <div>
                  <span>
                    <strong>
                      Peso final acabamento:{" "}
                      {modalAddPesoData.ACABAMENTO_FINAL_WEIGHT}
                    </strong>
                  </span>
                </div>
                <div>
                  <span>
                    <strong>
                      Peso inicial cravação:{" "}
                      {modalAddPesoData.CRAVACAO_INITIAL_WEIGHT}
                    </strong>
                  </span>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-one">
              <Card.Body>
                <Card>
                  <Card.Body>
                    {(page === 12 &&
                      modalAddPesoData.ACABAMENTO_FINAL_WEIGHT !== null) ||
                    (page === 13 &&
                      modalAddPesoData.CRAVACAO_INITIAL_WEIGHT !== null) ? (
                      <Button
                        onClick={() =>
                          handleUpdateStatusOrder(modalAddPesoData)
                        }
                      >
                        Enviar para {nextPage.page_description}
                      </Button>
                    ) : (
                      <Button disabled>
                        Enviar para {nextPage.page_description}
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Card className="card-one">
              <Card.Header>Adicionar Peso:</Card.Header>
              <Card.Body>
                {(page === 12 &&
                  modalAddPesoData.ACABAMENTO_INITIAL_WEIGHT === null) ||
                (page === 13 &&
                  modalAddPesoData.CRAVACAO_INITIAL_WEIGHT === null) ? (
                  <Form.Control
                    type="text"
                    name={
                      page === 12
                        ? "acabamento-peso-inicial"
                        : page === 13
                        ? "cravacao-peso-inicial"
                        : "peso-final"
                    }
                    id={
                      page === 12
                        ? "acabamento-peso-inicial"
                        : page === 13
                        ? "cravacao-peso-inicial"
                        : "peso-final"
                    }
                    step=".03"
                    className="w-100"
                    placeholder="Digite o peso inicial da peça! "
                  ></Form.Control>
                ) : modalAddPesoData.ACABAMENTO_FINAL_WEIGHT === null ? (
                  <Form.Control
                    type="text"
                    name={
                      page === 12 &&
                      modalAddPesoData.ACABAMENTO_FINAL_WEIGHT === null
                        ? "acabamento-peso-final"
                        : null
                    }
                    id={page === 12 ? "acabamento-peso-final" : null}
                    step=".03"
                    className="w-100"
                    placeholder="Digite o peso final da peça! "
                  ></Form.Control>
                ) : (
                  <Alert>Pesos da sua página adicionados!</Alert>
                )}
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Salvar</Button>
            <Button
              variant="secondary"
              onClick={() => {
                closeModalAddPeso();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="lg"
        show={showModalAddPesoPrototipagem}
        onHide={closeModalAddPesoPrototipagem}
      >
        <Modal.Header closeButton>Adicionar peso do material</Modal.Header>
        <Form onSubmit={handleSubmitModalAddPesoPrototipagem}>
          <Modal.Body className="gap-3 d-flex flex-column">
            <Card className="card-one">
              <Card.Header>Dados do Pedido</Card.Header>
              <Card.Body>
                <div>
                  <span>
                    <strong>Pedido:</strong> {modalAddPesoPrototipagemData.CODE}
                  </span>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-one">
              <Card.Header>Adicionar Peso:</Card.Header>
              <Card.Body>
                <Form.Control
                  type="number"
                  name="peso-inicial"
                  id="peso-inicial"
                  step=".01"
                  className="w-100"
                  placeholder="Digite o peso inicial do pedido! "
                ></Form.Control>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Salvar</Button>
            <Button
              variant="secondary"
              onClick={() => {
                closeModalAddPeso();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Lista;
