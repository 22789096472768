import { apiServices } from "../apiServices/ApiServices";

export const CartFunctions = (cartResume, cart) => {
  const groupedStones = cartResume.reduce((accumulator, currentData) => {
    const stoneKey = currentData.stone;

    if (accumulator[stoneKey]) {
      // Se a pedra já existe no objeto, acumule a quantidade
      accumulator[stoneKey].Amount += currentData.amount;
    } else {
      // Se a pedra não existe, adicione ao objeto
      accumulator[stoneKey] = {
        IdProduct: currentData.product_id,
        Stone: currentData.description,
        Amount: currentData.amount,
      };
    }

    return accumulator;
  }, {});
  const pedras = Object.values(groupedStones);

  const groupedMetal = cart.reduce((accumulator, currentData) => {
    const metalKey = currentData.material_name;
  
    if (accumulator[metalKey]) {
      accumulator[metalKey].amount += currentData.required_weight_total;
    } else {
      accumulator[metalKey] = {
        material_name: currentData.material_name,
        amount: currentData.required_weight_total
      };
    }
  
    return accumulator;
  }, {});
  
  const metais = Object.values(groupedMetal);
  
  return {
    metal: metais,
    pedras: pedras
  }
};
