import { param } from "jquery";
import api from "./api";

export const productServices = {
    getProducts: (token, pageNumber, searchKey, productType, list_type, productCategory) => {
        return api.post('/productController.php', {
            "action": "read",
            "page": pageNumber,
            "product_category": productCategory,
            "limit": 10,
            "search": searchKey,
            "product_type": parseInt(productType),
            "list_type": list_type,
            "token": token
        })
    },

    verificaProdutoProduzido: (data) => {
        return api.post('/productController.php', {
            "action": "verifica_produto_produzido",
            "id_product": data.id_product,
            "token": data.token
        })
    },

    updateProduct: (id_product, id_status, token) => {
        return api.post('/productController.php', {
            "action": "productUpdate",
            "id_product": id_product,
            "id_status": id_status,
            "token": token
        })
    },
    update: (token, data) => {
        return api.post('/productController.php', {
            "action": "update",
            "token": token,
            "id_product": data.product_id,
            "id_status": data.id_status,
            "name_status": data.name_status,
            "billing_type": data.billing_type,
            "part_code": data.part_code,
            "name_product": data.product_name,
            "category": data.category_id,
            "id_client": data.client_id,
            "client_name": data.client_name,
            "fantasy_name": data.fantasy_name,
            "client_phone": data.client_phone,
            "id_modeler": data.modeler_id,
            "modeler_name": data.modeler_name,
            "short_description": data.short_description,
            "info_01": data.info_01,
            "info_02": data.info_02,
            "info_03": data.info_03,
            "info_04": data.info_04,
            "info_05": data.info_05,
            "info_06": data.info_06,
            "info_07": data.info_07,
            "resin_type": data.resin_type,
            "product_volume": data.product_volume,
            "design_value": data.design_value,
            "metal_amount": data.metal_amount
        })
    },

    getProductDescription: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "productDescription",
            "id_product": id_product,
            "token": token
        })
    },

    getMetalProduct: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "metal",
            "id_product": id_product,
            "token": token
        })
    },

    getMetalModule: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "module",
            "id_product": id_product,
            "token": token
        })
    },

    getMetalNailing: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "nailing",
            "id_product": id_product,
            "token": token
        })
    },

    getMetalTypeNailing: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "type_nailing",
            "id_product": id_product,
            "token": token
        })
    },

    getMetalAttachment: (id_product, token) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "attachment",
            "id_product": id_product,
            "token": token
        })
    },

    selectListMaterial: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter > 0 ? parameter : 0,
            "type": "material",
            "token": token
        })
    },

    selectListTypeNailing: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter,
            "type": "type_nailing",
            "token": token
        })
    },

    selectListNailingFormat: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter,
            "type": "nailing_format",
            "token": token
        })
    },


    selectListModule: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter,
            "type": "module",
            "token": token
        })
    },

    selectListAttachmentType: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter,
            "type": "attachment_type",
            "token": token
        })
    },



    selectListMaterialcolor: (parameter, token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "parameter": parameter,
            "type": "material_color",
            "token": token
        })
    },

    getProductPdf: (data) => {
        return api.post('/productController.php', {
            "action": "productPDF",
            "id_product": data.id_product,
            "token": data.token
        })
    },

    getTypeNailing: (data) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "type": "type_nailing",
            "token": data.token
        })
    },

    getProductTypeNailing: (data) => {
        return api.post('/productController.php', {
            "action": "list",
            "type": "type_nailing",
            "id_product": data.product,
            "token": data.token
        })
    },

    getTypeAttachment: (token) => {
        return api.post('/productController.php', {
            "action": "selectList",
            "type": "attachment_type",
            "parameter": 0,
            "token": token
        })
    },

    getMetalDetailOrder: (data) => {
        return api.post("/orderController.php", {
            "action": "metalDetails",
            "id_metal": data.metal,
            "id_order": data.order,
            "token": data.token
        })
    },

    atribuirMetal: (data) => {
        return api.post("/orderController.php", {
            "action": "consume_metal_balance",
            "id_order": data.order,
            "id_metal": data.metal,
            "text_description": data.text,
            "token": data.token
        })
    },
    checkProductPre: (data) => {
        return api.post("/productController.php", {
            "action": "checkProductPre",
            "type": "new",
            "token": data,
            "id_product": 0
        })
    },

    productMetalAdd: (data) => {
        return api.post("/productController.php", {
            action: "product_metal_Add",
            token: data.token,
            id_product: data.product,
            id_material: data.type,
            weight: data.peso
        })
    },

    removeMetal: (data) => {
        return api.post("/productController.php", {
            action: "remove",
            token: data.token,
            type: "product_metal",
            id: data.id

        })
    },

    getCategory: (data) => {
        return api.post("/productController.php", {
            action: "selectList",
            type: "category",
            token: data
        })
    },

    productModuleAdd: (data) => {
        const fileReader = new FileReader();

        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                // Codificar o arquivo em Base64
                const fileBase64 = event.target.result.split(',')[1];

                const jsonData = {
                    action: 'product_module_Add',
                    token: data.token,
                    id_product: data.id_product,
                    description: data.descricao,
                    peso: data.peso,
                    fileName: data.fileName,
                    file: fileBase64,
                };

                api.post('/productController.php', jsonData, {
                    headers: {
                        'Content-Type': 'application/json', // Configurar o tipo de conteúdo como application/json
                    },
                })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsDataURL(data.anexo); // Lê o arquivo como Data URL
        });

    },

    productModuleUpdateImage: (data) => {
        const payload = {
            action: "product_update_module_image",
            id_module: data.id_module,
            token: data.token,
            file: data.image,
        };
        return api.post('/productController.php', payload);
    },

    removeModule: (data) => {
        return api.post("/productController.php", {
            action: "remove",
            type: "product_module",
            id: data.id,
            token: data.token
        })
    },

    addCravacao: (data) => {
        return api.post("/productController.php", {
            action: "product_nailing_Add",
            id_product: data.id_product,
            id_module: data.id_module,
            stone: data.stone,
            description: data.description,
            id_format: data.id_format,
            amount: data.amount,
            token: data.token
        })
    },

    removeCravacao: (data) => {
        return api.post("/productController.php", {
            action: "remove",
            type: "product_nailing",
            id: data.id,
            token: data.token
        })
    },

    addTypeNailing: (id_product, token, data) => {
        const postData = {
            action: "type_nailing_Add",
            token: token,
            id_product: id_product,
            ...data
        };
        return api.post("/productController.php", postData);
    },

    productAttachmentAdd: (data) => {
        const fileReader = new FileReader();

        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                // Codificar o arquivo em Base64
                const fileBase64 = event.target.result.split(',')[1];

                const jsonData = {

                    action: "product_attachment_Add",
                    attachment_description: data.descricao,
                    attachment_type: data.tipo,
                    token: data.token,
                    id_product: data.id_product,
                    fileName: data.fileName,
                    file: fileBase64,
                };

                api.post('/productController.php', jsonData, {
                    headers: {
                        'Content-Type': 'application/json', // Configurar o tipo de conteúdo como application/json
                    },
                })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsDataURL(data.anexo); // Lê o arquivo como Data URL
        });

    },

    getResinType: (token) => {
        return api.post('productController.php', {
            token: token,
            action: "getResinType"
        })
    }
}