import { Modal, Button, Form } from "react-bootstrap";
import { userServices } from "../apiServices/userServices";
import { ToastContainer, toast } from "react-toastify";

export const Dialog = ({
  page,
  showDialog,
  closeDialog,
  title,
  question,
  sendDataToParent,
  user,
}) => {
  const token = window.localStorage.getItem("token");
  const handleClick = value => {
    // Enviar dados para o componente pai
    sendDataToParent(value);
    closeDialog();
  };

  const handleGetUser = async e => {
    e.preventDefault();
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formData = new FormData(e.target);
    const matricula = formData.get("matricula");

    const response = await userServices.getUserData(token, matricula, user);
    if (response.data.result === false) {
      toast.dismiss();
      toast.error(
        "Você não tem permissão ou sua matrícula não está cadastrada!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
      sendDataToParent(false);
    } else {
      toast.dismiss();
      sendDataToParent(true);
    }
  };

  return (
    <>
      <Modal show={showDialog} onHide={closeDialog}>
        <Modal.Header closeButton>
          <h4>{title}</h4>
        </Modal.Header>
        <form onSubmit={handleGetUser}>
          <Modal.Body>
            <h4 className="text-center">{question}</h4>
            <Form.Group className="mb-3">
              <Form.Label>
                <span>Matricula</span>
              </Form.Label>
              <Form.Control
                type="password"
                id="matricula"
                name="matricula"
                placeholder="Digite sua matrícula"
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row">
            <Button variant="primary" type="submit">
              Sim
            </Button>
            <Button variant="danger" onClick={() => handleClick(false)}>
              Não
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer />
    </>
  );
};
