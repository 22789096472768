import api from "../api";

export const PageService = {
  list: (data) => {
    return api.post("/orderController.php", {
      token: data.token,
      action: "pageList",
      status_edit: data.status_edit
    })
  }
}