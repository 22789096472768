import api from "./api"

export const balanceServices = {
    getBalance: (data) => {
        return api.post('/balanceController.php', {
            "action": "read",
            "page": 1,
            "limit": 10,
            "search": data.search,
            "token": data.token
        })
    },

    getTypeBalanceDetail: (data) => {
        return api.post('/balanceController.php', {
            "action": "list",
            "type": "balanceDetail",
            "page": data.page,
            "limit": data.limit,
            "search": data.search,
            "id_balance": data.id,
            "token": data.token
        })
    }
}